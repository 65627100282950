<div class="layout-dashboard">
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-xl-4">
      <a (click)="dep()" style="cursor: pointer"> 
      <div class="card no-gutter widget-overview-box widget-overview-box-4">
        <span class="overview-icon">
          <i class="pi pi-sitemap"></i>
        </span>
        <span class="overview-title">Departments</span>
         <div class="p-grid overview-detail">
          <div class="p-col-12">
            <div class="overview-number">{{ dashBoardCounts?.departmentsCount }}</div>
            <div class="overview-subtext">Departments</div>
          </div>
        </div> 
      </div>
      </a>
    </div>
 
    <div class="p-col-12 p-md-6 p-xl-4">
      <a (click)="role()" style="cursor: pointer"> 
      <div class="card no-gutter widget-overview-box widget-overview-box-3">
        <span class="overview-icon">
          <i class="pi pi-id-card"></i>
        </span>
        <span class="overview-title">Roles</span>
        <div class="p-grid overview-detail">
          <div class="p-col-12">
            <div class="overview-number">{{ dashBoardCounts?.rolesCount }}</div>
            <div class="overview-subtext">Roles</div>
          </div>
          <!-- <div class="p-col-6">
            <div class="overview-number">
              {{ dashBoardCounts?.myDocs_Inprocess }}
            </div>
            <div class="overview-subtext">Inprocess</div>
          </div> -->
        </div>
      </div>
      </a>
    </div>
   
    <div class="p-col-12 p-md-6 p-xl-4">
      <a (click)="user()" style="cursor: pointer">
      <div class="card no-gutter widget-overview-box widget-overview-box-1">
        <span class="overview-icon">
          <i class="pi pi-users"></i>
        </span>
       <span class="overview-title">Users</span>
         <div class="p-grid overview-detail">
          <div class="p-col-12">
            <div class="overview-number">
              {{ dashBoardCounts?.usersCount }}
            </div>
            <div class="overview-subtext">Users</div>
          </div>
          <!-- <div class="p-col-6">
            <div class="overview-number">
              {{ dashBoardCounts?.myApprovalNeeded_Completed }}
            </div>
            <div class="overview-subtext">Approved Docs</div>
          </div> -->
        </div>         
      </div>
    </a>
    </div>
  </div>
  <div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>

 
</div>
