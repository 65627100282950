import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem} from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';

@Component({
  selector: 'app-departmentbulk',
  templateUrl: './departmentbulk.component.html',
  styleUrls: ['./departmentbulk.component.scss']
})
export class DepartmentbulkComponent implements OnInit {
    userId = sessionStorage.getItem('userGuid');
    status: SelectItem[];
    cols: any[];
    showLoading:boolean;
    @ViewChild("dt") table: Table;
    dynamicPlaceHolder: string;
    changeRequestsData:any;
    selectedStatus:any[]=[];
    selectedStatusChecked:any[]=[];
    department:any;
    selectedDepartment: any;
    issuesData:any;
    addDocsDisplay: boolean;
    
    constructor() { }
  
    ngOnInit(): void {
  this.cols = [
    { field: 'departmentname', header: 'Department', dynamicPlaceHolder: 'Search' }, 
    { field: 'departmentcode', header: 'Code', dynamicPlaceHolder: 'Search' },        
    { field: 'description', header: 'Description', dynamicPlaceHolder: 'Search' }
  ];
      
    }
  }

