import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';

@Component({
  selector: 'app-entrypoint',
  templateUrl: './entrypoint.component.html',
  styleUrls: ['./entrypoint.component.scss']
})
export class EntrypointComponent implements OnInit {

  constructor(private route: Router, private router: ActivatedRoute, private _facadeService: FacadeService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }
  showLoading = false;
  ngOnInit(): void {
    var guid = this.router.snapshot.queryParamMap.get('userguid');
    var userId = this.router.snapshot.queryParamMap.get('userId');
    var IsDCCQuery = this.router.snapshot.queryParamMap.get('IsDCC');
    var token = this.router.snapshot.queryParamMap.get('access_token');
    var showMenu = this.router.snapshot.queryParamMap.get('ShowMenu');
    var source = this.router.snapshot.queryParamMap.get('source');
    var bearer_token = this.router.snapshot.queryParamMap.get('bt');
    var appliId = this.router.snapshot.queryParamMap.get('appliId');
    var clientId = this.router.snapshot.queryParamMap.get('clientId');
    sessionStorage.appliId = appliId;
    sessionStorage.clientId = clientId;
    var userIdint = parseInt(userId);
    sessionStorage.bt = bearer_token;
    sessionStorage.userId = userIdint;
    sessionStorage.userGuid = guid;
    sessionStorage.IsDCC = IsDCCQuery;
    sessionStorage.setItem("access_token", token);
    sessionStorage.ShowMenu = showMenu;
    this.showLoading = true;
    this._facadeService.UpdateSecurityToken(token).subscribe(
      (data) => {
        if (data.returnObject) {
          this.showLoading = false;
          if (source == "dashboard") {
            this.route.navigate(["/dashboard"]);
          }
          else {
            this.route.navigate(["/profile"]);
          }
        }
        else {
          location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );

  }
}
