<div class="p-grid">
    <div class="p-col-12">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12">
                <div class="card" style="min-height:600px;">
                    <h4>Profile</h4>
                    <hr />
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-6">
                            <br><br>
                            <div class="p-fluid">
                                <div class="p-field p-grid">
                                    <div class="p-col-12 p-md-4">
                                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Email:</label>
                                    </div>
                                    <div class="p-col-12 p-md-8">
                                        <input type="text" [(ngModel)]="email" pInputText placeholder=""
                                            [disabled]="true" />
                                    </div>
                                </div>
                                <br>
                                <div class="p-field p-grid">
                                    <div class="p-col-12 p-md-4">
                                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Title:</label>
                                    </div>
                                    <div class="p-col-12 p-md-8">
                                        <input type="text" [(ngModel)]="title" pInputText placeholder=""
                                            [disabled]="true" />
                                    </div>
                                </div>
                                <br>
                                <div class="p-field p-grid">
                                    <div class="p-col-12 p-md-4">
                                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Supervisor:</label>
                                    </div>
                                    <div class="p-col-12 p-md-8">
                                        <input type="text" [(ngModel)]="manager" pInputText placeholder=""
                                            [disabled]="true" />
                                    </div>
                                </div>
                                <br>
                                <div class="p-field p-grid">
                                    <div class="p-col-12 p-md-4">
                                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Joining Date:</label>
                                    </div>
                                    <div class="p-col-12 p-md-8">
                                        <input type="text" [(ngModel)]="joiningdate" pInputText placeholder=""
                                            [disabled]="true" />
                                    </div>
                                </div>
                                <br>
                                <div class="p-field p-grid">
                                    <div class="p-col-12 p-md-4">
                                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> User Code:</label>
                                    </div>
                                    <div class="p-col-12 p-md-8">
                                        <input type="text" [(ngModel)]="UserCode" pInputText placeholder=""
                                            [disabled]="true" />
                                    </div>
                                </div>
                                <br>
                                <div class="p-field p-grid">
                                    <div class="p-col-12 p-md-4">
                                        <label for="pwd" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Password:</label>
                                    </div>
                                    <div class="p-col-12 p-md-4">
                                        <input type="password" [(ngModel)]="password" pInputText [disabled]="true" />
                                    </div>
                                    <div class="p-col-12 p-md-4">
                                        <!-- <a routerLink="/newpassword">
                                        <button type="button" label="Change Password" pButton
                                            style="width: 100%"></button></a> -->
                                            <button type="button" label="Change Password" (click)="changepwddisplayClick()" pButton style="width: 100%"></button>
                                            <p-dialog header="Change Password" [(visible)]="changepwddisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
                                                <hr>
                                                <div class="p-grid">
                                                <div class="p-col-12 p-md-2 p-xl-2">
                                                </div>
                                                <div class="p-col-12 p-md-8 p-xl-8">
                                                <br><br>
                                                <div class="p-fluid">
                                                <div class="p-field p-grid">
                                                <div class="p-col-12 p-md-4">
                                                <label for="password" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Current Password:</label>
                                                </div>
                                                <div class="p-col-12 p-md-8">
                                                <input type="password" [(ngModel)]="oldpassword" id="pwd" name="pwd" style="width:100%; height:35px; border:1px solid #d8d9db; border-radius:5px; ;">
                                                <small class="p-invalid">{{ oldpasswordError }}</small>    
                                            </div>
                                                </div>
                                                <br>
                                                <div class="p-field p-grid">
                                                <div class="p-col-12 p-md-4">
                                                <label for="password" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Enter New Password:</label>
                                                </div>
                                                <div class="p-col-12 p-md-8">
                                                <input type="password" [(ngModel)]="newpassword" id="pwd" name="pwd" style="width:100%; height:35px; border:1px solid #d8d9db; border-radius:5px; ;">
                                                <small class="p-invalid">{{ newpasswordError }}</small>    
                                            </div>
                                                </div>
                                                <br>
                                                <div class="p-field p-grid">
                                                <div class="p-col-12 p-md-4">
                                                <label for="password" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Confirm New Password:</label>
                                               
                                            </div>
                                                <div class="p-col-12 p-md-8">
                                                <input id="lastname4" type="password" [(ngModel)]="confirmpassword" pInputText>
                                                <small class="p-invalid">{{ ConfirmpasswordError }}</small>    
                                                </div>
                                                </div>
                                                
                                                </div>
                                                <div class="p-fluid p-formgrid p-grid">
                                                <div class="p-field p-col-12 p-md-4">
                                                </div>
                                                <div class="p-field p-col-12 p-md-4">
                                                </div>
                                                <div class="p-field p-col-12 p-md-4">
                                                <br />
                                                <button
                                                pButton
                                                pRipple
                                                type="button"
                                                icon="pi pi-check"
                                                iconPos="left"
                                                label="Save"
                                                class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                                (click)="ChangePasswordSave()"
                                                style="width:45%; "
                                                ></button>
                                                <button
                                                pButton
                                                pRipple
                                                type="button"
                                                icon="pi pi-times"
                                                iconPos="left"
                                                label="Cancel"
                                                class="p-button-raised p-button-danger p-mr-2 p-mb-2"
                                                (click)="changepwddisplay=false"
                                                style="width:45%; float:right;"
                                                ></button>
                                                <small class="p-invalid">{{ ProfleError }}</small>    
                                                </div>
                                                </div>
                                                </div>
                                                <div class="p-col-12 p-md-2 p-xl-2">
                                                </div>
                                                </div>
                                                </p-dialog>
                                    </div>
                                </div>
                                <br>
                                <div class="p-field p-grid">
                                    <div class="p-col-12 p-md-4">
                                        <label for="email" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Profile
                                            Picture:</label>
                                    </div>
                                    <div class="p-col-12 p-md-8">
                                        <div class="card widget-user-card">
                                            <div class="user-card-header">
                                                <img *ngIf="!profileDisplayExist" src="assets/demo/images/avatar/Defaultprofile.jpg"
                                                alt="diamond-layout"style="object-fit: cover;" />
                                                <img *ngIf="profileDisplayExist" src="{{profileDisplayPic}}"
                                                    class="user-card-avatar" alt="diamond-layout" style="object-fit: cover;"/>
                                            </div>
                                            <div class="user-card-body">
                                                <div class="user-card-title">{{ firstName  +  " " + lastName}}</div>
                                                <br><br>
                                                <button type="button" label="Change Picture" (click)="ChangePicButton()"
                                                    pButton style="width: 100%"></button>
                                                   
                                                <p-dialog header="Change Profile Pic" [(visible)]="profiledisplay"
                                                    modal="modal" showEffect="fade" [style]="{width:'80%'}">
                                                    <hr>
                                                    <div class="p-grid">
                                                        <div class="p-col-12 p-md-2 p-xl-2">
                                                        </div>
                                                        <div class="p-col-12 p-md-8 p-xl-8">
                                                            <br><br>
                                                            <div class="p-grid p-fluid">
                                                                <div class="p-col-12  p-md-4"></div>
                                                                <div class="p-col-12  p-md-4">
                                                                    <div class="card" >
                                                                        <input type="file" accept="image/*" #fileInput (click)="fileInput.value = null" value="" (change)="uploadFile($event)"
                                                                        id="upload" style="display: none" />
                                                                    <label pButton for="upload" pRipple
                                                                        type="button" icon="pi pi-paperclip"
                                                                        iconPos="right" label="Change Picture"
                                                                        class="p-button-raised p-button-info p-mr-2 p-mb-2"></label>
                                                           
                                                                

                                                            <div class="p-col-12">
                                                                <span *ngIf="selectedFile" > {{fileName}} - {{filesize}}  </span>
                                                                    
                                                                <img *ngIf="profileDisplayExist" src="{{profileDisplayPic}}"    alt="diamond-layout" />
                                                                  
    
                                                              <br>
    
                                                              <span *ngIf="selectedFile">  <button pButton pRipple type="button" icon="pi pi-times" (click)="removeFile()" class="p-button-raised p-button-info p-mr-2 p-mb-2"></button></span>  
                                                                            
                                                              <img *ngIf="!profileDisplayExist" src="assets/demo/images/avatar/profile.jpg"    alt="diamond-layout" />
                                                                       
                                                            </div>
                  
                                                                            <!-- <img src="assets/demo/images/avatar/profile.jpg" alt="diamond-layout" /> -->

                                                                           <footer>
                                                                            <button
                                                                            pButton
                                                                            pRipple
                                                                            type="button"
                                                                            icon="pi pi-check"
                                                                            iconPos="right"
                                                                            label="Save"
                                                                            class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                                                            (click)="ChangeProfilePicture()"
                                                                          
                                                                          ></button>
                                                                          <small class="p-invalid">{{ changePicError }}</small>
                                                                        </footer>
                                                                           
                                                                       
                                                                </div> 
                                                            
                                                            </div>


                                                                <div class="p-col-12  p-md-4"></div>
                                                            </div>
                                                        </div>
                                                        <div class="p-col-12 p-md-2 p-xl-2">
                                                        </div>
                                                    </div>
                                                </p-dialog>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <br><br>
                            <div class="p-field p-grid">
                                <div class="p-col-12 p-md-4">
                                    <label for="password" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Departments:</label>
                                </div>
                                <div class="p-col-12 p-md-8">
                                    <p-listbox [options]="departments" optionLabel="name"></p-listbox>
                                </div>
                            </div>
                            <br>
                            <div class="p-field p-grid">
                                <div class="p-col-12 p-md-4">
                                    <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Primary Department:</label>
                                </div>
                                <!-- <div class="p-col-12 p-md-8">
                                    <input type="text" [(ngModel)]="defaultdept" pInputText placeholder="" [disabled]="true" />
                                </div> -->
                                <div class="p-col-12 p-md-6">
                                    <p-dropdown [options]="departments" [(ngModel)]="defaultdept" optionLabel="name"></p-dropdown>
                                  </div>
                                  <div class="p-col-12 p-md-2">
                                    <button style="width: 80px;float:right;" pButton type="button" label="Save" class="p-button-success" (click)="DepartmentSave()"></button>
                                  </div>
                                  <span style="color: red;">{{defaultdepError}}</span>
                            </div>
                            <br>
                            <div class="p-field p-grid">
                                <div class="p-col-12 p-md-4">
                                    <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Roles:</label>
                                </div>
                                <div class="p-col-12 p-md-8">
                                    <p-listbox [options]="roles" optionLabel="name"></p-listbox>
                                </div>
                            </div>
                            <br>
                            <div class="p-field p-grid">
                                <div class="p-col-12 p-md-4">
                                    <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Business Units:</label>
                                </div>
                                <div class="p-col-12 p-md-8">
                                    <p-listbox [options]="BUData" optionLabel="name"></p-listbox>
                                </div>
                            </div>
                            <br>
                            <div class="p-field p-grid">
                                <div class="p-col-12 p-md-4">
                                    <label for="password" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Application Groups:</label>
                                </div>
                                <div class="p-col-12 p-md-8">
                                    <p-listbox [options]="AppGroup" optionLabel="name"></p-listbox>
                                </div>
                            </div>
                            <br>
                            <div class="p-field p-grid">
                                <div class="p-col-12 p-md-4">
                                    <label for="password" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Application
                                        Access:</label>
                                </div>
                                <div class="p-col-12 p-md-8">
                                    <p-listbox [options]="applications" optionLabel="name"></p-listbox>
                                </div>
                            </div>
                            <br>
                            <div class="p-field p-grid">
                                <div class="p-col-12 p-md-4">
                                    <label for="password" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Default
                                        Application:</label>
                                </div>
                                <div class="p-col-12 p-md-6">
                                  <p-dropdown [options]="applications" [(ngModel)]="DefaultApp" optionLabel="name"></p-dropdown>
                                </div>
                                <div class="p-col-12 p-md-2">
                                  <button style="width: 80px;float:right;" pButton type="button" label="Save" class="p-button-success" (click)="ApplicationSave()"></button>
                                </div>
                                <span style="color: red;">{{defaultAppError}}</span>
                            </div>
                            <br>
                            <div class="p-field p-grid">
                                <div class="p-col-12 p-md-4">
                                    <label for="password" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Time Zone:</label>
                                </div>
                                <div class="p-col-12 p-md-6">
                                    <p-dropdown inputId="timezones" [options]="timezone" placeholder="Select a Time zone" filter="false" [showClear]="true"
                    [(ngModel)]="selectedtimezone" optionLabel="displayName"></p-dropdown>
                                </div>
                                <small class="p-invalid">{{ timezoneError }}</small>
                            </div>
                            <br>
                            <div class="p-field p-grid">
                                <div class="p-col-12 p-md-4">
                                    <label for="password" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Site:</label>
                                </div>
                                <div class="p-col-12 p-md-6">
                                    <p-dropdown inputId="site" [options]="site" placeholder="Select Site" [(ngModel)]="selectedsite"  optionLabel="siteName">
                                    </p-dropdown>
                                </div>
                                <small class="p-invalid">{{ siteError }}</small> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 1000;" *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
  </div>