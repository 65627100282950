import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {LoginComponent} from './components/login/login.component';
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { NewpasswordComponent } from './components/newpassword/newpassword.component';
import { ProfileComponent } from './components/profile/profile.component';
import { UsersComponent } from './components/users/users.component';
import { RolesComponent } from './components/roles/roles.component';
import { DepartmentsComponent } from './components/departments/departments.component';
import { DepartmentbulkComponent } from './components/departmentbulk/departmentbulk.component';
import { RolebulkComponent } from './components/rolebulk/rolebulk.component';
import { UsersbulkComponent } from './components/usersbulk/usersbulk.component';
import { UserdetailsComponent } from './components/userdetails/userdetails.component';
import {AuthGuard} from './guard/auth.guard';
import { EntrypointComponent } from './components/entrypoint/entrypoint.component';
import { ApplicationgroupComponent } from './components/applicationgroup/applicationgroup.component';
import { BusinessunitComponent } from './components/businessunit/businessunit.component';
import { SiteComponent } from './components/site/site.component';
import { CreatehelpComponent } from './components/createhelp/createhelp.component';
import { CreatehelpeditorComponent } from './components/createhelpeditor/createhelpeditor.component';
import { FeaturesComponent } from './components/features/features.component';
import { RolefeaturesComponent } from './components/rolefeatures/rolefeatures.component';
import { MenusComponent } from './components/menus/menus.component';
import { RolemenuComponent } from './components/rolemenu/rolemenu.component';
import {  CreateupdatemenudetailsComponent} from './components/createupdatemenudetails/createupdatemenudetails.component';
import { UsertrackingComponent } from './components/usertracking/usertracking.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
@NgModule({
    imports: [
      RouterModule.forRoot(
        [
          { path: "", component: LoginComponent },
          { path: "maintenance", component: MaintenanceComponent },
          {
            path: "",
            component: AppMainComponent,
            children: [
              { path: 'entrypoint', component:EntrypointComponent},
              { path: "dashboard", component: DashboardComponent,canActivate:[AuthGuard] },   
              { path: "forgotpassword", component: ForgotpasswordComponent},
              { path: "newpassword", component: NewpasswordComponent},
              { path: "profile", component: ProfileComponent,canActivate:[AuthGuard] },   
              { path: "users", component: UsersComponent,canActivate:[AuthGuard] },   
              { path: "roles", component: RolesComponent ,canActivate:[AuthGuard]},  
              { path: "departments", component: DepartmentsComponent,canActivate:[AuthGuard] }, 
              { path: "departmentbulk", component: DepartmentbulkComponent ,canActivate:[AuthGuard]},   
              { path: "rolebulk", component: RolebulkComponent,canActivate:[AuthGuard] },           
              { path: "usersbulk", component: UsersbulkComponent,canActivate:[AuthGuard] }, 
              { path: "userdetails", component: UserdetailsComponent,canActivate:[AuthGuard] }, 
              { path: "applicationgroup", component: ApplicationgroupComponent,canActivate:[AuthGuard] }, 
              { path: "businessunit", component: BusinessunitComponent,canActivate:[AuthGuard] }, 
              { path: "site", component: SiteComponent,canActivate:[AuthGuard] },
              { path: "createhelpeditor", component: CreatehelpeditorComponent,canActivate:[AuthGuard] },
              { path: "searchhelp", component: CreatehelpComponent,canActivate:[AuthGuard] },
              { path: "feature", component: FeaturesComponent,canActivate:[AuthGuard] },
              { path: "rolefeature", component: RolefeaturesComponent,canActivate:[AuthGuard] },
              { path: "menu", component: MenusComponent,canActivate:[AuthGuard] },
              { path: "rolemenu", component: RolemenuComponent,canActivate:[AuthGuard] },
              { path: "usertracking", component: UsertrackingComponent,canActivate:[AuthGuard] },
              { path: "createupdatemenudetails", component: CreateupdatemenudetailsComponent,canActivate:[AuthGuard] },
            ],
          },
          { path: "**", redirectTo: "/notfound" },
        ],
        { scrollPositionRestoration: "enabled" }
      ),
    ],
    exports: [RouterModule],
  })
export class AppRoutingModule {
}
