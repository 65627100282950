import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx';
import { PrintcommonService } from '../../services/printcommon.service';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit {
  cols: any[];
  showLoading: boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  addDocsDisplay: boolean;
  siteData: any = [];
  siteName: any;
  SiteNameError: any;
  active: boolean = true;
  responseMessage: any;
  Type: any = "Add";
  FromEdit: boolean = false;
  savelabel: any = "Save";
  siteGUID: any = "";
  UserID = sessionStorage.getItem("userGuid");
  Count=0;
  ViewMode = this.baseUrl["API_ENDPOINTS"]["ApplicationGroupAccesss"];

  constructor(private _facadeService: FacadeService, public printService: PrintcommonService, @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }

  ngOnInit(): void {
    sessionStorage.siteName = "";
    sessionStorage.active ="";
    this.cols = [
      { field: 'siteName', header: 'Site Name', dynamicPlaceHolder: 'Search' },
      { field: 'active', header: 'Active', dynamicPlaceHolder: 'Search' }
    ];
    this.getSites();
  }

  getSites() {
    this._facadeService.GetSites().subscribe(
      (data) => {
        this.siteData = data.returnObject;
        this.siteData.forEach(element => {
          element.active=element.active==true?"Yes":"No";
        });
        this.Count=this.siteData.length;
        console.log("Sites data ::" + JSON.stringify(this.siteData));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  AddNewButton() {
    this.Type = "Add";
    this.SiteNameError = "";
    this.responseMessage = "";
    this.siteName = "";
    this.active = true;
    this.FromEdit = false;
    this.addDocsDisplay = true;
    this.savelabel = "Save";
    this.siteGUID = "";
  }

  View(guid) {
    debugger;
    this.showLoading = true;
    this.SiteNameError = "";
    this.responseMessage = "";
    this.siteName = "";
    this._facadeService.viewSite(guid).subscribe(
      (data) => {
        this.addDocsDisplay = true;
        this.showLoading = false;
        if (data.returnObject != null && data.returnObject != "" && data.returnObject != undefined) {
          this.active = data.returnObject.active;
          this.siteName = data.returnObject.siteName;
        }
        this.siteGUID = data.returnObject.siteGUID;
        this.Type = "Edit";
        this.FromEdit = true;
        this.savelabel = "Update";
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  IsActiveChange() {
    this.responseMessage = "";
    if (!this.active) {
      this.showLoading = true;
      this._facadeService.CheckBusinessUnitInactiveById(this.siteGUID).subscribe(
        (data) => {
          this.showLoading = false;
          if (data.returnObject == true) {
            this.responseMessage = "This site is already associated with the user. You cannot inactive";
            this.active = true;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }

  Create() {
    this.SiteNameError = "";
    this.responseMessage = "";
    if (this.siteName == "" || this.siteName == null || this.siteName == undefined) {
      this.SiteNameError = "Please enter site name.";
    }
    else {
      this.showLoading = true;
      var site = {
        siteName: this.siteName,
        active: this.active,
        siteGUID: this.siteGUID
      };
      if (this.siteGUID != null && this.siteGUID != "" && this.siteGUID != undefined) {
        this._facadeService.updateSite(site, this.UserID).subscribe(
          (data) => {
            this.showLoading = false;
            if (data.returnCode == 0) {
              this.addDocsDisplay = false;
              this.getSites();
            }
            else {
              this.responseMessage = data.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else {
        this._facadeService.createSite(site, this.UserID).subscribe(
          (data) => {
            this.showLoading = false;
            if (data.returnCode == 0) {
              this.addDocsDisplay = false;
              this.getSites();
            }
            else {
              this.responseMessage = data.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    }
  }

  delete_row(ws, row_index) {
    let range = XLSX.utils.decode_range(ws["!ref"])
    for (var R = row_index; R < range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
        if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
          ws[this.ec(R + 1, C)].v = "";
      }
    }
    range.e.r--
    delete (ws['C1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c })
  }
  export() {
    /* table id is passed over here */
    let element = document.getElementById('excel-Site');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    this.delete_row(wb.Sheets.Sheet1, 1);
    ws['!cols'] = [{ wpx : 140 },{ wpx : 50 }];
    /* save to file */
    XLSX.writeFile(wb, "Sites.xlsx");
  }
  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content, 'Sites', 'trSearch', true);
  }
  test(val,field,dt)
  {
    if(field == "active")
      sessionStorage.active = val;
    else if(field == "siteName")
      sessionStorage.siteName = val;
    dt = dt.filter(val,field, 'contains');
    this.CountData();
  }
  CountData(){
    if(this.siteData != undefined && this.siteData != null)
    {
      var temp = this.siteData;
      if(sessionStorage.active != ""&&sessionStorage.active != null)
        temp = temp.filter(x=> x.active!=null&&x.active!=""&&x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
      if(sessionStorage.siteName != "")
        temp = temp.filter(x=> x.siteName!=null&&x.siteName!=""&&x.siteName.toLowerCase().includes(sessionStorage.siteName.toLowerCase()));
      
       this.Count = temp.length;
    }
    else
      this.Count = 0;
  }
}
