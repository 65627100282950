import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem} from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';

@Component({
  selector: 'app-usersbulk',
  templateUrl: './usersbulk.component.html',
  styleUrls: ['./usersbulk.component.scss']
})
export class UsersbulkComponent implements OnInit {
    userId = sessionStorage.getItem('userGuid');
    status: SelectItem[];
    pageTitle: string = 'Create Document';
    cols: any[];
    selectedFromDate:any;
    selectedToDate:any;
    minDate:any;
    showLoading:boolean;
    @ViewChild("dt") table: Table;
    dynamicPlaceHolder: string;
    changeRequestsData:any;
    selectedStatus:any[]=[];
    selectedStatusChecked:any[]=[];
    department:any;
    selectedDepartment: any;
    issuesData:any;
    addDocsDisplay: boolean;
    
    constructor() { }
  
    ngOnInit(): void {
      this.selectedToDate=new Date();
      let dte = new Date();
  dte.setMonth(dte.getMonth() - 1);
  this.selectedFromDate= new Date(dte.toString());
  this.cols = [
    { field: 'firstname', header: 'First Name', dynamicPlaceHolder: 'Search' },
    { field: 'lastname', header: 'Last Name', dynamicPlaceHolder: 'Search' },   
    { field: 'email', header: 'Email', dynamicPlaceHolder: 'Search' },        
    { field: 'departmentname', header: 'Department', dynamicPlaceHolder: 'Search' },
    { field: 'rolename', header: 'Role', dynamicPlaceHolder: 'Search' },
    { field: 'location', header: 'Location', dynamicPlaceHolder: 'Search' },
    { field: 'supervisor', header: 'Supervisor', dynamicPlaceHolder: 'Search' },
    { field: 'businessunit', header: 'Business Unit', dynamicPlaceHolder: 'Search' },
    { field: 'applicationgroup', header: 'Application Group', dynamicPlaceHolder: 'Search' },
    { field: 'applications', header: 'Applications', dynamicPlaceHolder: 'Search' },
    { field: 'landingapp', header: 'Landing App', dynamicPlaceHolder: 'Search' }
    
  ];
      
    }
    selectedStatusChange(selectedStatus){
      this.selectedStatusChecked=[];
      selectedStatus.value.forEach((value, key) => {
        this.selectedStatusChecked.push(value.code);
      });
    }
    
    AddNewButton() {
      this.addDocsDisplay = true; }
  }
