import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem, SortEvent} from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx';
import { PrintcommonService } from '../../services/printcommon.service';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit {
  Count=0;
    userId = sessionStorage.getItem('userGuid');
    cols: any[];
    showLoading:boolean;
    @ViewChild("dt") table: Table;
    dynamicPlaceHolder: string;
    usersData:any;    
    AllProductsValue:boolean=true;
   
    constructor(private _facadeService: FacadeService, private route: ActivatedRoute,
      private datePipe: DatePipe,public printService: PrintcommonService, private router: Router,@Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }
  
    ngOnInit(): void {
      sessionStorage.active = "";
      sessionStorage.firstName1 ="";
      sessionStorage.lastName = "";
      sessionStorage.username = "";
      sessionStorage.manager ="";
      sessionStorage.defaultDepartment = "";
      sessionStorage.title = "";
      sessionStorage.roleName = "";
      sessionStorage.timeZone = "";
      sessionStorage.joiningDate = "";
      sessionStorage.terminationDate = "";
      sessionStorage.defaultApplication = "";
      this.AllProductsValue = true;
      if(this.route.snapshot.paramMap.get('Active')!=undefined)
      {
        var ii = this.route.snapshot.paramMap.get('Active');
        if(ii != null && ii != undefined && ii != '')
          this.AllProductsValue = ii == 'false' ? false : true;
      }

      this.cols = [
        { field: 'active', header: 'Active', dynamicPlaceHolder: 'Search' },
        { field: 'firstName', header: 'First Name', dynamicPlaceHolder: 'Search' },
        { field: 'lastName', header: 'Last Name', dynamicPlaceHolder: 'Search' },   
        { field: 'username', header: 'Email', dynamicPlaceHolder: 'Search' },   
        { field: 'manager', header: 'Supervisor', dynamicPlaceHolder: 'Search' },             
        { field: 'defaultDepartment', header: 'Primary Department', dynamicPlaceHolder: 'Search' },
        { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search' },
        { field: 'roleName', header: 'Role', dynamicPlaceHolder: 'Search' },
        { field: 'timeZone', header: 'Time Zone', dynamicPlaceHolder: 'Search' },
        { field: 'joiningDate', header: 'Join Date', dynamicPlaceHolder: 'Search' },
        { field: 'terminationDate', header: 'Termination Date', dynamicPlaceHolder: 'Search' },
        { field: 'defaultApplication', header: 'Default Application', dynamicPlaceHolder: 'Search' },
      ];
      this.getUserList();
    }
    getUserList() {
      this.showLoading = true;
      this._facadeService
        .GetAllUsers()
        .subscribe((result: any) => {
          this.usersData = result.returnObject;
          if(this.usersData != null && this.usersData != undefined)
          {
            if(this.usersData.length > 0)
            {
              this.usersData =  this.usersData.filter(x=>x.clientId == 1);
              this.usersData.forEach(element => {
                element.joiningDate= this.datePipe.transform(element.joiningDate, 'MM/dd/yyyy');
                element.terminationDate= this.datePipe.transform(element.terminationDate, 'MM/dd/yyyy');
              });
              if(!this.AllProductsValue)
                this.usersData =  this.usersData.filter(x=>x.active.toLowerCase() == "y");
            }
          } 
          
          console.log(this.usersData);
          this.Count=this.usersData.length;
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
        );
    }
    Getuserdetails(userguid){
      location.href=this.baseUrl["API_ENDPOINTS"]["UMSUI"]+'userdetails?userguid='+userguid+'&fromUsers=Y';
    }
    AddNewButton(){
      this.router.navigate(["/userdetails"]);
    }
    print() {
      var Content = document.getElementById('invoice-content').innerHTML;
      this.printService.Print(Content,'Users','trSearch',true);
  }
  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
            ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
            if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['M1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }
  export(){
    /* table id is passed over here */   
   let element = document.getElementById('excel-roles'); 
   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
   /* generate workbook and add the worksheet */
   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
   this.delete_row(wb.Sheets.Sheet1, 1);
   ws['!cols'] = [{ wpx : 50 },{ wpx : 80 },{ wpx : 80 },{ wpx : 150 },{ wpx : 100 },{ wpx : 140 },{ wpx : 200 },{ wpx : 620 },{ wpx : 120 },{ wpx : 80 },{ wpx : 100 },{ wpx : 130 }];
   /* save to file */
   XLSX.writeFile(wb, "Users.xlsx");
  }

  test(val,field,dt)
{
  if(field == "active")
    sessionStorage.active = val;
  else if(field == "firstName")
    sessionStorage.firstName1 = val;
  else if(field == "lastName")
    sessionStorage.lastName = val;
  else if(field == "username")
    sessionStorage.username = val;
  else if(field == "manager")
    sessionStorage.manager = val;
  else if(field == "defaultDepartment")
    sessionStorage.defaultDepartment = val;
  else if(field == "title")
    sessionStorage.title = val;
  else if(field == "roleName")
    sessionStorage.roleName = val;
    else if(field == "timeZone")
    sessionStorage.timeZone = val;
    else if(field == "joiningDate")
    sessionStorage.joiningDate = val;
    else if(field == "terminationDate")
    sessionStorage.terminationDate = val;
    else if(field == "defaultApplication")
    sessionStorage.defaultApplication = val;
  dt = dt.filter(val,field, 'contains');
  this.CountData();
}
CountData(){
  if(this.usersData != undefined && this.usersData != null)
  {
    var temp = this.usersData;
    if(sessionStorage.active != ""&&sessionStorage.active != null)
      temp = temp.filter(x=> x.active!=null&&x.active!=""&&x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
    if(sessionStorage.firstName1 != "")
      temp = temp.filter(x=> x.firstName!=null&&x.firstName!=""&&x.firstName.toLowerCase().includes(sessionStorage.firstName1.toLowerCase()));
    
    if(sessionStorage.lastName != "")
      temp = temp.filter(x=> x.lastName.toLowerCase().includes(sessionStorage.lastName.toLowerCase()));
    if(sessionStorage.username != "")
      temp = temp.filter(x=> x.username.toLowerCase().includes(sessionStorage.username.toLowerCase()));
    if(sessionStorage.manager != "")
      temp = temp.filter(x=> x.manager!=null&&x.manager!="" &&x.manager.toLowerCase().includes(sessionStorage.manager.toLowerCase()));
    if(sessionStorage.defaultDepartment != "")
      temp = temp.filter(x=> x.defaultDepartment.toLowerCase().includes(sessionStorage.defaultDepartment.toLowerCase()));
    if(sessionStorage.title != "")
      temp = temp.filter(x=> x.title!=null&&x.title!="" &&x.title.toString().toLowerCase().includes(sessionStorage.title.toLowerCase()));
      if(sessionStorage.roleName != "")
      temp = temp.filter(x=> x.roleName.toString().toLowerCase().includes(sessionStorage.roleName.toLowerCase()));
      if(sessionStorage.timeZone != "")
      temp = temp.filter(x=> x.timeZone.toString().toLowerCase().includes(sessionStorage.timeZone.toLowerCase()));
      if(sessionStorage.joiningDate != "")
      temp = temp.filter(x=> x.joiningDate.toString().toLowerCase().includes(sessionStorage.joiningDate.toLowerCase()));
      if(sessionStorage.terminationDate != "")
      //temp = temp.filter(x=> x.terminationDate.toString().toLowerCase().includes(sessionStorage.terminationDate.toLowerCase()));
      temp = temp.filter(x => x.terminationDate != null && x.terminationDate != "" && x.terminationDate.toString().toLowerCase().includes(sessionStorage.terminationDate.toLowerCase()));
      if(sessionStorage.defaultApplication != "")
      temp = temp.filter(x=> x.defaultApplication.toString().toLowerCase().includes(sessionStorage.defaultApplication.toLowerCase()));
     this.Count = temp.length;
  }
  else
    this.Count = 0;
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'joiningDate' || event.field === 'terminationDate') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '--' ? null : new Date(value1);
      const date2 = value2 === '--' ? null : new Date(value2);
      

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    }
    else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });

  event.data.forEach(e => {
    e.joiningDate = this.datePipe.transform(e.joiningDate, 'MM/dd/yyyy');
    e.terminationDate = this.datePipe.transform(e.terminationDate, 'MM/dd/yyyy');
  });
}
  }