<div class="p-grid">
    <div class="p-col-12">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12">
          <div class="card" style="min-height:600px;" *ngIf="FirstTime">
            <h4>Update New Password</h4>
            <hr />
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-2">
                </div>
               
              <div class="p-field p-col-12 p-md-8">
              <br><br>
                  
                    <div class="p-fluid">
                       
                        <div class="p-field p-grid">
                            <div class="p-col-12 p-md-4">
                            <label for="email" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> EmailId:</label>
                        </div>
                            <div class="p-col-12 p-md-8">
                                <input type="text" pInputText placeholder="loremipsum@gmail.com" [(ngModel)]="email" [disabled]="true"/>
                            </div>
                        </div>
                        <br>
                        <div class="p-field p-grid">
                            <div class="p-col-12 p-md-4">
                            <label for="password" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Enter New Password:</label>
                            </div>
                            <div class="p-col-12 p-md-8">
                                <input type="password" id="pwd" name="pwd" [(ngModel)]="password"
                                style="width:100%; height:35px; border:1px solid #d8d9db; border-radius:5px; ;">
                                <small class="p-invalid">{{ passwordError }}</small>
                              </div>
                        </div>
                        <br>
                        <div class="p-field p-grid">
                            <div class="p-col-12 p-md-4">
                            <label for="password" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"> Confirm New Password:</label>
                            </div>
                            <div class="p-col-12 p-md-8">
                                <input id="lastname4" type="password" pInputText [(ngModel)]="cpassword">
                                <small class="p-invalid">{{ cpasswordError }}</small>
                            </div>
                        </div>
                      
                    </div>
               
              </div>
              <div class="p-field p-col-12 p-md-2">
            </div>
            
        </div>
        <div class="p-fluid p-formgrid p-grid">
           
            <div class="p-field p-col-12 p-md-3">
            </div>
            <div class="p-field p-col-12 p-md-4">
            </div>
                     
              <div class="p-field p-col-12 p-md-2">
                <br />
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-check"
                  iconPos="right"
                  label="Save"
                  class="p-button-raised p-button-success p-mr-2 p-mb-2"                
                  style="width:45%; "
                  (click)="NewPasswordClick()"
                ></button>
                <small class="p-invalid">{{ errormessage }}</small>
                <!-- <button
                pButton
                pRipple
                type="button"
                icon="pi pi-times"
                iconPos="right"
                label="Cancel"
                class="p-button-raised p-button-danger p-mr-2 p-mb-2"
                style="width:45%; float:right;"
                (click)="CancelButton()"
              ></button> -->
              </div>
            
              <div class="p-field p-col-12 p-md-3">
            </div>
        </div>
            </div>
            <div *ngIf="!FirstTime" style="text-align: center;">
              <h1>{{expirymessage}}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
 
    <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 1000;" *ngIf="showLoading">
      <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
    </div>