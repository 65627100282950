<div class="layout-sidebar" (click)="onMenuClick($event)">
    <a class="logo">
        <img (click)="LogoClick()" id="app-logo" class="logo-image" src="assets/gallery/pficon.png" alt="FactoryPRO-Logo"style="width:60px; height:60px;margin-left:-1rem;">
        
    </a>

    <div class="layout-menu-container">
        <ul class="layout-menu">
            <ng-container *ngFor="let item of model; let i = index;">
                <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
                <li *ngIf="item.separator" class="menu-separator"></li>
            </ng-container>
        </ul>
    </div>
</div>

<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>
