import { Component, Inject, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { FacadeService } from "../../facade/facade.service";
import {Router, ActivatedRoute } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { identifierModuleUrl } from '@angular/compiler';
import { elementAt } from 'rxjs/operators';
import { DatePipe } from '@angular/common'
import { API_ENDPOINTS } from 'src/app/app.component';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  userId = parseInt(sessionStorage.getItem("userId")); 
  UID = 0;//parseInt(sessionStorage.getItem('docId'));
  profilesData: any;
  firstName:any="";
  email: any;
  password: any;
  departments: any[];
  roles: any[];
  applications: any[];
  BUData: any[];
  AppGroup: any[];
  profiledisplay: boolean;
  form: FormGroup;
  fileName: any = "";
  lastName:any="";
  manager:any="";
  joiningdate:any="";
  title:any;
  defaultdept:any;
  uploadedFiles: any[] = [];
  showLoading:boolean;
  changePicError:any='';
  selectedFile:boolean;
  filesize:any='';
  userGuid = sessionStorage.getItem('userGuid');
  fileToUpload: File = null;
userDetails:any;
changepwddisplay:any;
oldpasswordError:any;
newpasswordError:any;
ConfirmpasswordError:any;
oldpassword:any;
newpassword:any;
confirmpassword:any;
ProfleError:any;
DefaultApp:any;
profileDisplayPic:any;
profileDisplayExist:boolean;
defaultAppError:any;
defaultdepError:any;
UserCode:any;
timezone:any;
  timezoneData:any;
  timezoneError: any;
  selectedtimezone:any;
  site:any;
  selectedsite:any;
  siteError:any;
  siteData:any;
  id = 0;
  siteGUID=0;
  constructor(
     private fb: FormBuilder,
     private http: HttpClient,
     private _facadeService: FacadeService,
     private route: ActivatedRoute,
     public datepipe: DatePipe,
     @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {
    this.form = this.fb.group({
      name: [""],
      avatar: null,
    });
  }

  ngOnInit(): void {
    this.getUserDetails();
    this.gettimezones();
    this. getsites();
  }

  removeFile() {
    this.fileName ="";
    this.selectedFile=false;
    this.filesize="";
    this.changePicError='';
    this.form = this.fb.group({
      name: [""],
      avatar: null,
    });
}

  ChangePicButton(){
    this.changePicError='';
    this.selectedFile=false;
    this.filesize="";
    this.fileName==''
    this.profiledisplay=true;
  }
  ChangeProfilePicture(){
    this.changePicError='';
if(this.fileName=='' || this.fileName==undefined){
this.changePicError="Please select profile picture";
}
else{
  this.showLoading=true;
  let formData = new FormData(); 
formData.append('file', this.fileToUpload, this.fileToUpload.name);
    this._facadeService.UploadProfilePicture(this.userGuid,formData).subscribe(
      (data) => {
        this.showLoading=false;
      var response = data.returnObject;
      if(data!=null && data.returnCode==0){
this.fileName=response.fullPath;
if(this.fileName!='' && this.fileName!=undefined){
  this.profileDisplayPic ="";
  this.profileDisplayPic = this.baseUrl["API_ENDPOINTS"]["BlobDownloadURL"]+response.fullPath;
  this.profileDisplayExist=true;  
  this.profiledisplay=false;
  sessionStorage.profileDisplayPic= this.profileDisplayPic;
        sessionStorage.profileDisplayExist= this.profileDisplayExist;
        location.reload();
}
}
else{
  this.changePicError=data.returnMessage;
}
      console.log("Upload Details ::" + JSON.stringify(response));
    },
    (error) => {
      this.showLoading=false;
      console.log(error);
    }
  );
}
  }

  uploadFile(event) {
    debugger
    this.changePicError='';
    const file = (event.target as HTMLInputElement).files[0];
    this.fileName = file.name;
    this.fileToUpload = file;
    this.selectedFile=true;
    this.filesize = Math.round(file.size / 1024) + " KB";
    this.form.patchValue({
      avatar: file,
    });
    this.form.get("avatar").updateValueAndValidity();
  }

  ChangePasswordSave(){
this.oldpasswordError='';
this.newpasswordError='';
this.ConfirmpasswordError='';
this.ProfleError='';
if(this.oldpassword=='' || this.oldpassword===undefined){
this.oldpasswordError="Please enter current password";
}
else if(this.newpassword=='' || this.newpassword===undefined){
  this.newpasswordError="Please enter new password";
}
else if(this.confirmpassword=='' || this.confirmpassword===undefined){
  this.ConfirmpasswordError="Please enter confirm password";
}
else if(this.confirmpassword!=this.newpassword){
  this.ConfirmpasswordError="Password and confirm password does not match";
}
else if(this.password!=this.oldpassword){
  this.oldpasswordError="Please enter correct current password";
}
else if(this.newpassword==this.oldpassword){
  this.oldpasswordError="Old Password and New Password should not be same.";
}
else{
  this.showLoading=true;
  var data  = {
    UserGuid: this.userGuid,
        oldPassword: this.oldpassword,
        password: this.newpassword,
  }
  this._facadeService.ChangePassword(data).subscribe(
    (data) => {
      this.showLoading=false;
    if(data!=null && data.returnCode!=0){
      this.ProfleError=data.returnMessage;
    }
    else{
      this.password=this.newpassword;
      this.changepwddisplay=false;
    }
    console.log("Change Passwoord data ::" + JSON.stringify(data));
  },
  (error) => {
    this.showLoading=false;
    console.log(error);
  }
);
}
  }

  changepwddisplayClick(){
    this.oldpasswordError='';
this.newpasswordError='';
this.ConfirmpasswordError='';
this.ProfleError='';
this.changepwddisplay=true;
this.oldpassword='';
this.newpassword='';
this.confirmpassword='';
  }

  getUserDetails(){
    this.showLoading=true;
    this._facadeService.GetUserDetailsnoCache(this.userGuid).subscribe(
      (data) => {
        this.showLoading=false;
      this.userDetails = data.returnObject;
      this.firstName = this.userDetails.firstName;
      this.lastName = this.userDetails.lastName;
      this.email = this.userDetails.emailId;
      this.password = this.userDetails.password;
      this.manager= this.userDetails.manager;
      this.joiningdate =this.datepipe.transform( new Date(this.userDetails.joiningDate), 'MM/dd/yyyy');
      this.title= this.userDetails.title;
      this.UserCode = this.userDetails.userCode;
      this.roles = this.userDetails.userRoles;
      this.departments = this.userDetails.userDepartments;
      this.selectedtimezone = this.getTimeZonesIDs(this.userDetails.timeZone);
      this.selectedsite = this.getsiteIDs(this.userDetails.siteGuid);
      this.applications = this.userDetails.userApplications;
      this.BUData = this.userDetails.userBusinessUnits;
      this.AppGroup = this.userDetails.userGroups;
      this.defaultdept = this.departments.find(x=>x.name== this.userDetails.defaultDepartment);
      this.DefaultApp = this.applications.find(x=>x.name == this.userDetails.defaultApplication);
      
      this.fileName=this.userDetails.userProfilePic;
      this.userId = this.userDetails.userId;
      sessionStorage.userId = this.userDetails.userId;
      sessionStorage.userGuid = this.userGuid;
      sessionStorage.firstName = this.userDetails.firstName;

      if(this.fileName!='' && this.fileName!=undefined){
        this.profileDisplayExist=true;
        if(this.baseUrl["API_ENDPOINTS"]["Storage"]=="azure"){
          this.profileDisplayPic = this.baseUrl["API_ENDPOINTS"]["BlobDownloadURL"]+this.fileName;
        }
        else{
          this.profileDisplayPic =
          API_ENDPOINTS.UMSAPI +
          "user/Download?filename=" +
          this.userDetails.userProfilePic;
        }
       
        sessionStorage.profileDisplayPic= this.profileDisplayPic;
        sessionStorage.profileDisplayExist= this.profileDisplayExist;
      }
      //this.getProfilesById(); 
      console.log("User details data ::" + JSON.stringify(this.userDetails));
      // this.getApplications();
    },
    (error) => { 
      this.showLoading=false;
      console.log(error);
    }
  );
  }
  getProfilesById() {
    this.showLoading=true;
    this._facadeService.profileList(this.userId).subscribe(
        (data) => {
          this.showLoading=false;
        this.profilesData = data.returnObject;
        this.email = this.profilesData.emailId; //data.returnObject.workflowDefinitionId;password
        this.password = this.profilesData.password;
        console.log("Profiles details data ::" + JSON.stringify(this.profilesData));
      },
      (error) => {
        this.showLoading=false;
        console.log(error);
      }
    );
  }

  getTimeZonesIDs(id) {
    this._facadeService.GetTimezone().subscribe(
      (data) => {
        this.timezoneData = data.returnObject;
        if (id != "" && id != null && id != undefined) {
          this.selectedtimezone = this.timezoneData.find(m => m.id == id);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getsiteIDs(siteGUID) {
    this._facadeService.GetSites().subscribe(
      (data) => {
        this.siteData = data.returnObject;
        if (siteGUID != "" && siteGUID != null && siteGUID != undefined) {
          this.selectedsite = this.siteData.find(m => m.siteGUID == siteGUID);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  gettimezones() {
    this._facadeService.GetTimezone().subscribe(
      (data) => {
        this.timezone = data;
        this.timezone = this.timezone.returnObject;
        console.log("Time zone date",this.timezone);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getsites() {
    this._facadeService.GetSites().subscribe(
      (data) => {
        this.site = data;
        this.site = this.site.returnObject;
        console.log("Site Data: ",this.site);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ApplicationSave(){
    this.showLoading=true;
    this.defaultAppError="";
  this._facadeService.UpdateUserDefaultApplication(this.userGuid,this.DefaultApp.id).subscribe(
    (data) => {
      this.showLoading=false;
      if(data.returnCode==0&&data.returnObject){
        location.reload();
      }
      else{
  this.defaultAppError="Unable to update the Default Application.";
      }
  },
  (error) => {
    this.showLoading=false;
    console.log(error);
  }
  );
  }

  DepartmentSave()
  {
    this.showLoading=true;
    this.defaultdepError="";
  this._facadeService.UpdateUserDefaultDepartment(this.userGuid,this.defaultdept.id).subscribe(
    (data) => {
      this.showLoading=false;
      if(data.returnCode==0&&data.returnObject){
        location.reload();
      }
      else{
  this.defaultdepError="Unable to update the Primary Department";
      }
  },
  (error) => {
    this.showLoading=false;
    console.log(error);
  }
  );
  }
  
}
