import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from "primeng/table";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})

export class DashboardComponent implements OnInit {
  userId = parseInt(sessionStorage.getItem('userId'));
  userGuid = sessionStorage.getItem('userGuid');
  labels: number[] = [];
  data: number[] = [];
  options: any;
  plugin: any;
  today: any;
  Timeline: any;
  cols: any[];
  showLoading: boolean = true;
  dashBoardCounts:any;
  @ViewChild("dt") table: Table;

  constructor(
    private _facadeService: FacadeService, private router: Router,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {}
  ngOnInit(): void {
    this.getDashboardSummary();
  }

  getDashboardSummary() {
    this.showLoading = true;
    this._facadeService.getDashboardCounts().subscribe(
      (data) => {
        this.dashBoardCounts = data.returnObject;
        this.showLoading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  user(){
    this.router.navigate(['/users',{ Active: 'false' }]);    
  }
  dep(){
    this.router.navigate(['/departments',{ Active: 'false' }]);    
  }
  role(){
    this.router.navigate(['/roles',{ Active: 'false' }]);    
  }
}
