import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpBackend } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { BASE_API_URL_TOKEN } from '../injectors';
import { API_ENDPOINTS } from "../app.component";

@Injectable({
  providedIn: "root",
})
export class HttputilityService {
  constructor(private http: HttpClient, @Inject(BASE_API_URL_TOKEN) public baseUrls: Object) {}

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      //"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    }),
  };
  public httpOptionsFile = {
    headers: new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
    }),
  };
  //Added for workflows
  public httpUOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
  };

  Get(routeUrl): Observable<any> {
    return this.http.get<any>(routeUrl).pipe(catchError(this.handleError));
  }

  GetU(routeUrl, data?): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINTS.UMSAPI + routeUrl)
      .pipe(catchError(this.handleError));
  }
  GetAuth(routeUrl, data?): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINTS.UMSAuthAPI + routeUrl)
      .pipe(catchError(this.handleError));
  }
  Add(routeUrl, data) {
    return this.http.post<any>(API_ENDPOINTS.UMSAPI + routeUrl, data, this.httpOptions);
  }
  AddAuthh(routeUrl, data) {
    return this.http.post<any>(API_ENDPOINTS.UMSAuthAPI + routeUrl, data, this.httpOptions);
  }
  //Added for Workflow
  AddU(routeUrl, data) {
    return this.http.post<any>(
      API_ENDPOINTS.UMSAPI + routeUrl,
      data,
      this.httpUOptions
    );
  }

  Delete(routeUrl) {
    return this.http.delete<any>(routeUrl);
  }

  handleError(error) {
    return throwError(error.message);
  }

  FileUpload(routeUrl, data) {
    return this.http.post<any>(
      API_ENDPOINTS.UMSAPI +routeUrl,
      data,
      this.httpOptionsFile
    );
  }

  FileDownload(routeUrl, data) {
    return this.http.post<any>(
      API_ENDPOINTS.PLMAPI +  routeUrl,
      data,
      //this.httpOptionsFile
      this.httpOptions
    );
  }
  GetDMS(routeUrl): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINTS.PLMAPI + routeUrl)
      .pipe(catchError(this.handleError));
  }
  GetCMS(routeUrl): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINTS.PLMAPI + routeUrl)
      .pipe(catchError(this.handleError));
  }
  GetITM(routeUrl): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINTS.PLMAPI+ routeUrl)
      .pipe(catchError(this.handleError));
  }
  GetPDM(routeUrl): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINTS.PLMAPI + routeUrl)
      .pipe(catchError(this.handleError));
  }
  PostPDM(routeUrl, data) {
    return this.http.post<any>(API_ENDPOINTS.PLMAPI+ routeUrl, data, this.httpOptions);
  }
  PostDMS(routeUrl, data) {
    return this.http.post<any>(API_ENDPOINTS.PLMAPI+ routeUrl, data, this.httpOptions);
  } 
}
