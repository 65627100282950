import { Component, OnInit } from '@angular/core';
import { newpasswordModel } from '../../models/newpassword_model';
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Router,ActivatedRoute } from '@angular/router';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-newpassword',
  templateUrl: './newpassword.component.html',
  styleUrls: ['./newpassword.component.scss']
})
export class NewpasswordComponent implements OnInit {
  docsData: newpasswordModel[];
  myDocsMode: any;
  email: any;
  password: any;
  cpassword: any;
  profilesData: any;
  showLoading:boolean;
  passwordError:any;
  cpasswordError:any;
  t:any;
  errormessage:any;
  userGuid:any;
  FirstTime:boolean;
  expirymessage="";
  constructor(private _facadeService: FacadeService,private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    sessionStorage.clear();
    this.t = this.route.snapshot.queryParamMap.get('t');
    this.t = encodeURIComponent(this.t);
    this.validatelink(this.t);
  }

  validatelink(token)
  {
    this.showLoading=true;
    this._facadeService.ValidateLink(token).subscribe(
        (data) => {
          this.showLoading=false;
          if(data!=null && data.returnCode==0){
            this.FirstTime = data.returnObject;
            if(this.FirstTime){
              this.GetResetPassword(this.t);
            }
            else{
              this.expirymessage= "The link has been expired";
            }
          }
      },
      (error) => {
        this.showLoading=false;
        console.log(error);
      }
    );
  }

  GetResetPassword(token){
    this.showLoading=true;
    this._facadeService.ResetPassword(token).subscribe(
        (data) => {
          this.showLoading=false;
          if(data!=null && data.returnCode==0){
            this.email = data.returnObject.emailId;
            this.userGuid = data.returnObject.userGuid;
          }
      },
      (error) => {
        this.showLoading=false;
        console.log(error);
      }
    );
  }
  NewPasswordClick() {
    this.passwordError ='';
    this.cpasswordError ='';
    if(this.password=='' || this.password==undefined){
      this.passwordError = "Please enter new password.";
    }
    else if(this.cpassword=='' || this.cpassword==undefined){
      this.cpasswordError = "Please enter confirm new password.";
    }
    else if(this.password!=this.cpassword){
      this.cpasswordError = "New password and confirm new password does not match.";
    }
    else {
      this.showLoading=true;
      this.myDocsMode = {
        email: this.email,
        oldPassword: this.password,
        password: this.cpassword,
        userGuid:this.userGuid
      };
      this._facadeService.newpasswordData(this.myDocsMode).subscribe(
        (data) => {
          this.showLoading=false;
          this.docsData = data.returnObject;
          if(data!=null && data.returnCode==0){
            this.router.navigate(["/"]);
          }
         else{
          this.errormessage = data.returnMessage;
         }
          console.log("new password data ::" + JSON.stringify(this.docsData))
        }, 
        (error) => {
          this.showLoading=false;
          console.log(error);
        }
      );
    }
  }
}
