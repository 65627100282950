<div class="p-grid p-fluid">
    <div class="p-col-12">
<div class="card" style="min-height:600px;">
    <label>Select a Role</label>
    <p-dropdown [options]="rolesData" [(ngModel)]="selectedRole" placeholder="Select a role"
optionLabel="roleName" (onChange)="GetRoleByMenus()"></p-dropdown>
    <br />
    <div class="invoice invoice-header"  id="invoice-content" style="overflow-x: auto;">
        <p-table #dt [value]="roleMenuData" [(selection)]="selectedroleMenus" id="excel-table" [columns]="cols" sortMode="multiple" class="table-align"
          styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="menuId"
          [globalFilterFields]="['applicationName','menuName','headerMenuName','parentMenuName']">
          <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
              <th style="width: 3rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th> 
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
            <tr id="trSearch">
              <th></th>
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                (input)="test($event.target.value,col.field,dt)"
                  style="margin: -10px 0 -10px 0; width:90%; height:25px;">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
            
            <tr>
              <td>
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">
                    {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 3" style="text-align: left;padding-left:15px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 4" style="text-align: left;padding-left:15px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 5" style="text-align: left; padding-left:15px;">
                  {{rowData[col.field]}}
                </td>
              </ng-container>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7" style="text-align: left;">No records found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-10"><small class="p-invalid" style="padding-left: 20px;">{{ responseMessage}}</small></div>
        <div class="p-field p-col-12 p-md-2">
            <br />
            <button pButton pRipple type="button" icon="pi pi-check" label="Save"
            class="p-button-raised p-button-success p-mr-2 p-mb-2" style="float: right;width:auto" (click)="CreateUpdateRoleFeature()"></button>
        </div>
    </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>