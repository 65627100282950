import { Component, OnInit } from '@angular/core';
import { UserService } from "../../services/user.service";

@Component({
  selector: 'app-createhelp',
  templateUrl: './createhelp.component.html',
  styleUrls: ['./createhelp.component.scss']
})
export class CreatehelpComponent implements OnInit {
  htmlStr: string = '<strong>Can you help us improve?</strong> (The more you tell us the more we can help.)';
  showLoading:boolean;
  searchtext:string = "";
  searchError:string = "";
  ShowHelp:boolean = false;
  HelpData : any[]=[];
  appid = "41646072-42C7-4C85-BB2F-63B20BB2025D";//sessionStorage.getItem('applicationID');
  userID = sessionStorage.getItem('userGuid');
  isFeedback:boolean = false;
  HelpDataR:any[] = [];
  selectedRelated:any;
  title:string="";
  DisplayYes:boolean = false;
  DisplayNo:boolean = false;
  DisplayNoThanks:boolean = false;
  HelpID:string = "";
  selectedFeedback:any[]=[];
  FeedbackData:any[]=[];
  Description:string="";
  RemarkError:string = "";
  firsttime :boolean = true;
  DisplayContent:any;
  constructor(private _service: UserService) { }

  ngOnInit(): void {
  }

  filterhelp()
  {
    this.showLoading = true;
    this.HelpData = [];
    this.ShowHelp = false;
    this.searchError = "";
    this.isFeedback = false;
    this.HelpDataR = [];
    this.selectedRelated = [];
    this.title = "";
    this.HelpID = "";
    this.DisplayYes = false;
    this.DisplayNo = false;
    this.DisplayNoThanks = false;
    if(this.searchtext == "")
    {
      this.searchError = "Please enter search text";this.showLoading = false;
    }
    else
    {
      this._service.SearchHelp(this.searchtext,this.appid).subscribe((data) => {
        this.HelpData = data.returnObject;
        if(this.HelpData != null && this.HelpData != undefined)
        {
          if(this.HelpData.length > 0)
          {
            this.ShowHelp = true;
            this.GetHelpDetails(this.HelpData[0].helpGuid,true);
          }
          else
            this.searchError = "No help content found.";
        }
        else
          this.searchError = "No help content found.";
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
      );
    }
  }
  GetHelp(item:any)
  {
    this.showLoading = true;
    this.HelpID = "";
    this.title = "";
    this.isFeedback = false;
    this.HelpDataR = [];
    this.selectedRelated = [];
    this.DisplayYes = false;
    this.DisplayNo = false;
    this.DisplayNoThanks = false;
    if(this.HelpData!=null && this.HelpData.length>0){
      this.HelpData.forEach((value, key) => {
        if(key==item.index)
          this.GetHelpDetails(value.helpGuid,true);
      });
    }
    else
      this.showLoading = false;
  }
  GetHelp1()
  {
    this.showLoading = true;
    this.HelpID = "";
    this.title = "";
    this.isFeedback = false;
    this.DisplayYes = false;
    this.DisplayNo = false;
    this.DisplayNoThanks = false;
    if(this.selectedRelated!=null && this.selectedRelated != undefined)
      this.GetHelpDetails(this.selectedRelated.helpGuid,false);
    else
      this.showLoading = false;
  }
  GetHelpDetails(ID: any,FromMainHelp: boolean)
  {
    this.HelpID = ID; 
    this._service.GetHelpByID(ID).subscribe((data) => {
      var ID = data.returnObject;
      if(ID != null)
      {
        this.title = ID.helpTitle;
        this.isFeedback = ID.showFeedback;
        this.DisplayContent = ID.displayContent;
        if(FromMainHelp)
        {
          var ref = [];
          if(ID.references != null && ID.references != undefined)
          {
            ID.references.forEach(e => {
              var exists = false;
              this.HelpData.forEach(e1 => {
                if(e.helpGuid.toLowerCase() == e1.helpGuid.toLowerCase() && !exists)
                  exists = true;
              });
              if(!exists)
                ref.push(e);
            });
          }
          this.HelpDataR = ref;
          if(this.firsttime)
            this.firsttime = false;
        }
      }
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
    );
  }
  BindFeedback()
  {
    this.showLoading = true;
    this.DisplayNo = true;
    this.Description = "";
    this.FeedbackData = [];
    this.selectedFeedback = [];
    this.RemarkError = "";
    this._service.GetQuestions().subscribe((data) => {
      this.FeedbackData = data.returnObject;
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
    );
  }
  Add()
  {
    this.showLoading = true;
    this.RemarkError = '';
    
    if (this.selectedFeedback == undefined || this.selectedFeedback.length == 0 || this.selectedFeedback == null)
    { 
      this.RemarkError = 'Please select atleast one effected experience.';this.showLoading = false; 
    }
    else
    {
      if(this.Description == undefined || this.Description == null)
        this.Description = "";
      var q = "";
      this.selectedFeedback.forEach(element => {
        q = q + element.questionId.toString() + "," ;
      });
      var AddModel = {
        "helpGuid": this.HelpID,
        "feedBack": this.Description,
        "createdBy": this.userID,
        "questionIds": q,
        "active": true,
        "id": 0,
        "helpTitle": "",
        "createdDate": new Date() 
      };
      this._service.AddFeedback(AddModel).subscribe(
        (data) => {
            if(data.returnCode == 0){
              this.DisplayNo = false;
              this.DisplayNoThanks = true;
            }
            else
              this.RemarkError = data.returnMessage;
            this.showLoading=false;
        },
        (error) => {
          console.log(error);
        }
        );
    }
  }
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.filterhelp();
    }
  }
  clearSearch(){
    this.HelpData = [];
    this.ShowHelp = false;
    this.searchError = "";
    this.isFeedback = false;
    this.HelpDataR = [];
    this.selectedRelated = [];
    this.title = "";
    this.HelpID = "";
    this.DisplayYes = false;
    this.DisplayNo = false;
    this.DisplayNoThanks = false;
    this.searchtext="";
  }
}
