<div class="p-grid">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
    <h4>Forgot Password ?</h4>
    <hr />

<div class="container-fluid">

  <form [formGroup]="userEmails">
 
	<div class="p-grid form-group" >
    <div class="p-col-12 p-md-3">
    </div>
    <div class="p-col-12 p-md-6">
     
      <div class="card" style="margin-top:30px; padding-top:60px;">
        <div class="p-grid">
    <div class="p-col-12 p-md-2">
    <label style="float:right; margin-top:5px;">Email:<span style="color:red">*</span> </label>
   
  </div>
  <div class="p-col-12 p-md-8">
    <input type="email" class="form-control"
     [(ngModel)]="email" formControlName="primaryEmail"
     (keyup.enter)="onEnter($event)"
    style="width:100%; height:35px; border:1px solid #d8d9db; border-radius:5px; ;">
    <small class="p-invalid">{{ emailError }}</small>
</div>
  <div class="p-col-12 p-md-6">
  </div>
  <div class="p-col-12 p-md-8">
  </div>
  <div class="p-col-12 p-md-2">
    <button pButton pRipple type="button" iconPos="right" label="Submit" (click)="forgotPasswordClick()"
    class="p-button-raised p-button-primary p-mr-2 p-mb-2" style=" float:right;"></button>
  </div>
  <div class="p-col-12 p-md-2"></div>
</div></div>
</div>
  <div class="p-col-12 p-md-3">
  </div>
</div>
    <div class="p-fluid p-formgrid p-grid" *ngIf="successMessage">
      <div class="p-field p-col-12 p-md-3">
      </div>
      <div class="p-field p-col-12 p-md-6" ><br><br>
          <span style="color:green;">Please click the confirmation link which received in the email for the new password</span>
      </div>
      <div class="p-field p-col-12 p-md-3">
      </div>
  </div>
  </form>
</div>
</div>
</div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 1000;" *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
</div>