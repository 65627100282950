import { Component, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from '../../services/printcommon.service';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {
  Count=0;
  filteredDept:any;
  cols: any[];
  showLoading:boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  addDisplay: boolean;
  featuresData:any=[];
  featureName:any;
  featureNameError:any;
  code:any;
  codeError:any;
  departmentsData:any;
  active:boolean;
  responseMessage:any;
  Type:any="Add";
  FromEdit:boolean=false;
  savelabel:any="Save";
  roleGuid:any="";
  departmentGuid:any="";
  featureHeader:any="Add Feature";
  Savelabel="Save";
  UserID = sessionStorage.getItem("userGuid");
  roleAccess:boolean;
  roleData:any;
  Description="";
  featureId=0;
  applicationError="";
  selectedApplication:any;
  applicationsData=[];
  rolesData=[];
  selectedRole=[];
  roleError="";
  constructor(private _facadeService: FacadeService,private route: ActivatedRoute,public printService: PrintcommonService) { }
    ngOnInit(): void {
      this.showLoading = true;
      sessionStorage.applicationName = "";
      sessionStorage.roleAccess = "";
      sessionStorage.featureName = "";
      sessionStorage.code = "";
      sessionStorage.active ="";
      sessionStorage.roleNames="";
      this.cols = [
        { field: 'applicationName', header: 'Application', dynamicPlaceHolder: 'Search' },
        { field: 'featureName', header: 'Feature', dynamicPlaceHolder: 'Search' },
        { field: 'code', header: 'Code', dynamicPlaceHolder: 'Search' },  
        { field: 'roleNames', header: 'Role', dynamicPlaceHolder: 'Search' },  
        { field: 'roleAccess', header: 'Role Access', dynamicPlaceHolder: 'Search' },
        { field: 'active', header: 'Active', dynamicPlaceHolder: 'Search' }
      ];
      this.GetApplications();
    }
    AddNewButton() {
      this.showLoading = true;
      this.Type="Add";
      this.selectedApplication="";
      this.applicationError="";
      this.featureNameError="";
      this.codeError="";
      this.responseMessage="";
      this.featureName="";
      this.code="";
      this.active=true;
      this.roleAccess=false;
      this.FromEdit=false;
      this.addDisplay = true; 
      this.savelabel="Save";
      this.featureHeader="Add Feature";
      this.Savelabel="Save";
      this.Description="";
      this.featureId=0;
      this.selectedRole=[];
      this.roleError="";
      this.showLoading = false;
    }
    ViewFeature(featureId){
      this.showLoading=true;
      this.Description="";
      this.featureNameError="";
      this.selectedApplication="";
      this.applicationError="";
      this.codeError="";
      this.responseMessage="";
      this.featureName="";
      this.code="";
      this.selectedRole=[];
      this.roleError="";
      this.featureHeader="Update Feature";
      this.Savelabel="Update";
      this.featureId=0;
      this._facadeService.GetFeatureById(featureId).subscribe(
        (data) => {
          this.addDisplay = true;
          this.active=data.returnObject.active;
          this.roleAccess=data.returnObject.roleAccess;
          this.featureName=data.returnObject.featureName;
          this.code=data.returnObject.code;
          this.Description=data.returnObject.description;
          this.featureId=data.returnObject.featureId;
          this.selectedApplication=this.applicationsData.find(m=>m.applicationId==data.returnObject.applicationId);
          this.Type="Edit";
          this.FromEdit=true;
          this.savelabel="Update";
          var selroles= [];
          if(data.returnObject.featureRoleIds!=null&&data.returnObject.featureRoleIds.length>0){
            data.returnObject.featureRoleIds.forEach(element => {
              var role = this.rolesData.find(m=>m.roleId==element);
              selroles.push(role);
            });
          }
          this.selectedRole=selroles;
          this.showLoading=false;
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }

    GetFeatures(){
      var model ={
        "featureId": 0,
        "applicationId": 0,
        "bindRoles": true
      }
      this._facadeService.GetFeatures(model).subscribe(
        (data) => {
        this.featuresData = data.returnObject;
        this.Count = 0;
        if(this.featuresData != null && this.featuresData != undefined&&this.featuresData.length > 0)
        {
          this.featuresData.forEach(element => {
            element.roleAccess=element.roleAccess==true?"Y":"N";
            element.active=element.active==true?"Y":"N";
          });
          this.Count=this.featuresData.length;
        }
        this.showLoading = false;
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }
    GetApplications(){
      this._facadeService.GetApplications().subscribe(
        (data) => {
        this.applicationsData = data.returnObject;
        this.GetRoles();
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }
    GetRoles(){
      this._facadeService.GetAllRoles().subscribe(
        (data) => {
        this.rolesData = data.returnObject;
        if(this.roleData != null && this.rolesData != undefined)
        {
          this.roleData.forEach(e => {
            e.roleName = e.roleName + " (" +  e.departmentName + ")";
          });
        }
        this.GetFeatures();
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }
    CreateFeature(){
      this.featureNameError="";
      this.codeError="";
      this.applicationError="";
      this.responseMessage="";
      this.roleError="";
      if(this.selectedApplication==null||this.selectedApplication==""||this.selectedApplication==undefined)
        this.applicationError="Please select application.";
      else if(this.featureName==""||this.featureName==null||this.featureName==undefined)
        this.featureNameError="Please enter feature.";
      else if(this.code==""||this.code==null||this.code==undefined)
        this.codeError="Please enter code.";
      else if(this.selectedRole==null||this.selectedRole.length==0)
        this.roleError="Please select role.";
      else{
        this.showLoading=true;
        var featureRoleIds=[];
        if(this.selectedRole.length>0){
          this.selectedRole.forEach(element => {
            featureRoleIds.push(element.roleId);
          });
        }
        var feature={
          featureName:this.featureName,
          code:this.code,
          Active:this.active,
          Description:this.Description,
          roleAccess:this.roleAccess,
          featureId:this.featureId,
          applicationId:this.selectedApplication.applicationId,
          featureRoleIds:featureRoleIds
        };
        if(this.featureId>0){
          this._facadeService.UpdateFeature(feature).subscribe(
            (data) => {
              if(data.returnCode==0){
                this.addDisplay=false;
                this.GetFeatures();
              }
              else{
                this.responseMessage=data.returnMessage;
                this.showLoading=false;
              }
          },
          (error) => { 
            this.showLoading=false;
            console.log(error);
          }
        );
        }
        else{
          this._facadeService.CreateFeature(feature).subscribe(
            (data) => {
              if(data.returnCode==0){
                this.addDisplay=false;
                this.GetFeatures();
              }
              else{
                this.responseMessage=data.returnMessage;
                this.showLoading=false;
              }
            },
            (error) => { 
              this.showLoading=false;
              console.log(error);
            }
          );
        }
      }
    }
    delete_row (ws, row_index){
      let range = XLSX.utils.decode_range(ws["!ref"])
      for(var R = row_index; R < range.e.r; ++R){
          for(var C = range.s.c; C <= range.e.c; ++C){
              ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
              if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
                ws[this.ec(R+1, C)].v  = "";
          }
      }
      range.e.r--
      delete (ws['G1'])
      ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
    }
    ec(r, c) {
      return XLSX.utils.encode_cell({r:r,c:c})
    }
    export(){
      /* table id is passed over here */   
     let element = document.getElementById('excel-roles'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     ws['!cols'] = [{ wpx : 190 },{ wpx : 180 },{ wpx : 50 },{ wpx : 50 }];
     /* save to file */
     XLSX.writeFile(wb, "Features.xlsx");
    }
    print() {
      var Content = document.getElementById('invoice-content').innerHTML;
      this.printService.Print(Content,'Features','trSearch',true);
  }
  test(val,field,dt)
  {
    if(field == "active")
      sessionStorage.active = val;
    else if(field == "roleAccess")
      sessionStorage.roleAccess = val;
      else if(field == "code")
      sessionStorage.code = val;
      else if(field == "featureName")
      sessionStorage.featureName = val;
      else if(field == "applicationName")
      sessionStorage.applicationName = val;
      else if(field == "roleNames")
      sessionStorage.roleNames = val;
    dt = dt.filter(val,field, 'contains');
    this.CountData();
  }
  CountData(){
    if(this.featuresData != undefined && this.featuresData != null)
    {
      var temp = this.featuresData;
      if(sessionStorage.active != ""&&sessionStorage.active != null)
        temp = temp.filter(x=> x.active!=null&&x.active!=""&&x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
        if(sessionStorage.applicationName != ""&&sessionStorage.applicationName != null)
        temp = temp.filter(x=> x.applicationName!=null&&x.applicationName!=""&&x.applicationName.toLowerCase().includes(sessionStorage.applicationName.toLowerCase()));
        if(sessionStorage.roleNames != ""&&sessionStorage.roleNames != null)
        temp = temp.filter(x=> x.roleNames!=null&&x.roleNames!=""&&x.roleNames.toLowerCase().includes(sessionStorage.roleNames.toLowerCase()));
      if(sessionStorage.roleAccess != "")
        temp = temp.filter(x=> x.roleAccess!=null&&x.roleAccess!=""&&x.roleAccess.toLowerCase().includes(sessionStorage.roleAccess.toLowerCase()));
        if(sessionStorage.code != "")
        temp = temp.filter(x=> x.code!=null&&x.code!=""&&x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
        if(sessionStorage.featureName != "")
        temp = temp.filter(x=> x.featureName!=null&&x.featureName!=""&&x.featureName.toLowerCase().includes(sessionStorage.featureName.toLowerCase()));
       this.Count = temp.length;
    }
    else
      this.Count = 0;
  }
  }