import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from '../../services/printcommon.service';
@Component({
  selector: 'app-applicationgroup',
  templateUrl: './applicationgroup.component.html',
  styleUrls: ['./applicationgroup.component.scss']
})
export class ApplicationgroupComponent implements OnInit { 
  cols: any[];
  showLoading:boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  addDocsDisplay: boolean;
  applicationsData:any=[];
  groupName:any;
  groupNameError:any;
  Count=0;
  ApplictaionError:any;
  responseMessage:any;
  Type:any="Add";
  FromEdit:boolean=false;
  savelabel:any="Save";
  selectedApplication:any=[];
  active:boolean=true;
  AppGroupGuid:any;
  AppGroupId:any=0;
  groupsData:any=[];
  UserID = sessionStorage.getItem("userGuid");
  ViewMode = this.baseUrl["API_ENDPOINTS"]["ApplicationGroupAccesss"];
  constructor(private _facadeService: FacadeService,public printService: PrintcommonService, @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }

  ngOnInit(): void {
    sessionStorage.appGroupName = "";
    sessionStorage.applications = "";
    sessionStorage.active ="";
  this.cols = [
  { field: 'appGroupName', header: 'Application Group', dynamicPlaceHolder: 'Search' },   
  { field: 'applications', header: 'Applications', dynamicPlaceHolder: 'Search' },   
  { field: 'active', header: 'Active', dynamicPlaceHolder: 'Search' }
];
    this.GetApplications();
    this.GetGroups();
  }
  AddNewButton() {
    this.Type="Add";
    this.groupNameError="";
    this.ApplictaionError="";
    this.responseMessage="";
    this.selectedApplication=[];
    this.groupName="";
    this.FromEdit=false;
    this.addDocsDisplay = true; 
    this.savelabel="Save";
    this.active=true;
  }

  ViewGroup(GroupId){
    this.showLoading=true;
    this.groupNameError="";
    this.ApplictaionError="";
    this.responseMessage="";
    this.groupName="";
    this.selectedApplication=[];
    this._facadeService.GetGroupApplicationDetailsById(GroupId).subscribe(
      (data) => {
        this.addDocsDisplay = true; 
        this.showLoading=false;
        if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
            this.groupName=data.returnObject.appGroupName;
            this.AppGroupGuid=data.returnObject.appGroupGuid;
            this.AppGroupId=data.returnObject.appGroupId;
            this.active=data.returnObject.active;
            if(data.returnObject.fullApplications!=null&&data.returnObject.fullApplications.length>0){
              var sa=[];
              this.applicationsData.forEach(element => {
                var grop= data.returnObject.fullApplications.find(m=>m.applicationGuid==element.applicationGuid);
                if(grop!=null){
                  sa.push(element);
                }
              });
              this.selectedApplication=data.returnObject.fullApplications;
            }
          }
          this.Type="Edit";
          this.FromEdit=true;
          this.savelabel="Update";
    },
    (error) => { 
      this.showLoading=false;
      console.log(error);
    }
  );
  }

  GetApplications(){
    this.showLoading=true;
    this._facadeService.GetApplications().subscribe(
      (data) => {
        this.showLoading=false;
      this.applicationsData = data.returnObject;
      console.log("Applications data ::" + JSON.stringify(this.applicationsData));
    },
    (error) => { 
      this.showLoading=false;
      console.log(error);
    }
  );
  }
  GetGroups(){
    this.showLoading=true;
    this._facadeService.GetAllApplicationGroups().subscribe(
      (data) => {
       
      this.groupsData = data.returnObject;
      this.groupsData.forEach(element => {
        element.active=element.active==true?"Y":"N";
      });
      this.Count=this.groupsData.length;
      this.showLoading=false;
      console.log("Groups data ::" + JSON.stringify(this.groupsData));
    },
    (error) => { 
      this.showLoading=false;
      console.log(error);
    }
  );
  }
  CreateApplicationGroup(){
    this.groupNameError="";
    this.ApplictaionError="";
    this.responseMessage="";
    if(this.groupName==""||this.groupName==null||this.groupName==undefined){
        this.groupNameError="Please enter group name.";
    }
    else if(this.selectedApplication==""||this.selectedApplication==null||this.selectedApplication==undefined){
      this.ApplictaionError="Please select application.";
  }
  else{
    this.showLoading=true;
    var apps = [];
    this.selectedApplication.forEach(element => {
      apps.push(element.applicationGuid);
    });
    var group={
      GroupName:this.groupName,
      ApplicationIDs:apps,
      Active:this.active,
      AppGroupGuid:this.AppGroupGuid,
      AppGroupId:this.AppGroupId,
      ActionBy:this.UserID
    };
    if(this.AppGroupId>0){
      this._facadeService.UpdateApplicationGroup(group).subscribe(
        (data) => {
          this.showLoading=false;
          if(data.returnCode==0){
            this.addDocsDisplay=false;
           location.reload();
          }
          else{
           this.responseMessage=data.returnMessage;
          }
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }
    else{
      this._facadeService.CreateApplicationGroup(group).subscribe(
        (data) => {
          this.showLoading=false;
          if(data.returnCode==0){
            this.addDocsDisplay=false;
            location.reload();
          }
          else{
           this.responseMessage=data.returnMessage;
          }
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }
  }
  }
  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
            ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
            if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
              ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['D1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }
  export(){
    /* table id is passed over here */   
   let element = document.getElementById('excel-Departments'); 
   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
   /* generate workbook and add the worksheet */
   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
   this.delete_row(wb.Sheets.Sheet1, 1);
   ws['!cols'] = [{ wpx : 140 },{ wpx : 500 },{ wpx : 50 }];
   /* save to file */
   XLSX.writeFile(wb, "Application Groups.xlsx");
  }
  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,'Application Groups','trSearch',true);
}
IsActiveChange(){
  if(!this.active){
    this.showLoading=true;
    this._facadeService.CheckApplicationGroupInactiveById(this.AppGroupGuid).subscribe(
      (data) => {
        this.showLoading=false;
       if(data.returnObject==true){
         this.responseMessage="This group is already associated with the User. You cannot inactive";
         this.active=true;
       }
    },
    (error) => { 
      this.showLoading=false;
      console.log(error);
    }
  );
  }
}

test(val,field,dt)
  {
    if(field == "active")
      sessionStorage.active = val;
    else if(field == "applications")
      sessionStorage.applications = val;
      else if(field == "appGroupName")
      sessionStorage.appGroupName = val;
    dt = dt.filter(val,field, 'contains');
    this.CountData();
  }
  CountData(){
    if(this.groupsData != undefined && this.groupsData != null)
    {
      var temp = this.groupsData;
      if(sessionStorage.active != ""&&sessionStorage.active != null)
        temp = temp.filter(x=> x.active!=null&&x.active!=""&&x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
      if(sessionStorage.applications != "")
        temp = temp.filter(x=> x.applications!=null&&x.applications!=""&&x.applications.toLowerCase().includes(sessionStorage.applications.toLowerCase()));
        if(sessionStorage.appGroupName != "")
        temp = temp.filter(x=> x.appGroupName!=null&&x.appGroupName!=""&&x.appGroupName.toLowerCase().includes(sessionStorage.appGroupName.toLowerCase()));
      
       this.Count = temp.length;
    }
    else
      this.Count = 0;
  }
}
