import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';

@Component({
  selector: 'app-userdetails',
  templateUrl: './userdetails.component.html',
  styleUrls: ['./userdetails.component.scss']
})
export class UserdetailsComponent implements OnInit {
  show_button: Boolean = false;
  show_eye: Boolean = false;
  roles = [];
  userId = sessionStorage.getItem("userGuid");
  showLoading: boolean;
  ActivevalRadio: string = "Y";
  businessunits: any = [];
  businessunitError: any;
  selectedbusinessunits: any = [];

  departments: any = [];
  departmentError: any;
  selectedDepartments: any = [];
  defaultDepData: any = [];
  selectedDep: any;

  joiningDate: Date;
  JoiningDateError: any;

  roleError: any;
  selectedRole: any = [];
  appGroupData: any = [];
  selectedAppGroup: any = [];
  ApplicationDialog: boolean;
  appData: any = [];
  selectedApp: any;
  ApplicationGroupError: any;
  ApplicationError: any;
  applicationData: any = [];
  defaultapplicationData: any = [];
  ApplicationMainError: any;
  UsersData: any = [];
  selectedUser: any;
  SelectedLandingApp: any;
  firstNameuser: any = "";
  lastName: any = "";
  useremail: any = "";
  userCode: any = "New";
  active: any;
  Userpassword: any = "";
  managerId: any;
  firstNamError: any;
  lastNameError: any;
  emailError: any;
  primaryDeptError: any;
  passwordError: any;
  supervisorError: any;
  mainError: any;
  defaultappError: any;
  userdetails: any;
  deleteADisplay: any;
  AreYouSureText: any;
  removeappgroupid: any;
  removeappid: any;
  SelectedUserId: any = 0;
  labeltext: any = "Save";
  HeaderText: any = "New User";
  EditMode: boolean;
  title: any = "";
  TerminationDateError: any;
  termDate: Date;
  timezone: any;
  timezoneData: any;
  timezoneError: any;
  selectedtimezone: any;
  site: any;
  selectedsite: any;
  siteError: any;
  siteData: any;
  id = 0;
  siteGUID = 0;
  DependencyData = [];
  activedisplay: boolean = false;
  cols = [];
  userGridError = "";
  usersDisplay = false;
  SupUsersData = [];
  InActiveDisplay = false;
  supervisors = [];
  today: string;
  approvalsData = [];
  InActiveApprovalsDisplay = false;
  colsdocs = [];
  showUpdateButton = true;
  constructor(private _facadeService: FacadeService, private router: Router,
    private route: ActivatedRoute, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'firstName', header: 'First Name', dynamicPlaceHolder: 'Search' },
      { field: 'lastName', header: 'Last Name', dynamicPlaceHolder: 'Search' },
      { field: 'defaultDepartment', header: 'Primary Department', dynamicPlaceHolder: 'Search' },
      { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search' },
      { field: 'roleName', header: 'Role', dynamicPlaceHolder: 'Search' }
    ];
    this.colsdocs = [
      { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Search by Document id' },
      { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search by title' },
      { field: 'departmentName', header: 'Department', dynamicPlaceHolder: 'Department' },
      { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Search by Rev' },
      { field: 'eco', header: 'ECO', dynamicPlaceHolder: 'Search by ECO' },
      { field: 'owner', header: 'Originator', dynamicPlaceHolder: 'Search by Originator' },
      { field: 'delegatedBy', header: 'Delegated By', dynamicPlaceHolder: 'Search' },
    ];
    var userGu = this.route.snapshot.queryParamMap.get('userguid');
    this.lastName = "";
    this.Userpassword = "";
    if (userGu != null && userGu != "" && userGu != undefined) {
      this.HeaderText = "Update User";
      this.EditMode = true;
      this.getUserDetailsByGuid(userGu);
    }
    else {
      this.HeaderText = "New User";
      this.EditMode = false;
      this.getDepartments('', '', '');
      this.getBusinessUnit('');
      this.getApplicationGroups('', '');
      this.getUserList('');
    }
    this.gettimezones();
    this.getsites();
  }

  getBusinessUnit(userunits) {
    this.showLoading = true;
    this._facadeService.GetBusinessUnit().subscribe(
      (data) => {
        this.businessunits = data.returnObject;
        this.showLoading = false;
        if (userunits != null && userunits.length > 0) {
          this.businessunits.forEach(element => {
            var selected = userunits.find(m => m.id == element.businessUnitGUID);
            if (selected != null) {
              this.selectedbusinessunits.push(element);
            }
          });
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  getDepartments(userDepts, landing, manager) {
    this.showLoading = true;
    this._facadeService.GetDepartments().subscribe(
      (data) => {
        this.departments = data.returnObject;
        this.showLoading = false;
        if (userDepts != null && userDepts.length > 0) {
          this.departments.forEach(element => {
            var selected = userDepts.find(m => m.id == element.departmentGuid);
            if (selected != null) {
              this.selectedDepartments.push(element);
            }
          });
          if (this.selectedDepartments != null && this.selectedDepartments.length > 0) {
            this.defaultDepData = this.selectedDepartments;
            if (landing != null && landing != "" && landing != undefined) {
              this.selectedDep = this.defaultDepData.find(m => m.departmentName == landing);
              // this.getUserList(manager);
            }
          }
          this.getRolesByDepartmentGuids();
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  getRolesByDepartmentGuids() {
    this.departmentError = "";
    var departmentArray = [];
    this.selectedRole = [];
    this.defaultDepData = this.selectedDepartments;
    if (this.selectedDepartments.length > 0) {
      for (var i in this.selectedDepartments) {
        departmentArray.push(this.selectedDepartments[i].departmentGuid);
      }
      this._facadeService
        .getRolesByDepartmentGuids(departmentArray)
        .subscribe((result: any) => {
          this.roles = result.returnObject;
          if (this.roles != null && this.roles.length > 0) {
            this.roles.forEach(element => {
              element.fullName = element.roleName + " (" + element.departmentName + ")";
            });
          }
          if (this.userdetails != null && this.userdetails.userRoles != null && this.userdetails.userRoles.length > 0) {
            this.roles.forEach(element => {
              var role = this.userdetails.userRoles.find(m => m.id == element.roleGuid);
              if (role != null) {
                this.selectedRole.push(element);
              }
            });
          }
        },
          (error) => {
            console.log(error);
          });
    }
    else {
      this.departmentError = "Please select atlest one department.";
      this.roles = [];
      this.selectedRole = [];
    }
  }
  getApplicationGroups(userapps, landingApp) {
    this.showLoading = true;
    this.appData = [];
    this.selectedApp = "";
    this.UsersData = [];
    this.selectedUser = "";
    this._facadeService.GetApplicationGroups().subscribe(
      (data) => {
        this.appGroupData = data.returnObject;
        this.showLoading = false;
        if (userapps != null && userapps.length > 0) {
          userapps.forEach(element => {
            var gropnam = this.appGroupData.find(m => m.appGroupGuid == element.id);
            if (element.isdefault == true) {
              this.SelectedLandingApp = element;
            }
            if (this.selectedAppGroup != null && this.selectedAppGroup.length > 0) {
              var isExist = this.selectedAppGroup.find(m => m.appGroupGuid == element.applicationGroupGuid);
              if (isExist == null) {
                this.selectedAppGroup.push(gropnam);
              }
            }
            else {
              this.selectedAppGroup.push(gropnam);
            }
          });
          this.GetApplicationsByGroups(landingApp);
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getUserList(managerguid) {
    this.showLoading = true;
    this._facadeService
      .GetUsers()
      .subscribe((result: any) => {
        this.showLoading = false;
        result.returnObject.forEach((value, key) => {
          value.fullName = value.firstName + " " + value.lastName;
        });
        this.UsersData = result.returnObject;

        if (managerguid != null && managerguid != "" && managerguid != undefined && this.UsersData.length > 0) {
          var us = []
          this.UsersData.forEach(m => {
            if (m.firstName == this.firstNameuser && m.lastName == this.lastName) {

            }
            else
              us.push(m);
          });
          // this.UsersData=this.UsersData.filter(m=>m.firstName != this.firstNameuser && m.lastName != this.lastName);
          this.UsersData = us;
          this.selectedUser = this.UsersData.find(m => m.userGuid.toUpperCase() == managerguid.toUpperCase());
        }
        if (this.EditMode) {
          this.SupUsersData = this.UsersData.filter(m => m.userId != this.userdetails.userId);
        }
      },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
  }
  RemoveApplication(appgroup, appid, appName) {
    this.removeappgroupid = appgroup;
    this.removeappid = appid;
    this.AreYouSureText = "Are you sure you want to delete " + appName + "?";
    this.deleteADisplay = true;
  }
  AddNewApplications() {
    this.ApplicationDialog = true;
    this.appData = [];
    this.selectedApp = "";
    this.selectedAppGroup = "";
    this.ApplicationMainError = "";
    this.ApplicationGroupError = "";
    this.ApplicationError = "";
  }

  ValidateUser() {
    this.firstNamError = "";
    this.lastNameError = "";
    this.emailError = "";
    this.passwordError = "";
    this.departmentError = "";
    this.roleError = "";
    this.supervisorError = "";
    this.mainError = "";
    this.primaryDeptError = "";
    this.JoiningDateError = "";
    this.businessunitError = "";
    this.ApplicationGroupError = "";
    this.defaultappError = "";
    this.TerminationDateError = "";
    this.timezoneError = "";
    this.siteError = "";
    if (this.firstNameuser == null || this.firstNameuser == "" || this.firstNameuser == undefined) {
      this.firstNamError = "Please enter first name";
    }
    else if (this.lastName === null || this.lastName == "" || this.lastName == undefined) {
      this.lastNameError = "Please enter last name";
    }
    else if (this.useremail == null || this.useremail == "" || this.useremail == undefined) {
      this.emailError = "Please enter email";
    }
    else if (this.Userpassword == null || this.Userpassword == "" || this.Userpassword == undefined) {
      this.passwordError = "Please enter password";
    }
    else if (this.selectedDepartments == null || this.selectedDepartments.length == 0) {
      this.departmentError = "Please select department";
    }
    else if (this.selectedDep == null || this.selectedDep == undefined) {
      this.primaryDeptError = "please select primary department";
    }
    else if (this.selectedUser == null || this.selectedUser == undefined) {
      this.supervisorError = "Please select supervisor";
    }
    else if (this.joiningDate == null || this.joiningDate == undefined) {
      this.JoiningDateError = "Please enter joining date";
    }
    else if (this.selectedRole == null || this.selectedRole.length == 0) {
      this.roleError = "Please select roles";
    }
    else if (this.selectedbusinessunits == null || this.selectedbusinessunits == "" || this.selectedbusinessunits == undefined || this.selectedbusinessunits.length == 0) {
      this.businessunitError = "Please select business unit";
    }
    else if (this.selectedAppGroup == null || this.selectedAppGroup.length == 0) {
      this.ApplicationGroupError = "Please select application group";
    }
    else if (this.applicationData == null || this.applicationData.length == 0) {
      this.defaultappError = "Please select Application Group";
    }
    else if (this.SelectedLandingApp == null || this.SelectedLandingApp == "" || this.SelectedLandingApp == undefined) {
      this.mainError = "Please select default application";
    }
    else if (!this.isValid(this.useremail.trim())) {
      this.emailError = "Please enter correct email";
    }
    else if (this.SelectedUserId > 0 && (this.termDate == null || this.termDate == undefined) && this.ActivevalRadio == "N") {
      this.TerminationDateError = "Please enter termination date";
    }
    else if (this.selectedtimezone == null || this.selectedtimezone == "" || this.selectedtimezone == undefined || this.selectedtimezone.length == 0) {
      this.timezoneError = "Please select time zone";
    }
    else if (this.selectedsite == null || this.selectedsite == "" || this.selectedsite == undefined || this.selectedsite.length == 0) {
      this.siteError = "Please select site";
    }
    else if (this.EditMode && this.ActivevalRadio == "N" && !this.showUpdateButton) {
      this.InActiveApprovalsDisplay = true;
    }
    else if (this.EditMode && this.DependencyData != null && this.DependencyData.length > 0 && this.ActivevalRadio == "N") {
      this.InActiveDisplay = true;
    }
    else {
      this.CreateUser();
    }
  }
  UpdateUserSupervisor() {
    this.userGridError = "";
    this.supervisors = [];
    var alldataentered = true;
    this.DependencyData.forEach(element => {
      if (element.selectedUser == null || element.selectedUser == "" || element.selectedUser == undefined) {
        alldataentered = false;
      }
      else {
        element.managerId = parseInt(element.selectedUser);
        this.supervisors.push(element);
      }
    });
    if (alldataentered) {
      this.CreateUser();
    }
    else {
      this.userGridError = "Please select all the supervisors.";
    }
  }
  activechange() {
    this.termDate = null;
    if (this.ActivevalRadio != "Y")
      this.activedisplay = true;
    else
      this.activedisplay = false;
  }
  CreateUser() {
    let userDepartments = [];
    let userROles = [];
    let appData = [];
    let groups = [];
    let units = [];
    if (this.selectedDepartments != null && this.selectedDepartments.length > 0) {
      this.selectedDepartments.forEach(element => {
        userDepartments.push(element.departmentGuid);
      });
    }
    if (this.selectedRole != null && this.selectedRole.length > 0) {
      this.selectedRole.forEach(element => {
        userROles.push(element.roleGuid);
      });
    }
    if (this.defaultapplicationData != null && this.defaultapplicationData.length > 0) {
      this.defaultapplicationData.forEach(element => {
        appData.push(element.applicationGuid);
      });
    }
    if (this.selectedAppGroup != null && this.selectedAppGroup.length > 0) {
      this.selectedAppGroup.forEach(element => {
        groups.push(element.appGroupGuid);
      });
    }
    if (this.selectedbusinessunits != null && this.selectedbusinessunits.length > 0) {
      this.selectedbusinessunits.forEach(element => {
        units.push(element.businessUnitGUID);
      });
    }
    var user = {
      FirstName: this.firstNameuser,
      LastName: this.lastName,
      EmailId: this.useremail,
      UserName: this.useremail,
      Active: this.ActivevalRadio,
      Password: this.Userpassword,
      ActionBy: this.userId,
      ManagerId: this.selectedUser.userId,
      JoiningDate: new Date(this.datePipe.transform(new Date(this.joiningDate), "yyyy-MM-dd")),
      TerminationDate: (this.termDate == null || this.termDate == undefined) ? null : this.datePipe.transform(new Date(this.termDate), "yyyy-MM-dd"),
      Title: this.title == null || this.title == undefined ? "" : this.title,
      UserDepartments: userDepartments,
      UserRoles: userROles,
      UserApplications: appData,
      UserGroups: groups,
      UserBusinessUnits: units,
      DefaultApplication: this.SelectedLandingApp.applicationGuid,
      DefaultDepartment: this.selectedDep.departmentGuid,
      UserCode: this.userCode,
      timeZone: this.selectedtimezone.id,
      siteGuid: this.selectedsite.siteGUID,
      UserGuid: this.userdetails != null ? this.userdetails.userGuid : "",
      Supervisors: this.supervisors
    }
    if (this.SelectedUserId > 0) {
      this.showLoading = true;
      this._facadeService.UpdateUser(user).subscribe(
        (data) => {
          this.showLoading = false;
          if (data.returnCode == 0) {
            this.router.navigate(["/users"]);
          }
          else {
            this.mainError = data.returnMessage;
            this.userGridError = data.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else {
      this.showLoading = true;
      this._facadeService.SaveUser(user).subscribe(
        (data) => {
          this.showLoading = false;
          if (data.returnCode == 0) {
            this.router.navigate(["/users"]);
          }
          else {
            this.mainError = data.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }
  isValid(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  getTimeZonesIDs(id) {
    this._facadeService.GetTimezone().subscribe(
      (data) => {
        this.timezoneData = data.returnObject;
        if (id != "" && id != null && id != undefined) {
          this.selectedtimezone = this.timezoneData.find(m => m.id == id);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getsiteIDs(siteGUID) {
    this.showLoading = true;
    this._facadeService.GetSites().subscribe(
      (data) => {
        this.siteData = data.returnObject;
        if (siteGUID != "" && siteGUID != null && siteGUID != undefined) {
          this.selectedsite = this.siteData.find(m => m.siteGUID == siteGUID);
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getUserDetailsByGuid(usergid) {
    this.showLoading = true;
    this._facadeService
      .GetUserDetailsnoCache(usergid)
      .subscribe((result) => {
        this.showLoading = false;
        var user = result.returnObject;
        this.userdetails = result.returnObject;
        this.firstNameuser = user.firstName;
        this.SelectedUserId = user.userId;
        this.lastName = user.lastName;
        this.joiningDate = new Date(user.joiningDate);
        this.userCode = user.userCode;
        this.useremail = user.username.trim();
        this.Userpassword = user.password;
        this.title = user.title;
        this.selectedtimezone = this.getTimeZonesIDs(user.timeZone);
        this.selectedsite = this.getsiteIDs(user.siteGuid);
        this.labeltext = "Update";
        this.getDepartments(user.userDepartments, user.defaultDepartment, user.managerGuid);
        this.getBusinessUnit(user.userBusinessUnits);
        this.getApplicationGroups(user.userGroups, user.defaultApplication);
        this.ActivevalRadio = user.active;
        this.getUserList(user.managerGuid);
        this.GetDependencySupervisorList();
        this.approvalNeededData();
      },
        (error) => {
          console.log(error);
        });
  }
  approvalNeededData() {
    this.approvalsData = [];
    this.today = this.datePipe.transform(this.today, 'MM/dd/yyyy');
    var myApprovalModel = {
      documentId: 0,
      departmentId: 0,
      isArchived: 'N',
      documentTypeId: 0,
      documentStatus: "In-Process",
      todayDate: this.today,
      userID: this.userdetails.userId,
      userGuid: this.userdetails.userGuid
    };
    this.showLoading = true;
    this._facadeService.approvalNeededData(myApprovalModel).subscribe(
      (data) => {
        if (data.returnObject.length > 0) {
          data.returnObject.forEach((value, key) => {
            if (value.status == 'New' || value.status == 'In-Process') {
              value.lastAccessedDate = "--";
            }
            else {
              value.lastAccessedDate = this.datePipe.transform(value.lastAccessedDate, 'MM/dd/yyyy');
            }
            if (value.eco != null && value.eco != "" && value.eco != undefined) {
              value.eco = value.eco;
            }
            else {
              value.eco = "N/A";
            }
          });
        }
        this.approvalsData = data.returnObject;
        this.showLoading = false;
        if (this.approvalsData != null && this.approvalsData.length > 0) {
          this.DelegatedDocuments();
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  DelegatedDocuments() {
    this.showLoading = true;
    this._facadeService.DelegatedDocuments(this.userdetails.userId).subscribe(
      (data) => {
        var approvals = [];
        this.showLoading = false;
        if (data.returnObject != null && data.returnObject != "" && data.returnObject != undefined && data.returnObject.length > 0) {
          this.approvalsData.forEach(element => {
            var d = data.returnObject.find(m => m.id == element.id);
            if (d == null) {
              this.showUpdateButton = false;
              approvals.push(element);
            }
            else {
              element.delegatedBy = d.owner;
              approvals.push(element);
            }
          });
        }
        else {
          this.showUpdateButton = false;
        }
        if (approvals != null && approvals.length > 0) {
          this.approvalsData = approvals;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  BackClick() {
    this.router.navigate(["/users"]);
  }

  gettimezones() {
    this._facadeService.GetTimezone().subscribe(
      (data) => {
        this.timezone = data;
        this.timezone = this.timezone.returnObject;
        console.log("Time zone date", this.timezone);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getsites() {
    this._facadeService.GetSites().subscribe(
      (data) => {
        this.site = data;
        this.site = this.site.returnObject;
        console.log("Site Data: ", this.site);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  GetApplicationsByGroups(landingApp) {
    this.applicationData = [];
    this.defaultapplicationData = [];
    this.showLoading = true;
    var g = [];
    this.selectedAppGroup.forEach(element => {
      g.push(element.appGroupGuid);
    });
    this._facadeService
      .GetGroupApplicationData(g)
      .subscribe((result: any) => {
        this.showLoading = false;
        this.applicationData = result.returnObject;
        let defapp = [];
        if (this.applicationData != null && this.applicationData.length > 0) {
          this.applicationData.forEach(element => {
            if (defapp != null && defapp.length > 0) {
              var isexist = defapp.find(m => m.applicationGuid == element.applicationGuid);
              if (isexist == null) {
                defapp.push(element);
              }
            }
            else {
              defapp.push(element);
            }
          });
          this.defaultapplicationData = defapp;
          if (landingApp != null && landingApp != "" && landingApp != undefined) {
            this.SelectedLandingApp = this.defaultapplicationData.find(m => m.application == landingApp);
          }
        }
      },
        (error) => {
          console.log(error);
        });
  }

  GetDependencySupervisorList() {
    this._facadeService.GetDependencySupervisorList(this.userdetails.userId).subscribe(
      (data) => {
        this.DependencyData = data.returnObject;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  CheckUsers() {
    this.usersDisplay = true;
    this.InActiveDisplay = false;
  }
  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }
}
