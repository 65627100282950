import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem} from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from '../../services/printcommon.service';
@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  Count=0;
  filteredDept:any;
  cols: any[];
  showLoading:boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  addDisplay: boolean;
  rolesData:any=[];
  roleName:any;
  roleNameError:any;
  selecteddepartment:any;
  selecteddepartmentError:any;
  departmentsData:any;
  active:boolean;
  responseMessage:any;
  Type:any="Add";
  FromEdit:boolean=false;
  savelabel:any="Save";
  roleGuid:any="";
  departmentGuid:any="";
  roleHeader:any="Add Role";
  Savelabel="Save";
  UserID = sessionStorage.getItem("userGuid");
  productRelated:boolean;
  roleData:any;
  AllProductsValue:boolean=true;
  constructor(private _facadeService: FacadeService,private route: ActivatedRoute,public printService: PrintcommonService) { }
    ngOnInit(): void {
      sessionStorage.productRelated = "";
      sessionStorage.roleName = "";
      sessionStorage.departmentName = "";
      sessionStorage.active ="";
      this.AllProductsValue = true;
      if(this.route.snapshot.paramMap.get('Active')!=undefined)
      {
        var ii = this.route.snapshot.paramMap.get('Active');
        if(ii != null && ii != undefined && ii != '')
          this.AllProductsValue = ii == 'false' ? false : true;
      }
      this.cols = [
        { field: 'roleName', header: 'Role', dynamicPlaceHolder: 'Search' },
        { field: 'departmentName', header: 'Department', dynamicPlaceHolder: 'Search' },  
        { field: 'productRelated', header: 'Product Related', dynamicPlaceHolder: 'Search' },
        { field: 'active', header: 'Active', dynamicPlaceHolder: 'Search' }
      ];
      this.GetRoles();
    }
    AddNewButton() {
      this.Type="Add";
      this.roleNameError="";
      this.selecteddepartmentError="";
      this.responseMessage="";
      this.roleName="";
      this.selecteddepartment="";
      this.active=true;
      this.productRelated=false;
      this.FromEdit=false;
      this.addDisplay = true; 
      this.savelabel="Save";
      this.roleGuid="";
      this.GetDepartments("");
      this.roleHeader="Add Role";
      this.Savelabel="Save";
      this.responseMessage="";
    }
    IsActiveChange(){
      this.responseMessage="";
      if(!this.active){
        this.showLoading=true;
        this._facadeService.CheckRoleInactiveById(this.roleGuid).subscribe(
          (data) => {
            this.showLoading=false;
           if(data.returnObject==true){
            this.responseMessage="This role is already associated with the User. You cannot inactive";
             this.active=true;
           }
        },
        (error) => { 
          this.showLoading=false;
          console.log(error);
        }
      );
      }
    }
    filterDepartments(event) {
      let filtered : any[] = [];
      let query = event.query;
      for(let i = 0; i < this.departmentsData.length; i++) {
          let dept = this.departmentsData[i];
          if (dept.departmentName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
              filtered.push(dept);
          }
      }    
      this.filteredDept = filtered;
  }
    ViewRole(roleGuid){
      this.showLoading=true;
      this.roleNameError="";
      this.selecteddepartmentError="";
      this.responseMessage="";
      this.roleName="";
      this.selecteddepartment="";
      this.roleHeader="Update Role";
      this.Savelabel="Update";
      this._facadeService.ViewRole(roleGuid).subscribe(
        (data) => {
          this.addDisplay = true; 
          this.showLoading=false;
          this.roleData = data.returnObject;
          if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
            this.active=data.returnObject.active=="Y"?true:false;
            this.productRelated=data.returnObject.productRelated;
            this.roleName=data.returnObject.roleName;
            this.GetDepartments(data.returnObject.departmentGuid);
          }
            this.roleGuid=data.returnObject.roleGuid;
            this.Type="Edit";
            this.FromEdit=true;
            this.savelabel="Update";
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }

    GetRoles(){
      this.showLoading=true;
      this._facadeService.GetAllRoles().subscribe(
        (data) => {
        this.rolesData = data.returnObject;
        if(!this.AllProductsValue && this.rolesData != null && this.rolesData != undefined)
        {
          if(this.rolesData.length > 0)
          {
            this.rolesData =  this.rolesData.filter(x=>x.active.toLowerCase() == "y");
            this.rolesData =  this.rolesData.filter(x=>x.departmentName.toLowerCase() != "support");
          }
        } 
        this.rolesData.forEach(element => {
          element.productRelated=element.productRelated==true?"Y":"N";
        });
        this.Count=this.rolesData.length;
        this.showLoading=false;
        console.log("Roles data ::" + JSON.stringify(this.rolesData));
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }
    
    CreateRole(){
      this.roleNameError="";
      this.selecteddepartmentError="";
      this.responseMessage="";
      if(this.roleName==""||this.roleName==null||this.roleName==undefined){
          this.roleNameError="Please enter role name.";
      }
      else if(this.selecteddepartment.departmentName==""||this.selecteddepartment.departmentName==null||this.selecteddepartment.departmentName==undefined){
        this.selecteddepartmentError="Please select department.";
    }
    else{
      this.showLoading=true;
      var role={
        RoleName:this.roleName,
        DepartmentGuid:this.selecteddepartment.departmentGuid,
        Active:this.active==true?"Y":"N",
        RoleGuid:this.roleGuid,
        productRelated:this.productRelated
      };
      if(this.roleGuid!=null && this.roleGuid!=""&&this.roleGuid!=undefined){
        this._facadeService.UpdateRole(role,this.UserID).subscribe(
          (data) => {
            this.showLoading=false;
            if(data.returnCode==0){
              this.addDisplay=false;
              if(this.roleData.productRelated){
                if(!this.productRelated){
                  this.SaveProductRole(this.roleData.roleId,this.roleData.roleGuid,this.productRelated);
                }
              }
              else if(this.productRelated){
                this.SaveProductRole(this.roleData.roleId,this.roleData.roleGuid,this.productRelated);
              }
              this.GetRoles();
            }
            else{
             this.responseMessage=data.returnMessage;
            }
        },
        (error) => { 
          this.showLoading=false;
          console.log(error);
        }
      );
      }
      else{
        this._facadeService.CreateRole(role,this.UserID).subscribe(
          (data) => {
            this.showLoading=false;
            if(data.returnCode==0){
              this.addDisplay=false;
              if(this.productRelated){
                this.SaveProductRole(data.returnObject.roleId,data.returnObject.roleGuid,this.productRelated);
              }
              this.GetRoles();
            }
            else{
             this.responseMessage=data.returnMessage;
            }
        },
        (error) => { 
          this.showLoading=false;
          console.log(error);
        }
      );
      }
    }
    }
    SaveProductRole(roleId,roleGuid,productRelated){
      var data = {
        "prid": 0,
  "roleId": roleId,
  "roleGuid": roleGuid,
  "productRelated": productRelated
      }
      this._facadeService.SaveProductRole(data).subscribe(
        (data) => {
      },
      (error) => {
        console.log(error);
      }
    );
    }
    delete_row (ws, row_index){
      let range = XLSX.utils.decode_range(ws["!ref"])
      for(var R = row_index; R < range.e.r; ++R){
          for(var C = range.s.c; C <= range.e.c; ++C){
              ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
              if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
                ws[this.ec(R+1, C)].v  = "";
          }
      }
      range.e.r--
      delete (ws['E1'])
      ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
    }
    ec(r, c) {
      return XLSX.utils.encode_cell({r:r,c:c})
    }
    export(){
      /* table id is passed over here */   
     let element = document.getElementById('excel-roles'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     ws['!cols'] = [{ wpx : 190 },{ wpx : 180 },{ wpx : 50 },{ wpx : 50 }];
     /* save to file */
     XLSX.writeFile(wb, "Roles.xlsx");
    }
    print() {
      var Content = document.getElementById('invoice-content').innerHTML;
      this.printService.Print(Content,'Roles','trSearch',true);
  }
  GetDepartments(departmentGuid){
    this.showLoading=true;
    this._facadeService.GetDepartments().subscribe(
      (data) => {
        this.showLoading=false;
        this.departmentsData=data.returnObject;
        if(departmentGuid!=""&&departmentGuid!=null&&departmentGuid!=undefined){
          this.selecteddepartment=this.departmentsData.find(m=>m.departmentGuid==departmentGuid);
        }
    },
    (error) => { 
      this.showLoading=false;
      console.log(error);
    }
  );
  }

  test(val,field,dt)
  {
    if(field == "active")
      sessionStorage.active = val;
    else if(field == "productRelated")
      sessionStorage.productRelated = val;
      else if(field == "departmentName")
      sessionStorage.departmentName = val;
      else if(field == "roleName")
      sessionStorage.roleName = val;
    dt = dt.filter(val,field, 'contains');
    this.CountData();
  }
  CountData(){
    if(this.rolesData != undefined && this.rolesData != null)
    {
      var temp = this.rolesData;
      if(sessionStorage.active != ""&&sessionStorage.active != null)
        temp = temp.filter(x=> x.active!=null&&x.active!=""&&x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
      if(sessionStorage.productRelated != "")
        temp = temp.filter(x=> x.productRelated!=null&&x.productRelated!=""&&x.productRelated.toLowerCase().includes(sessionStorage.productRelated.toLowerCase()));
        if(sessionStorage.departmentName != "")
        temp = temp.filter(x=> x.departmentName!=null&&x.departmentName!=""&&x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));
        if(sessionStorage.roleName != "")
        temp = temp.filter(x=> x.roleName!=null&&x.roleName!=""&&x.roleName.toLowerCase().includes(sessionStorage.roleName.toLowerCase()));
       this.Count = temp.length;
    }
    else
      this.Count = 0;
  }
  }

