<div class="login-body">
    <div class="login-wrapper">
        <div class="login-image">
            <div class="login-image-content" style="text-align: center">
                <img src="assets/gallery/Client-logo.png" class="logo" alt="FactoryPRO-Logo"
                    style="width: 200px; height: 147px" />
            </div>
            <img src="assets/gallery/c11.png" class="logo" alt="FactoryPRO-Logo" />

            <div class="p-grid" style="text-align: center">
                <div class="col-md-1"></div>
                <div class="col-md-2" style="min-width: 100px;padding-right: 10px;">
                    <img src="assets/gallery/pms.png" class="img-fluid" alt="image" style="width:60px; height:60px;" />
                    <p>
                        Project<br />
                        Management
                    </p>
                </div>
                <div class="col-md-2" style="min-width: 100px;padding-right: 10px;">
                    <img src="assets/gallery/dms.png" class="img-fluid" alt="image" style="width:60px; height:60px;" />
                    <p>
                        Document <br />
                        Management
                    </p>
                </div>
                <div class="col-md-2" style="min-width: 100px;padding-right: 10px;">
                    <img src="assets/gallery/pdm.png" class="img-fluid" alt="image" style="width:60px; height:60px;" />
                    <p>
                        Product <br />
                        Management
                    </p>
                </div>
                <div class="col-md-2" style="min-width: 100px;">
                    <img src="assets/gallery/cms.png" class="img-fluid" alt="image" style="width:60px; height:60px;" />
                    <p>
                        Change<br />
                        Management
                    </p>
                </div>
                <div class="col-md-2" style="min-width: 100px;">
                    <img src="assets/gallery/its.png" class="img-fluid" alt="image" style="width:60px; height:60px;" />
                    <p>
                        Issue<br />
                        Tracking
                    </p>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>

        <div class="login-panel">
            <div class="login-form" style="margin-top: 100px; width: 100%">
                <img src="assets/gallery/logo.png" class="logo" alt="FactoryPRO-Logo"
                    style="width: 140px; height: 80px" />
                <h2 style="font-weight: bold;">The PLM system is temporarily unavailable. Thank you for your understanding.
                </h2>
                <h2 style="font-weight: bold;">Please contact support at support@solsemusa.com for any questions/concerns.</h2>
            </div>
        </div>
    </div>
</div>