<div class="p-fluid p-formgrid p-grid">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12 p-md-2">
          <a>
            <button pButton pRipple type="button" icon="pi pi-chevron-left" iconPos="left" label="Back"
              class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="width: 50%"
              (click)="BackClick()"></button></a>
        </div>

        <div class="p-col-12 p-md-8" style="text-align: center"> 
          <h4>{{HeaderText}}</h4>
        </div>
      </div>
      <hr />
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <label>First Name<span style="color: red">*</span></label>
              <input [(ngModel)]="firstNameuser" type="text" pInputText placeholder="Enter First Name" />
              <small class="p-invalid">{{ firstNamError }}</small>
              <br /><br />
              <label>Email<span style="color: red">*</span></label>
              <input [(ngModel)]="useremail" type="text" pInputText placeholder="Enter Email" [disabled]="EditMode" />
              <small class="p-invalid">{{ emailError }}</small>
              <br /><br />
              <label>Department<span style="color: red">*</span></label>
              <p-multiSelect [options]="departments" (onChange)="getRolesByDepartmentGuids()"
                [(ngModel)]="selectedDepartments" defaultLabel="Select a department" optionLabel="departmentName">
              </p-multiSelect>
              <small class="p-invalid">{{ departmentError }}</small>
              <br />
              <label>Supervisor<span style="color: red">*</span></label>
              <p-dropdown [options]="UsersData" [(ngModel)]="selectedUser" placeholder="Select a supervisor" optionLabel="fullName">
              </p-dropdown>
              <small class="p-invalid">{{ supervisorError }}</small>
              <br /> 
              <label>Role<span style="color: red">*</span></label>
              <p-multiSelect [options]="roles" [(ngModel)]="selectedRole" defaultLabel="Select a role"
                optionLabel="fullName"></p-multiSelect>
              <small class="p-invalid">{{ roleError }}</small>
              <br />
              <label>Application Group<span style="color: red">*</span></label>

              <p-multiSelect [options]="appGroupData" (onChange)="GetApplicationsByGroups('')"
                [(ngModel)]="selectedAppGroup" defaultLabel="Select a group" optionLabel="appGroupName">
              </p-multiSelect>
              <small class="p-invalid">{{ ApplicationGroupError }}</small>
              <br />
              <label>User Code<span style="color: red">*</span></label>
              <input [(ngModel)]="userCode" type="text" pInputText placeholder="New" readonly=true />
              <br/> <br/>
              <label>Title</label>
              <input [(ngModel)]="title" type="text" pInputText placeholder="Title" />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label>Last Name<span style="color: red">*</span></label>
              <input [(ngModel)]="lastName" type="text" pInputText placeholder="Enter Last Name" autocomplete="one-time-code"/>
              <small class="p-invalid">{{ lastNameError }}</small>
              <br /><br />
              <label>Password<span style="color: red">*</span></label>
            <div class="p-inputgroup">
              <input [(ngModel)]="Userpassword" type="password" pInputText placeholder="Enter Password" [disabled]="EditMode" autocomplete="one-time-code" [type]="show_button ? 'text' : 'password'"/>
              <span (click)="showPassword()" class="p-inputgroup-addon"><i style="font-size: 1rem;" [class]="show_eye ? 'pi pi-eye-slash' : 'pi pi-eye'"></i></span>        
              <small class="p-invalid">{{ passwordError }}</small> 
            </div>
              <br />
              <label>Primary Department<span style="color: red">*</span></label>
                <p-dropdown [options]="defaultDepData" [(ngModel)]="selectedDep" placeholder="Select a department" 
                optionLabel="departmentName">
              </p-dropdown>
              <small class="p-invalid">{{ primaryDeptError }}</small>
              <br>
              <label>Joining Date<span style="color: red">*</span></label>
              <p-calendar [showIcon]="true" [(ngModel)]="joiningDate" required="true" placeholder="Enter joining date"
                requiredMessage="The input must not be empty">
              </p-calendar>
              <small class="p-invalid">{{ JoiningDateError }}</small>
              <br /> 
              <label>Business Unit<span style="color: red">*</span></label>
              <p-multiSelect [options]="businessunits"
                [(ngModel)]="selectedbusinessunits" defaultLabel="Select a business unit" optionLabel="businessUnitName">
              </p-multiSelect>
              <small class="p-invalid">{{ businessunitError }}</small>
              <br />
                <label>Default Application<span style="color: red">*</span></label>
                <p-dropdown [options]="defaultapplicationData" [(ngModel)]="SelectedLandingApp" placeholder="Select a application"
                optionLabel="application">
                <small class="p-invalid">{{ defaultappError }}</small>
              </p-dropdown>
              <br>
                <label>Time Zone<span style="color: red">*</span></label>
                <p-dropdown inputId="timezones" [options]="timezone" placeholder="Select a Time zone" filter="false" [showClear]="true"
                    [(ngModel)]="selectedtimezone" optionLabel="displayName"></p-dropdown>
                <small class="p-invalid">{{ timezoneError }}</small>
                <br>
              <label>Site<span style="color: red">*</span></label>
              <p-dropdown inputId="site" [options]="site" placeholder="Select Site" 
                [(ngModel)]="selectedsite"  optionLabel="siteName">
              </p-dropdown>
              <small class="p-invalid">{{ siteError }}</small> 
              <br />
              <span class="p-field-radiobutton" style="margin-top: 25px;">
                <label style="margin-right:10px;">Active:</label>
                <p-radioButton value="Y" [(ngModel)]="ActivevalRadio" [disabled]="!EditMode" (click)="activechange()" ></p-radioButton>
                <label style="margin-right:10px;">Yes</label>
                <p-radioButton value="N" [(ngModel)]="ActivevalRadio" [disabled]="!EditMode" (click)="activechange()"></p-radioButton>
                <label>No</label>
              </span>
              <br>
              <span  *ngIf="activedisplay">
              <label>Termination Date<span style="color: red">*</span></label>
              <p-calendar [showIcon]="true" [(ngModel)]="termDate" required="true" placeholder="Enter termination date"
                requiredMessage="The input must not be empty">
              </p-calendar>
              <small class="p-invalid">{{ TerminationDateError }}</small></span>
              <br>
              <small class="p-invalid">{{ mainError }}</small>
            </div>

            <div class="p-field p-col-12">
              <div class="card-header" style="border-radius: 5px; ">
                <h6 style="color:#495057; padding:10px 0 0 15px;">Applications</h6>
              </div>
              <p-table #dt [value]="applicationData" sortMode="multiple" styleClass="p-datatable-striped"
                [filterDelay]="0" [globalFilterFields]="['applicationgroup', 'applications']">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="applicationgroup">
                      Application Group
                      <p-sortIcon field="applicationgroup"></p-sortIcon>
                    </th>
                    <th pSortableColumn="applications">
                      Application
                      <p-sortIcon field="applications"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-application let-i="rowIndex">
                  <tr>
                    <td style="text-align:left; padding-left:15px; ">{{ application.appGroupName }}
                    </td>
                    <td style="text-align:left; padding-left:15px; ">{{ application.application }}</td>
                   </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="2">No records found.</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="p-field p-col-12 p-md-10" ></div>
            <div class="p-field p-col-12 p-md-2" >
              <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="{{labeltext}}"
              class="p-button-raised p-button-success p-mr-2 p-mb-2"
              style="width:120px; float:right; margin-left:10px;margin-top: 10px;" (click)="ValidateUser()"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>

<span>
  <p-dialog header="Change Team Members Superivsors" [(visible)]="usersDisplay" modal="modal" showEffect="fade"
        [style]="{ width: '80%' }">
        <hr />
  <div class="p-field p-col-12">
    <br>
    <div class="invoice invoice-header"  id="invoice-content" style="overflow-x: auto;">
      <p-table #dt [value]="DependencyData" id="excel-table" [columns]="cols" sortMode="multiple" class="table-align"
        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id">
        <ng-template pTemplate="header" let-columns class="invoice-items">
          <tr>
            <th *ngFor="let col of columns;let i = index"
            [ngClass]="{'fn': i == 0 ,'ln': i == 1,
            'pd': i == 2,'ti': i == 3,'ro': i == 4}">
              {{col.header}}
            </th>
            <th>Supervisor</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
          <tr [pSelectableRow]="rowData">
            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
              <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">
                {{ rowData[col.field] }}
              </td>
              <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 3" style="text-align: left;padding-left:15px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 4" style="text-align: left;padding-left:15px;">
                {{rowData[col.field]}}
              </td>
            </ng-container>
            <td style="text-align: left;padding-left:15px;">
              <select class="select-css width100"
                    [(ngModel)]="rowData.selectedUser"
                    aria-placeholder="Select">
                    <option [value]="null" selected disabled hidden>Select</option>
                    <option *ngFor="let item of SupUsersData"
                        [value]="item.userId">
                        {{item.fullName}}
                    </option>
                </select>
            </td>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6" style="text-align: left;">No data found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="p-col-12 p-md-12 p-xl-12">
      <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Update"
      class="p-button-raised p-button-success p-mr-2 p-mb-2" (click)="UpdateUserSupervisor();"
      style="width:auto; float:right; "></button>
  </div>
    <small class="p-invalid">{{ userGridError }}</small>
  </div>
  </p-dialog>
</span>

<span>
  <p-dialog header="Information" [(visible)]="InActiveDisplay" modal="modal" showEffect="fade" [style]="{width:'50%'}">
    <hr>
    <div class="p-grid">
      <div class="p-col-12" style="text-align: center;">
        <span style="font-size:18px;">Team members are still active under selected user. Please change their supervisor to continue.</span>
      </div>
      <div class="p-col-12 p-md-12 p-xl-12">
        <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Yes"
        class="p-button-raised p-button-success p-mr-2 p-mb-2" (click)="CheckUsers();"
        style="width:auto; float:right; "></button>
      <button pButton pRipple type="button" icon="pi pi-times" (click)="InActiveDisplay = false;" iconPos="left" label="No"
      class="p-button-raised p-button-secondary p-mr-2 p-mb-2" style="width:auto; float:right; "></button>
    </div>
    </div>
  </p-dialog>
</span>

<span>
  <p-dialog header="Approval Needed Documents" [(visible)]="InActiveApprovalsDisplay" modal="modal" showEffect="fade" [style]="{width:'85%'}">
    <hr>
    <div class="p-grid">
      <div class="p-col-12" style="text-align: center;">
        <span style="font-size:18px;color: red;">The user cannot be made inactive if the user have pending document approvals. Please find the below documents.</span>
      </div>
      <div class="invoice invoice-header" id="invoice-content">
        <p-table #dt [value]="approvalsData" id="excel-table" [columns]="colsdocs" sortMode="multiple" class="table-align" [scrollable]="true" scrollHeight="200px"
          styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id"
          [globalFilterFields]="['code','title','documentType','revision','eco','owner','delegatedBy']">
          <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" 
              [ngClass]="{'code': i == 0 || i == 7,'title': i == 1,'departmentName': i == 2,'revision': i == 3,'eco': i == 4,
              'owner': i == 5, 'delegatedBy': i == 6}">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <!-- <th pSortableColumn="price" style="width:80px;">View</th> -->
            </tr>
            <tr id="trSearch">
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                (input)="test($event.target.value,col.field,dt)"
                  style="margin: -10px 0 -10px 0; width:95%; height:25px;">
              </th>
              <!-- <th></th> -->
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
            <tr [pSelectableRow]="rowData">
              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 120px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width: 400px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 2" style="text-align: left;padding-left:10px;width: 190px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 3" style="text-align: left;padding-left:20px;width: 60px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 60px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 5" style="text-align: left;padding-left:15px;width: 140px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 6" style="text-align: left;padding-left:15px;width: 90px;">
                  {{rowData[col.field]}}
                </td>
              </ng-container>
              <!-- <td style="text-align: center;width: 80px;padding-left:10px;">
                <button pButton type="button" (click)="viewDocDetails(docsData.id, docsData.wfDefinitionId)"
                  icon="pi pi-eye" pTooltip="View" tooltipPosition="left" class="p-button-rasised p-button-seconday"
                  style="height:25px; width:25px;"></button>
              </td> -->
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7" style="text-align: left;">No documents found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <!-- <div class="p-col-12 p-md-12 p-xl-12">
        <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="OK"
        class="p-button-raised p-button-success p-mr-2 p-mb-2" (click)="InActiveApprovalsDisplay=false;"
        style="width:auto; float:right; "></button>
    </div> -->
    </div>
  </p-dialog>
</span>