import { Component, OnInit } from '@angular/core';
import { FacadeService } from "../../facade/facade.service";
import { Router } from '@angular/router';
import { NgModule }      from '@angular/core';
import { FormGroup, FormControl, FormBuilder,Validators } from '@angular/forms';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
  email: any;
  forGotPassword: any;
  emailError: any;
  responseError: any;
 successMessage:boolean;
 showLoading:boolean;
  constructor( private _facadeService: FacadeService,private router: Router) { }

  ngOnInit(): void {
    document.querySelector('input').focus();
    sessionStorage.clear();
  }

  get primEmail(){
    return this.userEmails.get('primaryEmail');
    }

    userEmails = new FormGroup({
    primaryEmail: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    secondaryEmail: new FormControl('',[
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
    });  
    onEnter(e) {
      var code = e.keyCode || e.which;
      if (code === 13) {
          e.preventDefault();
          this.forgotPasswordClick();
      };
    }
    forgotPasswordClick() {
      this.successMessage= false;
      this.emailError = '';
      if (this.email == undefined || this.email == '') {
        this.emailError = 'Please enter email.';
      }
      else{
        var emailCheck = this.userEmails.get('primaryEmail');
        if(emailCheck.status=="VALID"){
          this.showLoading=true;
        this._facadeService.forGotPassword(this.email).subscribe(
          (data) => {
            this.showLoading=false;
            if(data!=null && data!=""&&data!=undefined&& data.returnCode==0){
              this.forGotPassword = data.returnObject;
              console.log("forGotPassword data ::" + JSON.stringify(this.forGotPassword));         
              this.successMessage = true;
              this.emailError = '';
            }
            else{
              this.emailError = data.returnMessage;
            }
          },
          (error) => {
            this.showLoading=false;
            console.log(error);
          }
        );
}
else{
  this.emailError = 'Please provide a valid email address.';
}
      }
  }
}


