<div class="layout-topbar" style="background-color: #d5e8ef; height: 90px">
  <div class="topbar-left">
    <a tabindex="0" class="menu-button" (click)="app.onMenuButtonClick($event)">
      <i class="pi pi-chevron-left"></i>
    </a>
    <span class="topbar-separator"></span>
    <img id="app-logo" class="logo-image" src="assets/gallery/ums.png" alt="Product Management" title="user Management"
    style="height:40px; width:40px; margin-right:1rem;float: left;" />
    <span class="viewname" style="text-transform: uppercase; font-size:14px;color: #495057;float: left;" *ngIf="HeaderText">
      
      <b>User Management</b></span>
      <!-- <img id="logo-mobile" class="mobile-logo" src="assets/layout/images/logo-dark.svg" alt="diamond-layout" /> -->
    </div>
  
    <div class="topbar-left">
      <a class="logo">
        <img id="app-logo" class="logo-image" src="assets/gallery/Client-logo.png" alt="FactoryPRO-Logo"
          style="width: 80px; height: 59px; margin-left: -0.5rem" />
      </a>
    </div>
    <div class="topbar-right">
      <ul class="topbar-menu">
        <li class="profile-item" [ngClass]="{ 'active-menuitem fadeInDown': app.topbarUserMenuActive }">
          <button pButton pRipple type="button" icon="pi pi-question" pTooltip="Search Help"tooltipPosition="top" 
          class="p-button-rounded p-button-info p-mr-2 p-mb-2" style="float:left;height:25px; width:25px;margin-top: 5px;" (click)="help()"></button>
         <a href="#" (click)="app.onTopbarUserMenuButtonClick($event)">
            <img *ngIf="!profileDisplayExist" src="assets/demo/images/avatar/Defaultprofile.jpg"
            alt="diamond-layout" style="object-fit: cover;" class="profile-image" style="margin-left: 10px;"/>
            <img *ngIf="profileDisplayExist" src="{{profileDisplayPic}}"
            style="object-fit: cover;" class="user-card-avatar" alt="diamond-layout" class="profile-image"/>
            <span class="profile-name">{{ firstName }}</span>
          </a>
          <ul class="profile-menu fade-in-up">
            <li>
              <a (click)="ProfileClick()">
                <i class="pi pi-user"></i>
                <span>Profile</span>
              </a>
            </li>
            <li>
              <a (click)="LogoutClick()">
                <i class="pi pi-power-off"></i>
                <span>Logout</span>
              </a>
            </li>
          </ul>
        </li>
  
        <li class="notifications-item" [ngClass]="{ 'active-menuitem ': app.topbarNotificationMenuActive }">
          <a href="#" tabindex="0" (click)="app.onTopbarNotificationMenuButtonClick($event)">
            <!-- <i class="pi pi-th-large" style="background-color: #eee; height:40px; width:37px;"></i> -->
            <img id="grid-icon" src="assets/gallery/grid.png" alt="grid-icon" style=" height:30px; width:30px;padding:5px;"/>
          </a>
          <ul class="notifications-menu fade-in-up">
            <li role="menuitem">
              <div class="p-col-12">
                <!-- <div class="p-grid">
                  <a (click)="TopMenuClicks(item.identifier)" *ngFor="let item of TopMenuItemsList">
                    <div class="p-col-12 p-md-6 p-lg-6" style="text-align:center;" >
                      <!-- <div class="p-col-12 p-md-6 p-lg-6" style="text-align:center;" (click)="TopMenuDisplay=true"> -->
                     <!-- <div style="width:80px; height:80px;">
                        <img [src]="item.iconPath" alt="diamond-layout" style="width:60px; height:60px;" />
                        <br> <span style="text-align:center;">{{item.displayText}}</span>
                      </div>
                    </div>
                  </a>
                </div> -->
                <div class="p-grid" style="width:488px;" *ngIf="TopMenuItemsList.length>9">
                  <a (click)="TopMenuClicks(item.identifier,item.id)" *ngFor="let item of TopMenuItemsList">
                    <div class="p-col-12" style="text-align:center;" >
                      <div style="width:80px; height:80px;">
                        <img [src]="item.iconPath" alt="diamond-layout" style="width:60px; height:60px;" />
                        <br> <span style="text-align:center;">{{item.displayText}}</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="p-grid" style="width:380px;" *ngIf="TopMenuItemsList.length>6&&TopMenuItemsList.length<=9">
                  <a (click)="TopMenuClicks(item.identifier,item.id)" *ngFor="let item of TopMenuItemsList">
                    <div class="p-col-12" style="text-align:center;" >
                      <div style="width:80px; height:80px;">
                        <img [src]="item.iconPath" alt="diamond-layout" style="width:60px; height:60px;" />
                        <br> <span style="text-align:center;">{{item.displayText}}</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="p-grid" *ngIf="TopMenuItemsList.length<=6">
                  <a (click)="TopMenuClicks(item.identifier,item.id)" *ngFor="let item of TopMenuItemsList">
                    <div class="p-col-12 p-md-6 p-lg-6" style="text-align:center;" >
                      <div style="width:80px; height:80px;">
                        <img [src]="item.iconPath" alt="diamond-layout" style="width:60px; height:60px;" />
                        <br> <span style="text-align:center;">{{item.displayText}}</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  
  <p-dialog header="Header" [(visible)]="TopMenuDisplay" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <p>You don't have permission</p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="TopMenuDisplay=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>