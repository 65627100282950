import { Component, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from '../../services/printcommon.service';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-rolefeatures',
  templateUrl: './rolefeatures.component.html',
  styleUrls: ['./rolefeatures.component.scss']
})
export class RolefeaturesComponent implements OnInit {
  selectedRole=[];
  rolesData=[];
  Count=0;
  cols: any[];
  showLoading:boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  addDisplay: boolean;
  featuresData:any=[];
  selectedfeature:any;
  selectedfeatureError:any;
  filteredfeature:any;
  code:any;
  roleError:any;
  departmentsData:any;
  roleFeatureId=0;
  active:boolean;
  responseMessage:any;
  Type:any="Add";
  FromEdit:boolean=false;
  savelabel:any="Save";
  roleGuid:any="";
  departmentGuid:any="";
  featureHeader:any="Add Role Feature";
  Savelabel="Save";
  UserID = sessionStorage.getItem("userGuid");
  roleAccess:boolean;
  roleData:any;
  Description="";
  featureId=0;
  features=[];
  constructor(private _facadeService: FacadeService,private route: ActivatedRoute,public printService: PrintcommonService) { }
    ngOnInit(): void {
      sessionStorage.featureName = "";
      sessionStorage.roleName = "";
      sessionStorage.active ="";
      this.cols = [
        { field: 'featureName', header: 'Feature', dynamicPlaceHolder: 'Search' }, 
        { field: 'roleName', header: 'Role', dynamicPlaceHolder: 'Search' },
        { field: 'active', header: 'Active', dynamicPlaceHolder: 'Search' }
      ];
      this.AllRolesFeatures();
     
    }
    AddNewButton() {
      this.Type="Add";
      this.selectedfeatureError="";
      this.roleError="";
      this.responseMessage="";
      this.selectedfeature="";
      this.code="";
      this.active=true;
      this.roleAccess=false;
      this.FromEdit=false;
      this.addDisplay = true; 
      this.savelabel="Save";
      this.featureHeader="Add Role Feature";
      this.Savelabel="Save";
      this.Description="";
      this.roleFeatureId=0;
    }
    ViewRole(featureId){
      this.showLoading=true;
      this.Description="";
      this.selectedfeatureError="";
      this.roleError="";
      this.responseMessage="";
      this.selectedfeature="";
      this.featureHeader="Update Role Feature";
      this.Savelabel="Update";
      this.roleFeatureId=0;
      this._facadeService.GetRolesByFeatureId(featureId).subscribe(
        (data) => {
          this.addDisplay = true; 
          this.showLoading=false;
            this.active=data.returnObject[0].active;
            this.selectedfeature=this.features.find(m=>m.featureId==data.returnObject[0].featureId);
            var roledataa=[];
            data.returnObject.forEach(element => {
              this.rolesData.forEach(element1 => {
                if(element1.roleId==element.roleId){
                  roledataa.push(element1);
                }
              });
            });
            this.selectedRole=roledataa;
            this.roleFeatureId=data.returnObject[0].rfid;
            this.Type="Edit";
            this.FromEdit=true;
            this.savelabel="Update";
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }

    AllRolesFeatures(){
      this.showLoading=true;
      this._facadeService.AllRolesFeatures().subscribe(
        (data) => {
          this.GetFeatures();
         
        this.featuresData = data.returnObject;
        if(this.featuresData != null && this.featuresData != undefined&&this.featuresData.length > 0)
        {
          this.featuresData.forEach(element => {
            element.active=element.active==true?"Y":"N";
          });
        } 
        
        this.Count=this.featuresData.length;
        this.showLoading=false;
        console.log("Features data ::" + JSON.stringify(this.featuresData));
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }
    
    CreateFeature(){
      this.selectedfeatureError="";
      this.roleError="";
      this.responseMessage="";
      if(this.selectedfeature==""||this.selectedfeature==null||this.selectedfeature==undefined){
          this.selectedfeatureError="Please enter feature.";
      }
      else if(this.selectedRole==null||this.selectedRole.length==-0){
        this.roleError="Please select atleast one role.";
    }
    else{
      this.showLoading=true;
      var model =[];
      debugger
      if(this.selectedRole!=null&&this.selectedRole.length>0){
        this.selectedRole.forEach(element => {
          var feature={
            roleId:element.roleId,
            Active:this.active,
            featureId:this.selectedfeature.featureId,
            rfid:this.roleFeatureId
          };
          model.push(feature);
        });
       
      }
      
      if(this.roleFeatureId>0){
        this._facadeService.UpdateRoleFeatures(model).subscribe(
          (data) => {
            this.showLoading=false;
            if(data.returnCode==0){
              this.addDisplay=false;
              this.AllRolesFeatures();
            }
            else{
             this.responseMessage=data.returnMessage;
            }
        },
        (error) => { 
          this.showLoading=false;
          console.log(error);
        }
      );
      }
      else{
        this._facadeService.InsertRoleFeatures(model).subscribe(
          (data) => {
            this.showLoading=false;
            if(data.returnCode==0){
              this.addDisplay=false;
              this.AllRolesFeatures();
            }
            else{
             this.responseMessage=data.returnMessage;
            }
        },
        (error) => { 
          this.showLoading=false;
          console.log(error);
        }
      );
      }
    }
    }
    delete_row (ws, row_index){
      let range = XLSX.utils.decode_range(ws["!ref"])
      for(var R = row_index; R < range.e.r; ++R){
          for(var C = range.s.c; C <= range.e.c; ++C){
              ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
              if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
                ws[this.ec(R+1, C)].v  = "";
          }
      }
      range.e.r--
      delete (ws['E1'])
      ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
    }
    ec(r, c) {
      return XLSX.utils.encode_cell({r:r,c:c})
    }
    export(){
      /* table id is passed over here */   
     let element = document.getElementById('excel-roles'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     ws['!cols'] = [{ wpx : 190 },{ wpx : 180 },{ wpx : 50 },{ wpx : 50 }];
     /* save to file */
     XLSX.writeFile(wb, "Features.xlsx");
    }
    print() {
      var Content = document.getElementById('invoice-content').innerHTML;
      this.printService.Print(Content,'Features','trSearch',true);
  }
  test(val,field,dt)
  {
    if(field == "active")
      sessionStorage.active = val;
    else if(field == "roleName")
      sessionStorage.roleName = val;
      else if(field == "featureName")
      sessionStorage.featureName = val;
    dt = dt.filter(val,field, 'contains');
    this.CountData();
  }
  CountData(){
    if(this.featuresData != undefined && this.featuresData != null)
    {
      var temp = this.featuresData;
      if(sessionStorage.active != ""&&sessionStorage.active != null)
        temp = temp.filter(x=> x.active!=null&&x.active!=""&&x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
      if(sessionStorage.roleName != "")
        temp = temp.filter(x=> x.roleName!=null&&x.roleName!=""&&x.roleName.toLowerCase().includes(sessionStorage.roleName.toLowerCase()));
       
        if(sessionStorage.featureName != "")
        temp = temp.filter(x=> x.featureName!=null&&x.featureName!=""&&x.featureName.toLowerCase().includes(sessionStorage.featureName.toLowerCase()));
       this.Count = temp.length;
    }
    else
      this.Count = 0;
  }

  filterFeatures(event) {
    debugger
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.features.length; i++) {
        let dept = this.features[i];
        if (dept.featureName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(dept);
        }
    }    
    this.filteredfeature = filtered;
}
GetFeatures(){
  var model ={
    "featureId": 0,
    "applicationId": 0,
    "active": true,
    "bindRoles": true
  }
  this.showLoading=true;
  this._facadeService.GetFeatures(model).subscribe(
    (data) => {
      this.GetRoles();
    this.features = data.returnObject;
    if(this.features!=null&&this.features.length>0){
      this.features=this.features.filter(m=>m.active==true);
    }
    this.showLoading=false;
  },
  (error) => { 
    this.showLoading=false;
    console.log(error);
  }
);
}
GetRoles(){
  this.showLoading=true;
  this._facadeService.GetAllRoles().subscribe(
    (data) => {
    this.rolesData = data.returnObject;
  },
  (error) => { 
    this.showLoading=false;
    console.log(error);
  }
);
}
  }
