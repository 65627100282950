<div class="card" style="min-height:600px;">  
    <div class="card-header" style="margin-bottom:10px; border-radius: 5px; width:99.8%;height:40px;">       
        <h5 style="color:#495057; padding:15px 0 0 15px;">Bulk Import of Departments</h5> 
        <span class="p-input-icon-left" style="float:right;margin-left:10px; margin-right:5px;">        
        <label style="padding-right:10px;">Select file to upload:</label>
        <input id="Title" type="text" pInputText  placeholder="file name" style="text-align: left;padding-left: 5px;opacity: 0.7;"/>
        <button pButton pRipple type="button" pButton type="button" icon="pi pi-upload"
        label="Upload" iconPos="left" class="p-button-raised p-button-info " style="margin-top:15px;margin-left: 10px;"></button>   
        <a href="" target="blank"><span style="font-size: 18px;margin-left: 10px; margin-right: 10px">
            <u>Download Template </u></span></a>            
        </span>
    </div>
    
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
            <h5 style="color:#495057; padding:15px 0 0 15px;">Review Departments</h5>
            <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                    class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                    style="float:right;height:25px; width:25px;"></button>
                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
                    class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                    style="float:right; height:25px; width:25px;"></button>
            </span>
        </div>
        <div class="invoice invoice-header" id="invoice-content">
            <p-table #dt [value]="issuesData" [columns]="cols" sortMode="multiple" class="table-align"
                styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id"
                [globalFilterFields]="['departmentname','departmentcode', 'description']">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                        <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th style="width:100px;">Actions</th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                            <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                (input)="dt.filter($event.target.value, col.field, 'contains')"
                                style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                            <td *ngIf="i == 0" style="text-align: left;padding-left:15px;">
                                {{ rowData[col.field] }}
                            </td>
                            <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                                {{rowData[col.field]}}
                            </td>
                            <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">
                                {{rowData[col.field]}}
                            </td>
                        </ng-container>
                        <td style="text-align: center;">
                            <a> <button pButton type="button" icon="pi pi-trash" pTooltip="view" tooltipPosition="left"
                                    class="p-button-raised p-button-primary"
                                    style="width:25px; height:25px;text-align:center;"></button>
                            </a>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4" style="text-align: left;">No records found.</td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="p-col-12" style="float:right;">
                <hr>
                <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save"
                  class="p-button-raised p-button-success p-mr-2 p-mb-2"
                  style="width:120px; float:right; margin-left:10px;"></button>
    
              </div>
        </div>
        
</div>

<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>