<div class="p-fluid p-formgrid p-grid">
  <div class="p-col-12">
    <div class="card" style="min-height:1000px;">
      <div class="relative overflow-hidden h-20rem bg-primary flex flex-column align-items-center justify-content-center border-round mb-6">
        <div class="font-bold mb-5 text-4xl z-1">Search Help</div>
        <div class="p-input-icon-left w-9 md:w-6" style="display: flex;float:right">
          <input type="text" pinputtext="" placeholder="Search for help" class="p-inputtext p-component p-element w-full" (keydown)="onKeyDown($event)" [(ngModel)]="searchtext" style="border-radius: 2rem;">
          <i class="pi pi-search" (click)="filterhelp()" style="position: inherit;right: 40px;"></i>
          <div class="p-col-12 p-md-2" style="width: auto;padding-top: 5px;">
            <button pButton type="button" label="Cancel" (click)="clearSearch()" style="font-size: large;font-weight: bold;"
                class="p-button-raised p-button-primary p-mr-2 p-mb-2" ></button></div>
        </div>
        <br>
        <small class="p-invalid" style="color: #fff;font-size: large;">{{ searchError }}</small> 
      </div>
      <div class="card" *ngIf="ShowHelp" style="padding-top: 25px;">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-col-12 p-md-2">
            <p-tabView styleClass="orientation-right" (onChange)="GetHelp($event)">
              <p-tabPanel header="{{item.helpTitle}}" *ngFor="let item of HelpData"> </p-tabPanel>
            </p-tabView>
            <h5>See Also</h5>
            <p-listbox optionLabel="helpTitle"  styleClass="listbox1" [(ngModel)]="selectedRelated" [style]="{height:'254px'}" [multiple]="false" [options]="HelpDataR" 
            (onClick)="GetHelp1()" [filter]="false"></p-listbox>
          </div>
          <div class="p-col-12 p-md-10"><div [innerHTML]="DisplayContent"></div> <br /> </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 5000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>
<div class="sticky" *ngIf="isFeedback">
  <div class="p-col-12 p-md-12" style="text-align: center;"  *ngIf="!DisplayYes && !DisplayNo && !DisplayNoThanks">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12 p-md-6" style="font-weight: bold; text-align: right;padding-top: 5px;">Was this information helpful?</div>
      <div class="p-col-12 p-md-2" style="width: auto;">
        <button pButton type="button" label="Yes" icon="pi pi-check" iconPos="left" (click)="DisplayYes = true"
          class="p-button-raised p-button-primary p-mr-2 p-mb-2"></button>
      </div>
      <div class="p-col-12 p-md-4" style="width: auto;">
        <button pButton type="button" label="No" icon="pi pi-times" iconPos="left" (click)="BindFeedback()"
          class="p-button-raised p-button-primary p-mr-2 p-mb-2"></button>
      </div>
    </div>
  </div>
  <div class="p-col-12 p-md-12" *ngIf="DisplayYes"><span style="color: green;font-weight: bold;" >Thank you for your feedback!</span></div>
  <div class="p-col-12 p-md-12" *ngIf="DisplayNo">
    <div class="p-fluid p-formgrid p-grid" style="text-align: center;">
      <div class="p-col-12 p-md-2"></div>
      <div class="p-col-12 p-md-8" style="text-align: left;">
        <p-card>
          <div [innerHTML]="htmlStr"></div>
          <h5>What affected your experience?</h5>
          <p-listbox optionLabel="question" [(ngModel)]="selectedFeedback" [style]="{'width':'100%','height':'280px'}"  [multiple]="true"
          [checkbox]="true" [filter]="true" [options]="FeedbackData" [listStyle]="{'max-height': '250px'}"></p-listbox>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12 p-md-12 p-xl-12" style="padding-top: 20px;" >
              <h5>Any additional feedback? (Optional)</h5>
              <small class="p-invalid" style="float: right;">{{1000 - Description.length}} characters are remaining</small>
              <textarea rows="5" pInputTextarea id="Description" [(ngModel)]="Description" placeholder="Feedback"
                  maxlength="1000" ng-trim="false" style="width: 100%;" ></textarea>
              <br>
              <small class="p-invalid"><b>{{ RemarkError }}</b></small>
              <span style="float: right;padding-top: 10px;">
                <button pButton type="button" label="Cancel" icon="pi pi-times" class="p-button-raised p-button-danger p-mr-2 p-mb-2"
                (click)="DisplayNo=false" style="width: 100px;height: 25px;float: right;"></button>&nbsp;&nbsp;
                  <button pButton type="button" (click)="Add()" label="Submit Feedback" icon="pi pi-check" class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2" style="width: 170px;height: 25px;float: right;"></button>
              </span>
            </div>
          </div>
        </p-card>
      </div>
      <div class="p-col-12 p-md-2"></div>
    </div>
  </div>
  <div class="p-col-12 p-md-12" *ngIf="DisplayNoThanks"><span style="color: green;font-weight: bold;" >Thank you for your feedback!</span></div>
</div>