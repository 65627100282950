import { Component, Inject, OnInit } from "@angular/core";
import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { FacadeService } from "../../facade/facade.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute,
    private _facadeService: FacadeService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }
  show_button: Boolean = false;
  show_eye: Boolean = false;
  loginModel: any;
  userName: any;
  password: any;
  loginMessage: any;
  showLoading: boolean;
  days: any;
  module: any;
  page: any;
  docId: any;
  status: any;
  prodcutid: any;
  phaseid: any;
  taskid: any;
  issueId: any;
  No: boolean = false;
  ECRId: any;
  action: any = "";
  requestID: any;
  RMAId: any;
  releasedate: any;
  ngOnInit(): void {
    var maintenance = this.baseUrl["API_ENDPOINTS"]["Maintenance"];
    if (maintenance) {
      location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"] + "maintenance";
    }
    else {
      location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
    }
    document.querySelector('input').focus();
    sessionStorage.profileDisplayExist = false;
    sessionStorage.clear();
    var day = this.route.snapshot.queryParamMap.get("days");
    this.module = this.route.snapshot.queryParamMap.get("module");
    this.page = this.route.snapshot.queryParamMap.get("page");
    this.docId = this.route.snapshot.queryParamMap.get("documentid");
    this.status = this.route.snapshot.queryParamMap.get("status");
    this.prodcutid = this.route.snapshot.queryParamMap.get("prodcutid");
    this.phaseid = this.route.snapshot.queryParamMap.get("phaseid");
    this.taskid = this.route.snapshot.queryParamMap.get("taskid");
    this.issueId = this.route.snapshot.queryParamMap.get("issueId");
    this.ECRId = this.route.snapshot.queryParamMap.get('ecrid');
    this.action = this.route.snapshot.queryParamMap.get('action');
    this.requestID = this.route.snapshot.queryParamMap.get('requestID');
    this.RMAId = this.route.snapshot.queryParamMap.get('id');
    this.releasedate = this.route.snapshot.queryParamMap.get('releasedate');
    if (day != null && day != "" && day != undefined) {
      this.days = parseInt(day);
    }
  }
  onEnter(e) {
    var code = e.keyCode || e.which;
    if (code === 13) {
      e.preventDefault();
      this.login();
    };
  }

  getUserDetails(userguid, accessToken, bearer_token) {
    this.showLoading = true;
    this._facadeService.GetUserDetails(userguid).subscribe(
      (data) => {
        var appliId = 0;
        this.showLoading = false;
        let IsDCC = false;
        if (data.returnCode == 0) {
          if (data.returnObject.userApplications != null && data.returnObject.userApplications.length > 0) {
            var applicationId = data.returnObject.userApplications.find(m => m.name == data.returnObject.defaultApplication);
            if (applicationId != null) {
              appliId = applicationId.applicationId;
            }
          }
          if (data.returnObject.userRoles != null && data.returnObject.userRoles != "" && data.returnObject.userRoles != undefined && data.returnObject.userRoles.length > 0) {
            var dcc = data.returnObject.userRoles.find(m => m.name == "DCC Admin");
            if (dcc != null)
              IsDCC = true;
          }
          var applications = data.returnObject.defaultApplication;
          if (applications == null)
            this.loginMessage = "You don't have permission to login.";
          else {
            if (this.module != null && this.module != "" && this.module != undefined && this.module.toUpperCase() == "DMS") {
              var applicationId = data.returnObject.userApplications.find(m => m.projectIdentifier == "DMSUI");
              if (applicationId != null) {
                appliId = applicationId.applicationId;
              }
              location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"] + 'entrypoint?userguid=' + data.returnObject.userGuid + '&userId=' + data.returnObject.userId + '&IsDCC=' + IsDCC + "&destination=" + this.page + '&access_token=' + accessToken + "&docId=" + this.docId + "&status=" + this.status + "&action=" + this.action + '&bt=' + bearer_token + "&appliId=" + appliId + "&clientId=" + data.returnObject.clientId;
            }

            else if (this.module != null && this.module != "" && this.module != undefined && this.module.toUpperCase() == "CMS") {
              var id = this.route.snapshot.queryParamMap.get("id");
              var source = this.route.snapshot.queryParamMap.get("source");
              var CCBApproved = this.route.snapshot.queryParamMap.get("CCBApproved");
              var ProjectID = this.route.snapshot.queryParamMap.get("ProjectID");
              var applicationId = data.returnObject.userApplications.find(m => m.projectIdentifier == "CMSUI");
              if (applicationId != null) {
                appliId = applicationId.applicationId;
              }
              location.href = this.baseUrl["API_ENDPOINTS"]["CMSUI"] + 'entrypoint?userguid=' + data.returnObject.userGuid + '&userId=' + data.returnObject.userId + '&ProjectID=' + ProjectID + '&IsDCC=' + IsDCC + "&destination=" + this.page + '&access_token=' + accessToken + "&id=" + id + "&source=" + source + "&CCBApproved=" + CCBApproved + '&bt=' + bearer_token + "&appliId=" + appliId + "&clientId=" + data.returnObject.clientId;
            }
            else if (this.module != null && this.module != "" && this.module != undefined && this.module.toUpperCase() == "PRT") {
              var applicationId = data.returnObject.userApplications.find(m => m.projectIdentifier == "PRTUI");
              if (applicationId != null) {
                appliId = applicationId.applicationId;
              }
              location.href = this.baseUrl["API_ENDPOINTS"]["PRTUI"] + 'entrypoint?userguid=' + data.returnObject.userGuid + '&userId=' + data.returnObject.userId + '&IsDCC=' + IsDCC + "&destination=" + this.page + '&access_token=' + accessToken + '&requestID=' + this.requestID + '&bt=' + bearer_token + "&appliId=" + appliId + "&clientId=" + data.returnObject.clientId
                + '&releasedate=' + this.releasedate;
            }
            else if (this.module != null && this.module != "" && this.module != undefined && this.module.toUpperCase() == "PDM") {
              var applicationId = data.returnObject.userApplications.find(m => m.projectIdentifier == "ProductUI");
              if (applicationId != null) {
                appliId = applicationId.applicationId;
              }
              location.href = this.baseUrl["API_ENDPOINTS"]["ProductUI"] + 'entrypoint?userguid=' + data.returnObject.userGuid + '&userId=' + data.returnObject.userId + '&IsDCC=' + IsDCC + "&destination=" + this.page + '&access_token=' + accessToken + '&ProductID=' + this.prodcutid + '&PhaseID=' + this.phaseid + '&bt=' + bearer_token + "&appliId=" + appliId + "&clientId=" + data.returnObject.clientId;
            }
            else if (this.module != null && this.module != "" && this.module != undefined && this.module.toUpperCase() == "PM") {
              var applicationId = data.returnObject.userApplications.find(m => m.projectIdentifier == "ProjectUI");
              if (applicationId != null) {
                appliId = applicationId.applicationId;
              }
              location.href = this.baseUrl["API_ENDPOINTS"]["ProjectUI"] + 'entrypoint?userguid=' + data.returnObject.userGuid + '&userId=' + data.returnObject.userId + '&IsDCC=' + IsDCC + "&destination=" + this.page + '&access_token=' + accessToken + '&taskid=' + this.taskid + '&bt=' + bearer_token + "&appliId=" + appliId + "&clientId=" + data.returnObject.clientId;
            }
            else if (this.module != null && this.module != "" && this.module != undefined && this.module.toUpperCase() == "ITM") {
              var applicationId = data.returnObject.userApplications.find(m => m.projectIdentifier == "ITUI");
              if (applicationId != null) {
                appliId = applicationId.applicationId;
              }
              location.href = this.baseUrl["API_ENDPOINTS"]["ITUI"] + 'entrypoint?userguid=' + data.returnObject.userGuid + '&userId=' + data.returnObject.userId + '&IsDCC=' + IsDCC + "&destination=" + this.page + '&access_token=' + accessToken + '&issueId=' + this.issueId + '&bt=' + bearer_token + "&appliId=" + appliId + "&clientId=" + data.returnObject.clientId;
            }
            else if (this.module != null && this.module != "" && this.module != undefined && this.module.toUpperCase() == "PCN") {
              var applicationId = data.returnObject.userApplications.find(m => m.projectIdentifier == "PCNUI");
              if (applicationId != null) {
                appliId = applicationId.applicationId;
              }
              location.href = this.baseUrl["API_ENDPOINTS"]["PCNUI"] + 'entrypoint?userguid=' + data.returnObject.userGuid + '&userId=' + data.returnObject.userId + '&IsDCC=' + IsDCC + "&destination=" + this.page + '&access_token=' + accessToken + "&id=" + this.ECRId + '&bt=' + bearer_token + "&appliId=" + appliId + "&clientId=" + data.returnObject.clientId;
            }
            else if (this.module != null && this.module != "" && this.module != undefined && this.module.toUpperCase() == "RMA") {
              var applicationId = data.returnObject.userApplications.find(m => m.projectIdentifier == "RMAUI");
              if (applicationId != null) {
                appliId = applicationId.applicationId;
              }
              location.href = this.baseUrl["API_ENDPOINTS"]["RMAUI"] + 'entrypoint?userguid=' + data.returnObject.userGuid + '&userId=' + data.returnObject.userId + '&IsDCC=' + IsDCC + "&destination=" + this.page + '&access_token=' + accessToken + "&id=" + this.RMAId + '&bt=' + bearer_token + "&appliId=" + appliId + "&clientId=" + data.returnObject.clientId;
            }
            else if (this.module != null && this.module != "" && this.module != undefined && this.module.toUpperCase() == "NCMR") {
              var applicationId = data.returnObject.userApplications.find(m => m.projectIdentifier == "NCMRUI");
              if (applicationId != null) {
                appliId = applicationId.applicationId;
              }
              location.href = this.baseUrl["API_ENDPOINTS"]["NCMRUI"] + 'entrypoint?userguid=' + data.returnObject.userGuid + '&userId=' + data.returnObject.userId + '&IsDCC=' + IsDCC + "&destination=" + this.page + '&access_token=' + accessToken + "&id=" + this.RMAId + '&bt=' + bearer_token + "&appliId=" + appliId + "&clientId=" + data.returnObject.clientId;
            }
            else if (this.module != null && this.module != "" && this.module != undefined && this.module.toUpperCase() == "MRB") {
              var applicationId = data.returnObject.userApplications.find(m => m.projectIdentifier == "MRBUI");
              if (applicationId != null) {
                appliId = applicationId.applicationId;
              }
              location.href = this.baseUrl["API_ENDPOINTS"]["MRBUI"] + 'entrypoint?userguid=' + data.returnObject.userGuid + '&userId=' + data.returnObject.userId + '&IsDCC=' + IsDCC + "&destination=" + this.page + '&access_token=' + accessToken + "&id=" + this.RMAId + '&bt=' + bearer_token + "&appliId=" + appliId + "&clientId=" + data.returnObject.clientId;
            }
            else {
              if (this.days != null && this.days != "" && this.days != undefined) {
                location.href = this.baseUrl["API_ENDPOINTS"][data.returnObject.defaultIdentifierApp] + 'entrypoint?userguid=' + data.returnObject.userGuid + '&userId=' + data.returnObject.userId + '&IsDCC=' + IsDCC + "&days=" + this.days + '&access_token=' + accessToken + '&bt=' + bearer_token + "&appliId=" + appliId + "&clientId=" + data.returnObject.clientId + "&source=dashboard";
              }
              else {
                location.href = this.baseUrl["API_ENDPOINTS"][data.returnObject.defaultIdentifierApp] + 'entrypoint?userguid=' + data.returnObject.userGuid + '&userId=' + data.returnObject.userId + '&access_token=' + accessToken + '&IsDCC=' + IsDCC + '&bt=' + bearer_token + "&appliId=" + appliId + "&clientId=" + data.returnObject.clientId + "&source=dashboard";
              }
            }
          }
        }
        else
          this.loginMessage = "You don't have permission to login.";
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  login() {
    if (this.userName == undefined || this.userName == "") {
      this.loginMessage = "Please enter username.";
    } else if (this.password == undefined || this.password == "") {
      this.loginMessage = "Please enter password.";
    }
    else {
      this.loginModel = {
        Email: this.userName,
        Password: this.password,
      };
      console.log(JSON.stringify(this.loginModel));
      this.showLoading = true;
      this._facadeService.login(this.loginModel).subscribe(
        (data) => {
          this.showLoading = false;
          if (data.returnCode == 0) {
            sessionStorage.userId = data.returnObject.userId;
            sessionStorage.userGuid = data.returnObject.userGuid;
            sessionStorage.firstName = data.returnObject.firstName;
            sessionStorage.bt = data.returnObject.bearer_token;
            sessionStorage.un = data.returnObject.username;
            sessionStorage.pwd = data.returnObject.password;
            console.log(data.returnMessage);
            console.log(sessionStorage.userdata);
            this.getUserDetails(data.returnObject.userGuid, data.returnObject.accessToken, data.returnObject.bearer_token);
            console.log(data);
          }
          else {
            this.loginMessage = data.returnMessage;
            console.log(data.returnMessage);
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }

  forgotPasswordClick() {
    if (this.userName == undefined || this.userName == "")
      this.loginMessage = "Please enter username.";
    else {
      this.showLoading = true;
      this._facadeService.forGotPassword(this.userName).subscribe(
        (data) => {
          this.showLoading = false;
          if (data != null && data != "" && data != undefined) {
            if (data.returnCode == 0)
              this.No = true;
            else
              this.loginMessage = data.returnMessage;
          }
          else {
            this.loginMessage = "Not an authorized EmailID. Please contact Administrator";
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }
  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }
}
