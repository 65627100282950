import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem} from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from '../../services/printcommon.service';
@Component({
  selector: 'app-businessunit',
  templateUrl: './businessunit.component.html',
  styleUrls: ['./businessunit.component.scss']
})
export class BusinessunitComponent implements OnInit {
  Count=0;
  cols: any[];
    showLoading:boolean;
    @ViewChild("dt") table: Table;
    dynamicPlaceHolder: string;
    addDocsDisplay: boolean;
    unitData:any=[];
    businessUnitName:any;
    businessUnitNameError:any;
    active:boolean=true;
    responseMessage:any;
    Type:any="Add";
    FromEdit:boolean=false;
    savelabel:any="Save";
    businessUnitGuid:any="";
    UserID = sessionStorage.getItem("userGuid");
    ViewMode = this.baseUrl["API_ENDPOINTS"]["ApplicationGroupAccesss"];
    constructor(private _facadeService: FacadeService,public printService: PrintcommonService, @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }
  
    ngOnInit(): void {
      sessionStorage.businessUnitName = "";
      sessionStorage.active ="";
  this.cols = [
    { field: 'businessUnitName', header: 'Business Unit', dynamicPlaceHolder: 'Search' }, 
    { field: 'active', header: 'Active', dynamicPlaceHolder: 'Search' }
  ];
      this.GetBusinessUnit();
    }
    AddNewButton() {
      this.Type="Add";
      this.businessUnitNameError="";
      this.responseMessage="";
      this.businessUnitName="";
      this.active=true;
      this.FromEdit=false;
      this.addDocsDisplay = true; 
      this.savelabel="Save";
      this.businessUnitGuid="";
    }

    View(Guid){
      this.showLoading=true;
      this.businessUnitNameError="";
      this.responseMessage="";
      this.businessUnitName="";
      this._facadeService.ViewBusinessUnit(Guid).subscribe(
        (data) => {
          this.addDocsDisplay = true; 
          this.showLoading=false;
          if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
            this.active=data.returnObject.active;
            this.businessUnitName=data.returnObject.businessUnitName;
          }
            this.businessUnitGuid=data.returnObject.businessUnitGUID;
            this.Type="Edit";
            this.FromEdit=true;
            this.savelabel="Update";
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }

    IsActiveChange(){
      this.responseMessage= "";
      if(!this.active){
        this.showLoading=true;
        this._facadeService.CheckBusinessUnitInactiveById(this.businessUnitGuid).subscribe(
          (data) => {
            this.showLoading=false;
           if(data.returnObject==true){
             this.responseMessage= "This business unit is already associated with the User. You cannot inactive";
             this.active=true;
           }
        },
        (error) => { 
          this.showLoading=false;
          console.log(error);
        }
      );
      }
    }

    GetBusinessUnit(){
      this.showLoading=true;
      this._facadeService.GetAllBusinessUnit().subscribe(
        (data) => {
          this.showLoading=false;
        this.unitData = data.returnObject;
        this.unitData.forEach(element => {
          element.active=element.active==true?"Yes":"No";
        });
        this.Count=this.unitData.length;
        console.log("BusinessUnit data ::" + JSON.stringify(this.unitData));
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }
    
    Create(){
      this.businessUnitNameError="";
      this.responseMessage="";
      if(this.businessUnitName==""||this.businessUnitName==null||this.businessUnitName==undefined){
          this.businessUnitNameError="Please enter business unit name.";
      }
    else{
      this.showLoading=true;
      var department={
        BusinessUnitName:this.businessUnitName,
        Active:this.active,
        BusinessUnitGUID:this.businessUnitGuid
      };
      if(this.businessUnitGuid!=null && this.businessUnitGuid!=""&&this.businessUnitGuid!=undefined){
        this._facadeService.UpdateBusinessUnit(department,this.UserID).subscribe(
          (data) => {
            this.showLoading=false;
            if(data.returnCode==0){
              this.addDocsDisplay=false;
              this.GetBusinessUnit();
            }
            else{
             this.responseMessage=data.returnMessage;
            }
        },
        (error) => { 
          this.showLoading=false;
          console.log(error);
        }
      );
      }
      else{
        this._facadeService.CreateBusinessUnit(department,this.UserID).subscribe(
          (data) => {
            this.showLoading=false;
            if(data.returnCode==0){
              this.addDocsDisplay=false;
              this.GetBusinessUnit();
            }
            else{
             this.responseMessage=data.returnMessage;
            }
        },
        (error) => { 
          this.showLoading=false;
          console.log(error);
        }
      );
      }
    }
    }

   delete_row (ws, row_index){
     let range = XLSX.utils.decode_range(ws["!ref"])
     for(var R = row_index; R < range.e.r; ++R){
         for(var C = range.s.c; C <= range.e.c; ++C){
           ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
           if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
             ws[this.ec(R+1, C)].v  = "";
         }
     }
     range.e.r--
     delete (ws['C1'])
     ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
   }
    ec(r, c) {
      return XLSX.utils.encode_cell({r:r,c:c})
    }
    export(){
      /* table id is passed over here */   
     let element = document.getElementById('excel-BusinessUnit'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     ws['!cols'] = [{ wpx : 140 },{ wpx : 50 }];
     /* save to file */
     XLSX.writeFile(wb, "Business Units.xlsx");
    }
    print() {
      var Content = document.getElementById('invoice-content').innerHTML;
      this.printService.Print(Content,'Business Units','trSearch',true);
  }
  test(val,field,dt)
  {
    if(field == "active")
      sessionStorage.active = val;
    else if(field == "businessUnitName")
      sessionStorage.businessUnitName = val;
    dt = dt.filter(val,field, 'contains');
    this.CountData();
  }
  CountData(){
    if(this.unitData != undefined && this.unitData != null)
    {
      var temp = this.unitData;
      if(sessionStorage.active != ""&&sessionStorage.active != null)
        temp = temp.filter(x=> x.active!=null&&x.active!=""&&x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
      if(sessionStorage.businessUnitName != "")
        temp = temp.filter(x=> x.businessUnitName!=null&&x.businessUnitName!=""&&x.businessUnitName.toLowerCase().includes(sessionStorage.businessUnitName.toLowerCase()));
      
       this.Count = temp.length;
    }
    else
      this.Count = 0;
  }
  }
