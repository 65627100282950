<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="p-grid p-fluid p-formgrid" >
                <div class="p-field p-col-12 p-lg-10"></div>
                <div class="p-field p-col-12 p-lg-2" >
            <!-- <span class="p-input-icon-left" style="float:right; margin-top:15px; margin-bottom:5px;"> -->
                <a>
                    <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add"
                        class="p-button-raised p-button-primary" (click)="AddNewButton()"
                        style="width:auto;float: right;"></button>
                </a>
                <!-- <a>
            <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Import from Excel"
                class="p-button-raised p-button-primary" style="width:auto;margin-left:15px;margin-top: 25px;"></button>
        </a> -->
            <!-- </span> -->
        </div>
    </div>   

            <span class="p-input-icon-left" style="float: right">
                <p-dialog header="{{Type}} Department" [(visible)]="addDocsDisplay" modal="modal" showEffect="fade"
                    [style]="{ width: '50%' }">
                    <hr /><br>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-2"></div>
                        <div class="p-field p-col-12 p-md-8">
                            <label>Department<span style="color: red">*</span></label>
                            <input id="Department" [(ngModel)]="departmentName" type="text" pInputText
                                placeholder="Enter Department Name" />
                            <small class="p-invalid">{{ departmentNameError }}</small>
                            <br /><br />
                            <label>Code<span style="color: red">*</span></label>
                            <input id="Code" type="text" [disabled]="FromEdit" [(ngModel)]="departmentCode" pInputText
                                placeholder="Enter Department Code" maxlength="2" class="upper"  />
                            <small class="p-invalid">{{ departmentCodeError }}</small>
                            <br /><br />
                            <label style="margin-right: 20px;margin-bottom: -5px;">Active</label>
                            <p-checkbox binary="active" [disabled]="!FromEdit" [(ngModel)]="active"
                                (click)="IsActiveChange()"></p-checkbox>

                            <br />
                        </div>
                        <div class="p-field p-col-12 p-md-2"></div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-9"><small class="p-invalid" style="padding-left: 20px;">{{ responseMessage}}</small></div>
                        <div class="p-field p-col-12 p-md-2">
                            <br />
                            <button pButton pRipple type="button" icon="pi pi-check" label="{{savelabel}}"
                                (click)="CreateDepartment();" class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                style="float: right;width: auto;"></button>
                        </div>
                        <div class="p-field p-col-12 p-md-1"></div>
                    </div>
                </p-dialog>
            </span>

            <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
                <h5 style="color:#495057; padding:15px 0 0 15px;width: 80%;">Departments - {{Count}}</h5>
                <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                        class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                        style="float:right;height:25px; width:25px;" (click)="print()"></button>
                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download"
                        tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                        style="float:right; height:25px; width:25px;" (click)="export()"></button>
                    <p-checkbox name="groupname" (click)="GetDepartments()" label="Include Inactive" binary="false" inputId="binary" class="padding p-mb-2"
                        [(ngModel)]="AllProductsValue" ></p-checkbox>
                </span>
            </div>
            <div class="invoice invoice-header" id="invoice-content">
                <p-table #dt [value]="departmentsData" id="excel-Departments" [columns]="cols" sortMode="multiple"  [scrollable]="true" scrollHeight="580px"
                    class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                    dataKey="id" [globalFilterFields]="['departmentName','departmentCode', 'active']">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                            <th style="width:80px;">Actions</th>
                        </tr>
                        <tr id="trSearch">
                            <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                (input)="test($event.target.value,col.field,dt)"
                                    style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                                <td *ngIf="i == 0" style="text-align: left;padding-left:15px;">
                                    {{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 1" style="text-align: center;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 2" style="text-align: center;padding-left:35px;">
                                    {{rowData[col.field]}}
                                </td>
                            </ng-container>
                            <td style="text-align: center;width:80px;">
                                <a> <button pButton type="button" icon="pi pi-pencil" pTooltip="edit"
                                        tooltipPosition="left" class="p-button-raised p-button-primary"
                                        style="width:25px; height:25px;text-align:center;"
                                        (click)="ViewDepartment(rowData.departmentGuid);"></button>
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="4" style="text-align: left;">No records found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>