<div class="p-fluid p-formgrid p-grid">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
      <div class="p-fluid p-formgrid p-grid">
        <div class=" p-col-12 p-md-11" style="text-align:left;">
          <h4 style="margin-top:5px;text-align: center;">Manage Help Items </h4>
        </div>
      </div>
      <hr />
      <div class="card">
        <div class="card-header" style="border-radius: 5px; margin-top:-5px;height:40px;">
          <h5 style="padding:15px 0 0 0px;">{{ Type }} Help Item</h5> 
        </div>
        <hr />
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-4">
            <label>Application</label><span style="color: red">*</span>
            <p-dropdown [options]="applications" [(ngModel)]="selectedapplication" [disabled]="editmode"
              placeholder="Select Application" optionLabel="applicationName" (onChange)="getMenuList('',true)"></p-dropdown>
            <small class="p-invalid">{{applicationError}}</small>
          </div>
          <div class="p-field p-col-12 p-md-4">
              <label>Menu</label><span style="color: red">*</span>
              <p-dropdown [options]="menusData" [(ngModel)]="selectedmenu" optionLabel="menuName" placeholder="Select Menu"></p-dropdown>
              <small class="p-invalid">{{menuError}}</small>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <label>Help ID</label><span style="color: red">*</span>
            <input type="text" placeholder="Enter Help ID" pInputText [(ngModel)]="helpID" id="helpID" [min]="1" [max]="999999" maxlength="6" (keypress)="numberOnly($event)" />
            <small class="p-invalid">{{HelpIDError}}</small>
          </div>
          <div class="p-field p-col-12 p-md-1" style="padding-top: 28px;">
            <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" title="Check Availability" label=""
            class="p-button-raised p-button-primary p-mr-2 p-mb-2 width50" (click)="checkAvailability()"></button>
          </div>
          <div class="p-field p-col-12 p-md-8">
            <label>Title</label><span style="color: red">*</span>
            <small class="p-invalid" style="float: right;">{{100 - Title.length}} characters are remaining</small>
            <input type="text" placeholder="Enter Title" pInputText maxlength="100" id="Title" [(ngModel)]="Title" />
            <small class="p-invalid">{{TitleError}}</small>
            <br><br>

            <label>Key Words</label><span style="color: red">*</span>
            <small class="p-invalid" style="float: right;">{{500 - KeyWords.length}} characters are remaining</small>
            <textarea [(ngModel)]="KeyWords" rows="4" placeholder="Enter Key Words" id="KeyWords" maxlength="500" pInputTextarea 
            style="opacity: 1;overflow:auto;resize:none;"></textarea>
            <small class="p-invalid">Use comma / space for multiple keywords</small>
            <small class="p-invalid" style="padding-left: 50px;">{{KeyWordsError}}</small>
            <br><br>

            <p-checkbox [(ngModel)]="isActive" binary="isActive" [disabled]="!editmode"></p-checkbox><span style="padding-left: 10px; vertical-align: bottom;">Active</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p-checkbox [(ngModel)]="isFeedback" binary="isFeedback"></p-checkbox><span style="padding-left: 10px; vertical-align: bottom;">Display Feedback Questions</span>
            <br><br>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label>Related Help Items:</label>
            <p-listbox optionLabel="name" [(ngModel)]="selectedRelated" [style]="{height:'254px'}" [multiple]="true" [options]="HelpDataR" 
            [filter]="true" [listStyle]="{'max-height': '204px'}"></p-listbox>
          </div>
          <div class="p-col-12 p-md-12">
            <label>Description</label><span style="color: red">*</span>
            <div id="editorjs" style="text-align: left;width:100%; border: solid 1px #495057;"></div>
            <small class="p-invalid">{{descriptionError}}</small>
          </div>
          <div class="p-field p-col-12 p-md-10" style="padding-right: 20px;margin-top: 20px;">
            <small class="p-invalid" style="float: right; margin-top: 20px;">{{responseError}}</small>
          </div>
          <div class="p-field p-col-12 p-md-2" style="float: right;margin-top: 20px;">
            <button pButton pRipple type="button" icon="pi pi-refresh" iconPos="left" label="Reset"
            class="p-button-raised p-button-secondary p-mr-2 p-mb-2 width100" (click)="resetCtrls()"></button>&nbsp;&nbsp;
            <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="{{btnLabel}}" (click)="save()"
              class="p-button-raised p-button-success p-mr-2 p-mb-2 width100" ></button>
          </div>
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.9%;height:auto;">
            <h5 style="color:#495057; padding:15px 0 0 15px; width:50%">{{ applicationName }} Help Items - {{Count}}</h5>
            <span class="p-input-icon-left" style="float: right; margin-top: 10px">
              <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top" (click)="print()" 
                  class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                  style="float: right; width:25px; height:25px;" (click)="print()"></button>
              <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" 
                  class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                  style="float: right; margin-left:15px; width:25px; height:25px;" (click)="exportExcel()"></button>
            </span>
          </div>
          <div  class="invoice invoice-header" id="invoice-content" style="width: 100%;">
            <p-table #dt [value]="HelpData" [columns]="cols" id="excel-table" sortMode="multiple" [autoLayout]="true"
            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"  dataKey="helpGuid"> 
            <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                  [ngClass]="{'width15': i==0 || i==1,'width60':i == 2, 'width100A':i == 3 || i ==4}">{{ col.header }}
                    <p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon>
                  </th>
                  <th style="text-align: center; width:80px;">Actions</th>
                </tr>
                <tr id="trSearch">
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                      <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                      (input)="test($event.target.value,col.field,dt)" style="margin: -10px 0 -10px 0;width: 98%; height:25px;">
                  </th>
                  <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                  <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 15%;">{{ rowData[col.field] }}</td>
                  <td *ngIf="i == 1" style="text-align: left; padding-left:15px; width: 15%;">{{ rowData[col.field] }}</td>
                  <td *ngIf="i == 2" style="text-align: left; padding-left:15px;width: 50%;">{{ rowData[col.field] }}</td>
                  <td *ngIf="i == 3" style="text-align: center; width: 100px;">{{ rowData[col.field] }}</td>
                  <td *ngIf="i == 4" style="text-align: center; width: 100px;">
                    <span *ngIf="rowData.active == true">Yes</span><span *ngIf="rowData.active != true ">No</span>
                  </td>
                </ng-container>
                <td style="text-align: center;width: 80px;">
                  <button pButton type="button" pTooltip="Edit" tooltipPosition="right" (click)="Edit(rowData.helpGuid)"
                  icon="pi pi-pencil"  class="p-button-raised p-button-primary" style="height:25px; width:25px;"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr><td colspan="6">No records found...</td></tr>
            </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 5000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>


