<div class="p-grid p-fluid p-formgrid">
    <div class="p-col-12">
      <div class="card" style="min-height:600px;">
        <p-accordion [activeIndex]="0">
          <p-accordionTab header="Search Criteria">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-3">
                <label>Application:</label>
                <p-multiSelect [options]="applicationsData" (onChange)="AllMenus()" [(ngModel)]="selectedApplication" defaultLabel="Select Application" optionLabel="applicationName" selectedItemsLabel="{0} application selected"></p-multiSelect>
              </div>
              <div class="p-field p-col-12 p-md-2">
                <label>Menu:</label>
                <p-multiSelect [options]="menuData" [(ngModel)]="selectedMenu" defaultLabel="Select Menu" optionLabel="menuName" selectedItemsLabel="{0} menus selected"></p-multiSelect>
            </div>
              <div class="p-field p-col-12 p-md-3">
                  <label>User:</label>
                  <p-multiSelect [options]="usersData" [(ngModel)]="selectedUser" defaultLabel="Select User" optionLabel="fullName" selectedItemsLabel="{0} users selected"></p-multiSelect>
              </div>
             
              <div class="p-field p-col-12 p-md-2">
                <label>From:</label>
                <p-calendar [showIcon]="true" placeholder="Enter From Date" [(ngModel)]="startDate"></p-calendar>
              </div>
              <div class="p-field p-col-12 p-md-2">
                <label>To:</label>
                <p-calendar [showIcon]="true" placeholder="Enter To Date" [(ngModel)]="endDate"></p-calendar>
              </div>
             
            </div>
            <div>
                <div class="p-field p-col-12 p-md-12" style="text-align: center; margin-top: 24px;">               
                    <button pButton pRipple type="button" icon="pi pi-search" pTooltip="Search" label="Search" (click)="Search()"
                    class="p-button-raised p-button-primary" style="width: 120px;" ></button>
                    &nbsp;&nbsp;
                    <button pButton pRipple type="button" icon="pi pi-refresh" pTooltip="Reset" label="Reset" (click)="Reset()"
                    class="p-button-raised p-button-secondary" style="width: 120px;" ></button>
                </div>
                <small class="p-invalid" style="margin-top: 35px;">{{ Error }}</small>
            </div>
          </p-accordionTab>
          <br>
        </p-accordion>
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:auto;">
          <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">User Tracking - {{ count }}</h5>
        
          <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
            <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
              tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
              style="float:right; height:25px; width:25px;"></button>
            <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()" pTooltip="Download"
              tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
              style="float:right; margin-left:15px ;height:25px; width:25px; "></button>
          </span>
        </div>
  
        <div class="invoice invoice-header" id="invoice-content">
          <p-table #dt [value]="trackingData" id="excel-table" [columns]="cols" sortMode="multiple" class="table-align" [scrollable]="true" scrollHeight="400px"
            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id">
            <ng-template pTemplate="header" let-columns class="invoice-items">
              <tr>
                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
              <tr id="trSearch">
                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                  <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder" (input)="test($event.target.value,col.field,dt)"
                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
              <tr [pSelectableRow]="rowData">
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                  <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">
                    {{ rowData[col.field] }}
                  </td>
                  <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 2" style="text-align: left;padding-left:15px">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 3" style="text-align: center;padding-left:15px;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 4" style="text-align: center;padding-left:15px;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 5" style="text-align: center;padding-left:15px;">
                    {{rowData[col.field]}}
                  </td>
                </ng-container>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="6" style="text-align: left;">No records found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
  <div
    style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>