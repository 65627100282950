import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/facade/facade.service';
import { PrintcommonService } from 'src/app/services/printcommon.service';
import * as XLSX from 'xlsx'; 
@Component({
  selector: 'app-usertracking',
  templateUrl: './usertracking.component.html',
  styleUrls: ['./usertracking.component.scss']
})
export class UsertrackingComponent implements OnInit {
  showLoading:boolean;
  applicationsData=[];
  selectedApplication=[];
  usersData=[];
  selectedUser=[];
  menuData=[];
  selectedMenu=[];
  startDate=new Date();
  endDate=new Date();
  Error="";
  trackingData=[];
  cols=[];
  count=0;
  constructor(private _facadeService: FacadeService,public printService: PrintcommonService,private datePipe: DatePipe) { }

  ngOnInit(): void {
    sessionStorage.applicationName="";
    sessionStorage.userName="";
    sessionStorage.menuName="";
    sessionStorage.inTime="";
    sessionStorage.outTime="";
    sessionStorage.timeDifference="";
    this.cols = [
      { field: 'applicationName', header: 'Application', dynamicPlaceHolder: 'Search' },
      { field: 'userName', header: 'User', dynamicPlaceHolder: ' Search' },
      { field: 'menuName', header: 'Menu', dynamicPlaceHolder: 'Search' },
      { field: 'inTime', header: 'In Time', dynamicPlaceHolder: 'Search' },
      { field: 'outTime', header: 'Out Time', dynamicPlaceHolder: 'Search' },
      { field: 'timeDifference', header: 'Time Diff (Minutes)', dynamicPlaceHolder: 'Search' }
    ];
    this.AllApplications();
  }
  AllMenus(){
    this.selectedMenu=[];
    this.menuData=[];
    this.showLoading=true;
    this._facadeService.AllMenus().subscribe(
      (data) => {
      this.showLoading=false;
      var selMenus=[];
      var menmains=[];
      var menuMainData = data.returnObject.filter(m=>m.url!=null);
      menuMainData.forEach(element => {
        element.menuName=element.menuName+ " ("+element.applicationName+")";
        if(this.selectedApplication!=null&&this.selectedApplication.length>0){
          var appe = this.selectedApplication.find(m=>m.applicationId==element.applicationId);
          if(appe!=null){
            selMenus.push(element);
            menmains.push(element);
          }
        }
        else{
          selMenus.push(element);
          menmains.push(element);
        }
      });
      this.selectedMenu=selMenus;
      this.menuData=menmains;
      //console.log("Menu data ::" + JSON.stringify(this.menuData));
    },
    (error) => { 
      this.showLoading=false;
      console.log(error);
    }
  );
  }
  AllApplications(){
    this.selectedApplication=[];
    this.showLoading=true;
    this._facadeService.GetApplications().subscribe(
      (data) => {
      this.applicationsData = data.returnObject; 
      this.applicationsData.forEach(element => {
        this.selectedApplication.push(element);
      });
      this.showLoading=false;
      this.AllMenus();
      this.AllUsers();
    },
    (error) => { 
      this.showLoading=false;
      console.log(error);
    }
  );
  }
  AllUsers() {
    this.showLoading=true;
    this.selectedUser = [];
    this.usersData=[];
    this._facadeService.GetUsers().subscribe(
      (data) => {
        if (data.returnObject != null && data.returnObject.length > 0) 
        {
          data.returnObject.forEach(value => { value.fullName = value.firstName + " " + value.lastName;  });
          this.usersData = data.returnObject;
          this.selectedUser=data.returnObject;
          // this.usersData.forEach(element => {
          //   this.selectedUser.push(element);
          // });
        }
        this.Search();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
Search() {
  this.Error = "";
  if (this.startDate != null && this.startDate != undefined
    && this.endDate != null && this.endDate != undefined && this.startDate > this.endDate) {
    this.Error = "To Date should be greater then from date";
  }
  else {
    this.showLoading = true;
    var users = [];
    if (this.selectedUser != null && this.selectedUser != undefined) {
      if (this.selectedUser.length > 0) {
        this.selectedUser.forEach(element => {
          users.push(element.userId);
        });
      }
    }
    var menus = [];
    if (this.selectedMenu != null && this.selectedMenu != undefined) {
      if (this.selectedMenu.length > 0) {
        this.selectedMenu.forEach(element => {
          menus.push(element.menuId);
        });
      }
    }
    var applications = [];
    if (this.selectedApplication != null && this.selectedApplication != undefined) {
      if (this.selectedApplication.length > 0) {
        this.selectedApplication.forEach(element => {
          applications.push(element.applicationId);
        });
      }
    }
    var myRequestsData =
    {
      "applications": applications,
      "menus": menus,
      "users": users,
      // fromDate:this.startDate,
      // toDate:this.endDate
    }
    this._facadeService.GetUserTracking(myRequestsData).subscribe(
      (data) => {
       
        this.trackingData = data.returnObject;
        console.log(JSON.stringify(this.trackingData));
        if(this.trackingData!=undefined && this.trackingData != null && this.trackingData.length > 0)
        {
          if (this.startDate != null && this.startDate != undefined) {
            this.trackingData = this.trackingData.filter(x => {
              const xDate = new Date(x.inTime);
              xDate.setHours(0, 0, 0, 0); // Set time to midnight
              const startDate = new Date(this.startDate);
              startDate.setHours(0, 0, 0, 0); // Set time to midnight
              if (this.endDate != null && this.endDate != undefined) {
                const endDate = new Date(this.endDate);
                endDate.setHours(0, 0, 0, 0); // Set time to 23:59:59.999
                 // Compare year, month, and day of the dates
                  return xDate >= startDate && xDate <= endDate;
              }
          });
          }
          // else{
          //   if (this.endDate != null && this.endDate != undefined) {
          //     this.trackingData = this.trackingData.filter(x => {
          //       const xDate = new Date(x.inTime);
          //       xDate.setHours(0, 0, 0, 0); // Set time to midnight
          //       const endDate = new Date(this.endDate);
          //       endDate.setHours(0, 0, 0, 0); // Set time to midnight
          //       return xDate==endDate;
          //   });
          //   }
          // }
          // if (this.endDate != null && this.endDate != undefined) {
          //   this.trackingData = this.trackingData.filter(x => {
          //     const xDate = new Date(x.inTime);
          //     const endDate = new Date(this.endDate);
          
          //     // Compare year, month, and day of the dates
          //     return xDate.getFullYear() === endDate.getFullYear() &&
          //            xDate.getMonth() === endDate.getMonth() &&
          //            xDate.getDate() <= endDate.getDate();
          // });
          // }
          this.trackingData.forEach((value, key) => {              
            if(value.inTime != '' && value.inTime != null){
             // value.inTime = new Date(new Date(value.inTime).toDateString() + " " + new Date(value.inTime).toTimeString() + " UTC");      
              value.inTime = this.datePipe.transform(value.inTime, 'MM/dd/yyyy hh:mm:ss a');
            }
             

              if(value.outTime != '' && value.outTime != null){
                //value.OutTime = new Date(new Date(value.OutTime).toDateString() + " " + new Date(value.OutTime).toTimeString() + " UTC");      
                value.outTime = this.datePipe.transform(value.outTime, 'MM/dd/yyyy hh:mm:ss a');
              }
              
          });
          
          this.count=this.trackingData.length;
        }
        
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
    
  }
}
Reset(){
  sessionStorage.applicationName="";
  sessionStorage.userName="";
  sessionStorage.menuName="";
  sessionStorage.inTime="";
  sessionStorage.outTime="";
  sessionStorage.timeDifference="";
  this.selectedApplication=[];
  this.selectedMenu=[];
  this.selectedUser=[];
  this.menuData=[];
  this.applicationsData=[];
  this.usersData=[];
  this.startDate=new Date();
  this.endDate=new Date();
  this.Error="";
  this.trackingData=[];
  this.AllApplications();
  this.AllUsers();
}
delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['G1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
 ec(r, c) {
   return XLSX.utils.encode_cell({r:r,c:c})
 }
 exportExcel(){
   /* table id is passed over here */   
  let element = document.getElementById('excel-table'); 
  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  this.delete_row(wb.Sheets.Sheet1, 1);
  ws['!cols'] = [{ wpx : 180 },{ wpx : 180 },{ wpx : 100 },{ wpx : 180 },{ wpx : 180 },{ wpx : 80 }];
  /* save to file */
  XLSX.writeFile(wb, "User Tracking.xlsx");
 }
print(){
  var Content = document.getElementById('invoice-content').innerHTML;
  this.printService.Print(Content,'UserTracking','trSearch',true);
}
test(val,field,dt)
  {
    if(field == "applicationName")
      sessionStorage.applicationName = val;
    else if(field == "userName")
      sessionStorage.userName = val;
      else if(field == "menuName")
      sessionStorage.menuName = val;
      else if(field == "inTime")
      sessionStorage.inTime = val;
      else if(field == "outTime")
      sessionStorage.outTime = val;
      else if(field == "timeDifference")
      sessionStorage.timeDifference = val;
    dt = dt.filter(val,field, 'contains');
    this.CountData();
  }
  CountData(){
    if(this.trackingData != undefined && this.trackingData != null)
    {
      var temp = this.trackingData;
      if(sessionStorage.applicationName != ""&&sessionStorage.applicationName != null)
        temp = temp.filter(x=> x.applicationName!=null&&x.applicationName!=""&&x.applicationName.toLowerCase().includes(sessionStorage.applicationName.toLowerCase()));
      if(sessionStorage.userName != "")
        temp = temp.filter(x=> x.userName!=null&&x.userName!=""&&x.userName.toLowerCase().includes(sessionStorage.userName.toLowerCase()));
        if(sessionStorage.menuName != "")
        temp = temp.filter(x=> x.menuName!=null&&x.menuName!=""&&x.menuName.toLowerCase().includes(sessionStorage.menuName.toLowerCase()));
        if(sessionStorage.inTime != "")
        temp = temp.filter(x=> x.inTime!=null&&x.inTime!=""&&x.inTime.includes(sessionStorage.inTime));
        if(sessionStorage.outTime != "")
        temp = temp.filter(x=> x.outTime!=null&&x.outTime!=""&&x.outTime.includes(sessionStorage.outTime));
        if(sessionStorage.timeDifference != "")
        temp = temp.filter(x=> x.timeDifference!=null&&x.timeDifference!=""&&x.timeDifference.toLowerCase().includes(sessionStorage.timeDifference.toLowerCase()));
       this.count = temp.length;
    }
    else
      this.count = 0;
  }
}
