<div class="layout-footer" style="margin-top: -2rem !important">
    <div class="footer-logo-container">
      <img
        id="footer-logo"
        src="assets/gallery/logoicon.png"
        alt="FactoryPRO-Logoicon"
      />
      <span class="app-name">FactoryPRO</span>
    </div>
    <span class="copyright">&#169; SolSemUSA - 2020</span>
  </div>