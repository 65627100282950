import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem} from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from '../../services/printcommon.service';
@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {
  Count=0; 
    cols: any[];
    showLoading:boolean;
    @ViewChild("dt") table: Table;
    dynamicPlaceHolder: string;
    addDocsDisplay: boolean;
    departmentsData:any=[];
    departmentName:any;
    departmentNameError:any;
    departmentCode:any;
    departmentCodeError:any;
    active:boolean=true;
    responseMessage:any;
    Type:any="Add";
    FromEdit:boolean=false;
    savelabel:any="Save";
    departmentGuid:any="";
    UserID = sessionStorage.getItem("userGuid");
    AllProductsValue:boolean=true;
    constructor(private _facadeService: FacadeService,private route: ActivatedRoute,public printService: PrintcommonService) { }
  
    ngOnInit(): void {
      sessionStorage.departmentCode = "";
      sessionStorage.departmentName = "";
      sessionStorage.active ="";
      this.AllProductsValue = true;
      if(this.route.snapshot.paramMap.get('Active')!=undefined)
      {
        var ii = this.route.snapshot.paramMap.get('Active');
        if(ii != null && ii != undefined && ii != '')
          this.AllProductsValue = ii == 'false' ? false : true;
      }
      this.cols = [
        { field: 'departmentName', header: 'Department', dynamicPlaceHolder: 'Search' }, 
        { field: 'departmentCode', header: 'Code', dynamicPlaceHolder: 'Search' },        
        { field: 'active', header: 'Active', dynamicPlaceHolder: 'Search' }
      ];
      this.GetDepartments();
    }
    AddNewButton() {
      this.Type="Add";
      this.departmentNameError="";
      this.departmentCodeError="";
      this.responseMessage="";
      this.departmentName="";
      this.departmentCode="";
      this.active=true;
      this.FromEdit=false;
      this.addDocsDisplay = true; 
      this.savelabel="Save";
      this.departmentGuid="";
    }

    ViewDepartment(departmentGuid){
      this.showLoading=true;
      this.departmentNameError="";
      this.departmentCodeError="";
      this.responseMessage="";
      this.departmentName="";
      this.departmentCode="";
      this._facadeService.ViewDepartment(departmentGuid).subscribe(
        (data) => {
          this.addDocsDisplay = true; 
          this.showLoading=false;
          if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
            this.departmentCode=data.returnObject.departmentCode;
            this.active=data.returnObject.active=="Y"?true:false;
            this.departmentName=data.returnObject.departmentName;
          }
            this.departmentGuid=data.returnObject.departmentGuid;
            this.Type="Edit";
            this.FromEdit=true;
            this.savelabel="Update";
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }

    IsActiveChange(){
      this.responseMessage= "";
      if(!this.active){
        this.showLoading=true;
        this._facadeService.CheckDepartmentInactiveById(this.departmentGuid).subscribe(
          (data) => {
            this.showLoading=false;
           if(data.returnObject==true){
             this.responseMessage= "This department is already associated with another Role/User. You cannot inactive";
             this.active=true;
           }
        },
        (error) => { 
          this.showLoading=false;
          console.log(error);
        }
      );
      }
    }

    GetDepartments(){
      this.showLoading=true;
      this._facadeService.GetAllDepartments().subscribe(
        (data) => {
        this.departmentsData = data.returnObject;
        if(!this.AllProductsValue && this.departmentsData != null && this.departmentsData != undefined)
        {
          if(this.departmentsData.length > 0)
          {
            this.departmentsData =  this.departmentsData.filter(x=>x.active.toLowerCase() == "y");
            this.departmentsData =  this.departmentsData.filter(x=>x.departmentName.toLowerCase() != "support");
          }
        } 
        this.Count=this.departmentsData.length;
        this.showLoading=false;
        console.log("Departments data ::" + JSON.stringify(this.departmentsData));
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }
    
    CreateDepartment(){
      this.departmentNameError="";
      this.departmentCodeError="";
      this.responseMessage="";
      if(this.departmentName==""||this.departmentName==null||this.departmentName==undefined){
          this.departmentNameError="Please enter department name.";
      }
      else if(this.departmentCode==""||this.departmentCode==null||this.departmentCode==undefined){
        this.departmentCodeError="Please enter department code.";
    }
    else{
      this.showLoading=true;
      var department={
        DepartmentName:this.departmentName,
        DepartmentCode:this.departmentCode,
        Active:this.active==true?"Y":"N",
        DepartmentGuid:this.departmentGuid
      };
      if(this.departmentGuid!=null && this.departmentGuid!=""&&this.departmentGuid!=undefined){
        this._facadeService.UpdateDepartment(department,this.UserID).subscribe(
          (data) => {
            this.showLoading=false;
            if(data.returnCode==0){
              this.addDocsDisplay=false;
              this.GetDepartments();
            }
            else{
             this.responseMessage=data.returnMessage;
            }
        },
        (error) => { 
          this.showLoading=false;
          console.log(error);
        }
      );
      }
      else{
        this._facadeService.CreateDepartment(department,this.UserID).subscribe(
          (data) => {
            this.showLoading=false;
            if(data.returnCode==0){
              this.addDocsDisplay=false;
              this.GetDepartments();
            }
            else{
             this.responseMessage=data.returnMessage;
            }
        },
        (error) => { 
          this.showLoading=false;
          console.log(error);
        }
      );
      }
    }
    }

   delete_row (ws, row_index){
     let range = XLSX.utils.decode_range(ws["!ref"])
     for(var R = row_index; R < range.e.r; ++R){
         for(var C = range.s.c; C <= range.e.c; ++C){
           ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
           if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
             ws[this.ec(R+1, C)].v  = "";
         }
     }
     range.e.r--
     delete (ws['D1'])
     ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
   }
    ec(r, c) {
      return XLSX.utils.encode_cell({r:r,c:c})
    }
    export(){
      /* table id is passed over here */   
     let element = document.getElementById('excel-Departments'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     ws['!cols'] = [{ wpx : 180 },{ wpx : 50 },{ wpx : 50 }];
     /* save to file */
     XLSX.writeFile(wb, "Departments.xlsx");
    }
    print() {
      var Content = document.getElementById('invoice-content').innerHTML;
      this.printService.Print(Content,'Departments','trSearch',true);
  }
  test(val,field,dt)
  {
    if(field == "active")
      sessionStorage.active = val;
    else if(field == "departmentCode")
      sessionStorage.departmentCode = val;
      else if(field == "departmentName")
      sessionStorage.departmentName = val;
    dt = dt.filter(val,field, 'contains');
    this.CountData();
  }
  CountData(){
    if(this.departmentsData != undefined && this.departmentsData != null)
    {
      var temp = this.departmentsData;
      if(sessionStorage.active != ""&&sessionStorage.active != null)
        temp = temp.filter(x=> x.active!=null&&x.active!=""&&x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
      if(sessionStorage.departmentCode != "")
        temp = temp.filter(x=> x.departmentCode!=null&&x.departmentCode!=""&&x.departmentCode.toLowerCase().includes(sessionStorage.departmentCode.toLowerCase()));
        if(sessionStorage.departmentName != "")
        temp = temp.filter(x=> x.departmentName!=null&&x.departmentName!=""&&x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));
       
       this.Count = temp.length;
    }
    else
      this.Count = 0;
  }
  }
