import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from '../../services/printcommon.service';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import { ActivatedRoute } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss']
})
export class MenusComponent implements OnInit {
  Count=0;
  cols: any[];
  showLoading:boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  menuData:any=[];
  UserID = sessionStorage.getItem("userGuid");
  clients:any[];
  constructor(private _facadeService: FacadeService,
    private route: ActivatedRoute,public printService: PrintcommonService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }
    ngOnInit(): void {
      sessionStorage.applicationName = "";
      sessionStorage.menuName = "";
      sessionStorage.menuCode = "";
      sessionStorage.active ="";
      sessionStorage.clientName = "";
      sessionStorage.isParent = "";
      sessionStorage.path = "";
      this.cols = [
        { field: 'clientName', header: 'Client', dynamicPlaceHolder: 'Search' },
        { field: 'applicationName', header: 'Application', dynamicPlaceHolder: 'Search' },
        { field: 'menuName', header: 'Menu', dynamicPlaceHolder: 'Search' },  
        { field: 'menuCode', header: 'Menu Code', dynamicPlaceHolder: 'Search' },
        { field: 'isParent', header: 'Is Parent', dynamicPlaceHolder: 'Search' },
        { field: 'path', header: 'Path', dynamicPlaceHolder: 'Search' },
        { field: 'active', header: 'Active', dynamicPlaceHolder: 'Search' }
      ];
      this.clients = [
      { label: 'Kinetic', value: 1},
      { label: 'SolSem', value: 2}
      ];
      this.AllMenus();
    }
    AllMenus(){
      this.showLoading=true;
      this._facadeService.AllMenus().subscribe(
        (data) => {
        this.menuData = data.returnObject; 
        this.Count = 0;
        if(this.menuData != null && this.menuData != undefined)
        {
          this.Count=this.menuData.length;
          this.menuData.forEach(e => {
            e.isParent = e.parentMenuId == 0 && e.headerMenuId == 0 ? "Y" : "N";
            e.path = "--";
            if(e.parentMenuId != 0)
              e.path = e.parentMenuName + " >> " +e.menuName;
            if(e.headerMenuId != 0)
            {
              if(e.parentMenuId != 0)
              {
                if(e.headerMenuName != e.parentMenuName)
                  e.path = e.headerMenuName + " >> " +e.path;
              }
              else 
                e.path = e.headerMenuName + " >> " +e.path;
            }
            e.clientName = "";
            var a = this.clients.find(x=>x.value == e.clientId);
            if(a!= null)
              e.clientName = a.label;
          });
        }
        this.showLoading=false;
        console.log("Menu data ::" + JSON.stringify(this.menuData));
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }
    delete_row (ws, row_index){
      let range = XLSX.utils.decode_range(ws["!ref"])
      for(var R = row_index; R < range.e.r; ++R){
          for(var C = range.s.c; C <= range.e.c; ++C){
              ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
              if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
                ws[this.ec(R+1, C)].v  = "";
          }
      }
      range.e.r--
      delete (ws['H1'])
      ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
    }
    ec(r, c) {
      return XLSX.utils.encode_cell({r:r,c:c})
    }
    export(){
      /* table id is passed over here */   
     let element = document.getElementById('excel-roles'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     ws['!cols'] = [{ wpx : 50 },{ wpx : 190 },{ wpx : 180 },{ wpx : 100 },{ wpx : 50 },{ wpx : 280 },{ wpx : 50 }];
     /* save to file */
     XLSX.writeFile(wb, "Menus.xlsx");
    }
    print() {
      var Content = document.getElementById('invoice-content').innerHTML;
      this.printService.Print(Content,'Menus','trSearch',true);
  }
  test(val,field,dt)
  {
    if(field == "clientName")
      sessionStorage.clientName = val;
    else if(field == "active")
      sessionStorage.active = val;
    else if(field == "applicationName")
      sessionStorage.applicationName = val;
    else if(field == "menuCode")
      sessionStorage.menuCode = val;
    else if(field == "menuName")
      sessionStorage.menuName = val;
    else if(field == "isParent")
      sessionStorage.isParent = val;
    else if(field == "path")
      sessionStorage.path = val;
    dt = dt.filter(val,field, 'contains');
    this.CountData();
  }
  CountData(){
    if(this.menuData != undefined && this.menuData != null)
    {
      var temp = this.menuData;
      if(sessionStorage.clientName != ""&&sessionStorage.clientName != null)
        temp = temp.filter(x=> x.clientName!=null&&x.clientName!=""&&x.clientName.toLowerCase().includes(sessionStorage.clientName.toLowerCase()));
      if(sessionStorage.active != ""&&sessionStorage.active != null)
        temp = temp.filter(x=> x.active!=null&&x.active!=""&&x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
      if(sessionStorage.applicationName != "")
        temp = temp.filter(x=> x.applicationName!=null&&x.applicationName!=""&&x.applicationName.toLowerCase().includes(sessionStorage.applicationName.toLowerCase()));
      if(sessionStorage.menuCode != "")
        temp = temp.filter(x=> x.menuCode!=null&&x.menuCode!=""&&x.menuCode.toLowerCase().includes(sessionStorage.menuCode.toLowerCase()));
      if(sessionStorage.menuName != "")
        temp = temp.filter(x=> x.menuName!=null&&x.menuName!=""&&x.menuName.toLowerCase().includes(sessionStorage.menuName.toLowerCase()));
      if(sessionStorage.isParent != "")
        temp = temp.filter(x=> x.isParent!=null&&x.isParent!=""&&x.isParent.toLowerCase().includes(sessionStorage.isParent.toLowerCase()));
      if(sessionStorage.path != "")
        temp = temp.filter(x=> x.path!=null&&x.path!=""&&x.path.toLowerCase().includes(sessionStorage.path.toLowerCase()));
      this.Count = temp.length;
    }
    else
      this.Count = 0;
  }
  AddNewButton(){
    location.href=this.baseUrl["API_ENDPOINTS"]["UMSUI"]+'createupdatemenudetails';
  }
  ViewMenu(menuId){
    location.href=this.baseUrl["API_ENDPOINTS"]["UMSUI"]+'createupdatemenudetails?menuId='+menuId;
  }
  }
