import { Injectable } from '@angular/core';
import { HttputilityService } from './httputility.service';
import EditorJS from "@editorjs/editorjs";
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Marker from '@editorjs/marker';
import Image from '@editorjs/image';
import Table from '@editorjs/table';
import Paragraph from '@editorjs/paragraph';
import { API_ENDPOINTS } from "../app.component";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import createGenericInlineTool from 'editorjs-inline-tool'


@Injectable({
  providedIn: 'root'
})
export class UserService {
  editor: any;
  constructor(private _httputilityService: HttputilityService) { }

  GetDepartments() {
    return this._httputilityService.GetU('user/GetDepartments');
  }
  GetAllDepartments() {
    return this._httputilityService.GetU('user/GetAllDepartments');
  }
  CreateDepartment(model, ActionBy) {
    return this._httputilityService.Add('user/CreateDepartment?ActionBy=' + ActionBy, model);
  }
  UpdateDepartment(model, ActionBy) {
    return this._httputilityService.Add('user/UpdateDepartment?ActionBy=' + ActionBy, model);
  }
  CheckDepartmentInactiveById(guid) {
    return this._httputilityService.GetU('user/CheckDepartmentInactiveById?guid=' + guid);
  }
  ViewDepartment(departmentGuid) {
    return this._httputilityService.GetU('user/GetDepartmentByGuid?deptGuid=' + departmentGuid);
  }

  GetRoles() {
    return this._httputilityService.GetU('user/GetRoles');
  }
  GetAllRoles() {
    return this._httputilityService.GetU('user/GetAllRoles');
  }
  CheckRoleInactiveById(guid) {
    return this._httputilityService.GetU('user/CheckRoleInactiveById?guid=' + guid);
  }
  CreateRole(model, ActionBy) {
    return this._httputilityService.Add('user/CreateRole?ActionBy=' + ActionBy, model);
  }
  UpdateRole(model, ActionBy) {
    return this._httputilityService.Add('user/UpdateRole?ActionBy=' + ActionBy, model);
  }
  ViewRole(roleGuid) {
    return this._httputilityService.GetU('user/GetRoleByGuid?roleGuid=' + roleGuid);
  }
  GetFeatureById(featureid){
    return this._httputilityService.GetU('GetFeatureById?featureid=' + featureid);
  }
  GetBusinessUnit() {
    return this._httputilityService.GetU('user/GetBusinessUnits');
  }
  GetAllBusinessUnit() {
    return this._httputilityService.GetU('user/GetAllBusinessUnit');
  }
  CheckBusinessUnitInactiveById(guid) {
    return this._httputilityService.GetU('user/CheckBusinessUnitInactiveById?guid=' + guid);
  }
  CreateBusinessUnit(model, ActionBy) {
    return this._httputilityService.Add('user/CreateBusinessUnit?ActionBy=' + ActionBy, model);
  }
  UpdateBusinessUnit(model, ActionBy) {
    return this._httputilityService.Add('user/UpdateBusinessUnit?ActionBy=' + ActionBy, model);
  }
  ViewBusinessUnit(BusinessUnitGuid) {
    return this._httputilityService.GetU('user/GetBusinessUnitByGuid?Guid=' + BusinessUnitGuid);
  }
  createSite(model, actionby) {
    return this._httputilityService.Add('user/CreateSite?actionby=' + actionby, model);
  }
  updateSite(model, actionby) {
    return this._httputilityService.Add('user/UpdateSite?actionby=' + actionby, model);
  }

  viewSite(siteGUID) {
    return this._httputilityService.GetU('user/GetSiteByGuid?guid=' + siteGUID);
  }

  GetApplications() {
    return this._httputilityService.GetU('user/GetApplications');
  }
  GetApplicationGroups() {
    return this._httputilityService.GetU('user/GetApplicationGroups');
  }
  GetAllApplicationGroups() {
    return this._httputilityService.GetU('user/GetAllApplicationGroups');
  }
  UpdateApplicationGroup(group) {
    return this._httputilityService.Add("user/UpdateApplictaionGroup", group);
  }
  CreateApplicationGroup(group) {
    return this._httputilityService.Add("user/CreateApplicationGroup", group);
  }
  GetGroupApplicationDetailsById(guid) {
    return this._httputilityService.GetU('user/GetGroupApplicationDetailsById?guid=' + guid);
  }
  CheckApplicationGroupInactiveById(guid) {
    return this._httputilityService.GetU('user/CheckApplicationGroupInactiveById?guid=' + guid);
  }
  GetGroupApplicationData(guids) {
    return this._httputilityService.Add("user/GetGroupApplicationData", guids);
  }
  GetUsersByDepID(depid) {
    return this._httputilityService.GetU("user/GetUsersByDepID?depid=" + depid);
  }
  UpdateUserDefaultApplication(userid, applicationid) {
    return this._httputilityService.GetU('user/UpdateUserDefaultApplication?userId=' + userid + "&applicationId=" + applicationid);
  }
  UpdateUserDefaultDepartment(userid, depid) {
    return this._httputilityService.GetU('user/UpdateUserDefaultDepartment?userId=' + userid + "&departmentId=" + depid);
  }
  getRolesByDepartment(departmentId) {
    return this._httputilityService.GetU('user/GetRolesByDepartment?DepartmentGUID=' + departmentId);
  }
  getRolesByDepartmentGuids(departmets) {
    return this._httputilityService.Add("user/GetRolesByDepartments", departmets);
  }
  GetAllUsers() {
    return this._httputilityService.GetU('user/GetAllUsers');
  }
  GetUsers() {
    return this._httputilityService.GetU('user/GetUsers');
  }
  GetTimeZones() {
    return this._httputilityService.GetU('user/GetTimeZones');
  }
  GetSites() {
    return this._httputilityService.GetU('user/GetSites');
  }
  GetDependencySupervisorList(userId) {
    return this._httputilityService.GetU('user/GetDependencySupervisorList?userId=' + userId);
  }
  SaveUser(user) {
    return this._httputilityService.Add("user/CreateUser", user);
  }
  UpdateUser(user) {
    return this._httputilityService.Add("user/UpdateUser", user);
  }
  DMSUserApprovals(userguid) {
    return this._httputilityService.PostDMS("UserApprovals?userGuid=" + userguid, null);
  }
  DMSMyDocuments(data) {
    return this._httputilityService.PostDMS("MyDocuments", data);
  }
  GetInprocessDocUsersFromCMSToInactive(userguid) {
    return this._httputilityService.GetCMS("GetChangeRequestsAndApprovalHistoryByUserId?userGuid=" + userguid);
  }
  GetMyIssuesByUserIdUserDeactivation(userguid) {
    return this._httputilityService.GetITM("GetMyIssuesByUserIdUserDeactivation?userGuid=" + userguid);
  }
  GetUserReplacementInfo(userguid) {
    return this._httputilityService.GetPDM("Product/GetUserReplacementInfo?UserID=" + userguid);
  }
  UpdateUserReplacementInfo(userguid, ActionBy, model) {
    return this._httputilityService.PostPDM("Product/UpdateUserReplacementInfo?UserID=" + userguid + "&ActionBy=" + ActionBy, model);
  }
  SaveProductRole(data) {
    return this._httputilityService.PostDMS("SaveProductRole", data);
  }
  CreateSecurityToken() {
    return this._httputilityService.Add("user/CreateSecurityToken", null);
  }
  UpdateSecurityToken(token) {
    return this._httputilityService.Add("user/UpdateSecurityToken?Token=" + token, null);
  }
  approvalNeededData(model) {
    return this._httputilityService.PostDMS("MyApprovals", model);
  }

  getMenus(model){
    return this._httputilityService.Add("GetMenus",model);
  }  
  getHelp(model){
    return this._httputilityService.Add("GetHelp",model);
  }  

  AddHelp(model){
    return this._httputilityService.Add("AddHelp",model);
  }  
  UpdateHelp(model){
    return this._httputilityService.Add("UpdateHelp",model);
  } 
  
  CheckAavailability(ID)
  {
    return this._httputilityService.GetU("CheckAavailability?helpid=" + ID);
  }

  
  //from here
  GetHelpByID(HelpID){
    return this._httputilityService.GetU("GetHelpByGuid?helpGuid=" + HelpID);
  }  
  GetQuestions()
  {
    return this._httputilityService.GetU("GetQuestions?active=true");
  }
  AddFeedback(model)
  {
    return this._httputilityService.Add("AddFeedback",model);
  }
  
  SearchHelp(text,AppID){
    return this._httputilityService.GetU("SearchHelp?input=" + text+"&applicationguid=" + AppID);
  }  

  /* Begin: EditorJs */

  editorJsInit(editorJsBlockData?: [], readyOnly?) {
    this.editor = new EditorJS({
      placeholder: 'Click here to write something!',
      autofocus: true,
      holder: 'editorjs',
      readOnly: readyOnly,
      //inlineToolbar: ['link', 'marker', 'bold', 'italic', 'underline'],
      data: {
        time: 1552744582955,
        blocks: editorJsBlockData,
        version: "2.27.0"
      },
      tools: {
        header: {
          class: Header,
          inlineToolbar: ['link'],
          tunes: ['anyTuneName'],
        },
        // list: {
        //   class: List,
        //   inlineToolbar: true,
        //   config: {
        //     defaultStyle: 'unordered'
        //   }
        // },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          tunes: ['anyTuneName'],
        },
        bold: {
          class: createGenericInlineTool({
            sanitize: {
              strong: {},
            },
            shortcut: 'CMD+B',
            tagName: 'STRONG',
            toolboxIcon:
              '<svg class="icon icon--bold" width="12px" height="14px"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#bold"></use></svg>',
          }),
        },
        anyTuneName: {
          class:AlignmentTuneTool,
          config:{
            default: "right",
            blocks: {
              header: 'center',
              list: 'right'
            }
          },
        },
        // checklist: {
        //   class: Checklist,
        // },
        marker: {
          class: Marker,
          shortcut: 'CMD+SHIFT+M'
        },
        // table: {
        //   class: Table,
        //   inlineToolbar: true,
        //   config: {
        //     rows: 2,
        //     cols: 3,
        //   },
        // },
        // linkTool: {
        //   class: LinkTool,
        //   config: {
        //     endpoint: DMUrls.editorGetLink // Your backend endpoint for url data fetching,
        //   }
        // },
        // columns: {
        //   class: editorjsColumns,
        //   config: {
        //     tools: this.column_tools, // IMPORTANT! ref the column_tools
        //   }
        // },
        image: {
          class: Image,
          config: {
            endpoints: {
              byFile: API_ENDPOINTS.UMSAuthAPI + "UploadFile",  // DMUrls.editorFileload, // Your backend file uploader endpoint
              byUrl: API_ENDPOINTS.UMSAuthAPI + "UploadFile",  // environment.fileFetchUrl, // Your endpoint that provides uploading by Url
            },
            field: "file",
          }
        },
        // embed: {
        //   class: Embed,
        //   inlineToolbar: true,
        //   config: {
        //     services: {
        //       youtube: true,
        //       coub: true,
        //       imgur: true
        //     }
        //   }
        // },
        // delimiter: Delimiter,
        // // image: SimpleImage,
        // quote: {
        //   class: Quote,
        //   inlineToolbar: true,
        //   shortcut: 'CMD+SHIFT+O',
        //   config: {
        //     quotePlaceholder: 'Enter a quote',
        //     captionPlaceholder: 'Quote author',
        //   },
        // },

        // // warning: {
        // //   class: Warning,
        // //   inlineToolbar: true,
        // //   shortcut: 'CMD+SHIFT+W',
        // //   config: {
        // //     titlePlaceholder: 'Title',
        // //     messagePlaceholder: 'Message',
        // //   },
        // // },
        // breakLine: {
        //   class: BreakLine,
        //   inlineToolbar: true,
        //   shortcut: 'CMD+SHIFT+ENTER',
        // },
      },
    });

    // first define the tools to be made avaliable in the columns
    /*
    this.column_tools = {
      header: {
        class: Header,
        inlineToolbar: ['link']
      },
      list: {
        class: List,
        inlineToolbar: true,
        config: {
          defaultStyle: 'unordered'
        }
      },
      checklist: {
        class: Checklist,
      },
      marker: {
        class: Marker,
        shortcut: 'CMD+SHIFT+M'
      },
      table: {
        class: Table,
        inlineToolbar: true,
        config: {
          rows: 2,
          cols: 3,
        },
      },
      linkTool: {
        class: LinkTool,
        config: {
          endpoint: DMUrls.editorGetLink // Your backend endpoint for url data fetching,
        }
      },
      image: {
        class: ImageTool,
        config: {
          endpoints: {
            byFile: DMUrls.editorFileload, // Your backend file uploader endpoint
            byUrl: environment.fileFetchUrl, // Your endpoint that provides uploading by Url
          }
        }
      },
      embed: {
        class: Embed,
        inlineToolbar: true,
        config: {
          services: {
            youtube: true,
            coub: true,
            imgur: true
          }
        }
      },
      delimiter: Delimiter,
      // image: SimpleImage,
      quote: {
        class: Quote,
        inlineToolbar: true,
        shortcut: 'CMD+SHIFT+O',
        config: {
          quotePlaceholder: 'Enter a quote',
          captionPlaceholder: 'Quote author',
        },
      },
      breakLine: {
        class: BreakLine,
        inlineToolbar: true,
        shortcut: 'CMD+SHIFT+ENTER',
      },
    }
    // this.editor.readOnly.toggle();
    */
  }


  editorJsDestroy() {
    this.editor.destroy();
  }



  /* End: EditorJs */

  GetFeatures(model) {
    return this._httputilityService.Add('GetFeatures',model);
  }
  CreateFeature(model){
    return this._httputilityService.Add('CreateFeature',model);
  }
  UpdateFeature(model){
    return this._httputilityService.Add('UpdateFeature',model);
  }

  AllRolesFeatures(){
    return this._httputilityService.GetU('user/AllRolesFeatures');
  }
  InsertRoleFeatures(model){
    return this._httputilityService.Add('user/InsertRoleFeatures',model);
  }
  UpdateRoleFeatures(model){
    return this._httputilityService.Add('user/UpdateRoleFeatures',model);
  }
  GetRolesByFeatureId(fetureid){
    return this._httputilityService.GetU('user/GetRolesByFeatureId?fetureid='+fetureid);
  }
  AllMenus(){
    return this._httputilityService.GetU('AllMenus');
  }
  CreateMenu(model){
    return this._httputilityService.Add('CreateMenu',model);
  }
 UpdateMenu(model){
    return this._httputilityService.Add('UpdateMenu',model);
  }
  GetMenuById(menuid){
    return this._httputilityService.GetU('GetMenuById?menuid='+menuid);
  }
  GetMenusByRoles(roles){
    return this._httputilityService.Add('GetMenusByRoles',roles);
  }
   InsertRoleMenus(model){
    return this._httputilityService.Add('InsertRoleMenus',model);
  }
  UpdateRoleMenus(model){
    return this._httputilityService.Add('UpdateRoleMenus',model);
  }
  GetMenusByUser(userId,applicationid){
    return this._httputilityService.GetU('GetMenusByUser?userid='+userId+'&applicationid='+applicationid);
  }
  AddUserTracking(model){
    return this._httputilityService.Add('user/AddUserTracking',model);
  }
  GetUserTracking(model){
    return this._httputilityService.Add('user/GetUserTracking',model);
  }
}
