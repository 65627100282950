import { Inject, Injectable } from "@angular/core";
import { BASE_API_URL_TOKEN } from '../injectors';
import { HttputilityService } from "../services/httputility.service";
@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private _httputilityService: HttputilityService) {}

  getDashboardCounts() {
    return this._httputilityService.GetU("user/GetDashboardCounts");
  }
}
