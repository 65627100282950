import { Component, Inject, OnDestroy } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { BreadcrumbService } from './app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { Router,ActivatedRoute } from '@angular/router';
import { FacadeService } from './facade/facade.service';
import { BASE_API_URL_TOKEN } from './injectors';
import { API_ENDPOINTS } from './app.component';
@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnDestroy{
  subscription: Subscription;
  firstName = sessionStorage.getItem('firstName');
  profileDisplayExist = sessionStorage.getItem('profileDisplayExist')=="true"?true:false;
  profileDisplayPic = sessionStorage.getItem('profileDisplayPic');
  applications = JSON.parse(sessionStorage.getItem('applications'));
  departments = [];
  userGuid = sessionStorage.getItem('userGuid');
  items: MenuItem[];
  TopMenuDisplay = false;
  userId = parseInt(sessionStorage.getItem('userId'));
  showLoading:boolean;
  TopMenuItemsList:any[]=[];
  HeaderText:boolean=true;
  userFeatures = sessionStorage.getItem('userFeatures');
    profilePIc:any;
    constructor(
      public breadcrumbService: BreadcrumbService,
      public app: AppMainComponent,
      private router: Router,
      private route: ActivatedRoute,
    private _facadeService: FacadeService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
    ) {
      var w = window.innerWidth;
      if(w <= 500)
        this.HeaderText = false;
      this.subscription = breadcrumbService.itemsHandler.subscribe((response) => {
        this.items = response;
      });
      var bearer_token=this.route.snapshot.queryParamMap.get('bt');
      if(bearer_token!=null&&bearer_token!=""&&bearer_token!=undefined){
        sessionStorage.bt= bearer_token;
      }
      var source = this.route.snapshot.queryParamMap.get('source');
      var guid = this.route.snapshot.queryParamMap.get('userguid');
      var userId = this.route.snapshot.queryParamMap.get('userId');
      var fromUsers = this.route.snapshot.queryParamMap.get('fromUsers');
      
      let user = true;
      if(source != null && source != undefined && source == "new")
        user = false;
      if(user)
      {
        if(guid!='' && guid!=undefined &&fromUsers!="Y"){
          this.userGuid=guid;
          this.userId = parseInt(userId);
          this.getUserDetails();
        }
        else{
          if(this.applications!=null&&this.applications.length>0){
          this.bindTopMenuList();
          }
        }
        if(this.userGuid==null &&this.userGuid=="" ||this.userGuid==undefined){
          location.href=this.baseUrl["API_ENDPOINTS"]["UMSUI"];
        }
        if(this.profilePIc!=null&&this.profilePIc!=""&&this.profilePIc!=undefined){
          API_ENDPOINTS.UMSAPI +
            "user/Download?filename=" +
            this.profilePIc;
        }
      }
    }
    ProfileClick(){
      let ShowMenu="N";
      this.applications.forEach((value, key) => {
        if(value.name == "User Management" && value.active == "Y"){
          ShowMenu="Y";
        }
      });
      sessionStorage.ShowMenu=ShowMenu;
      this.router.navigate(["/profile"]);
    }
    LogoutClick(){
      var userId = parseInt(sessionStorage.getItem('userId'));
          var appId= parseInt(sessionStorage.getItem('appliId'));
          var modelData ={
            "userId": userId,
            "applicationId": appId,
            "source":"logout"
          };
          this._facadeService.AddUserTracking(modelData).subscribe(
            (data) => {
              sessionStorage.clear();
              location.href =this.baseUrl["API_ENDPOINTS"]["UMSUI"];
            },
            (error) => {
              console.log(error);
            }
          );
     
    } 
    help(){
      this.router.navigate(["/searchhelp"]);
  }
  getUserDetails(){
    this.showLoading = true;
   this._facadeService.GetUserDetails(this.userGuid)
  .subscribe(
     (data) => {
      this.showLoading = false;
    var userDetails = data.returnObject;
    sessionStorage.userId = userDetails.userId;
    sessionStorage.userGuid =  this.userGuid;
    this.applications=userDetails.userApplications;
    sessionStorage.un= userDetails.username;
    sessionStorage.pwd= userDetails.password;
    sessionStorage.defaultApplication = userDetails.defaultApplication;
    sessionStorage.defaultIdentifierApp = userDetails.defaultIdentifierApp;
    sessionStorage.applications = JSON.stringify(userDetails.userApplications);
    sessionStorage.userFeatures="";
    if(userDetails.userFeatures!=null&&userDetails.userFeatures.length>0){
      sessionStorage.userFeatures = JSON.stringify(userDetails.userFeatures);
    }
    this.applications.forEach((value, key) => {
      if(value.name == "User Management")
          sessionStorage.applicationID = value.id;
    });
    this.departments = userDetails.userDepartments;
    this.departments= this.departments.slice(0,1);
    this.departments.forEach((value, key) => {
      sessionStorage.userDepartmentID = value.id;
      sessionStorage.userDepartment = value.name;
    });
    sessionStorage.firstName= userDetails.firstName;
    this.firstName= userDetails.firstName;
    sessionStorage.ManagerGuid = userDetails.managerGuid;
    this.bindTopMenuList();
    if(userDetails.userProfilePic!='' && userDetails.userProfilePic!=undefined){
      this.profileDisplayExist=true;
      if(this.baseUrl["API_ENDPOINTS"]["Storage"]=="azure"){
        this.profileDisplayPic = this.baseUrl["API_ENDPOINTS"]["BlobDownloadURL"]+userDetails.userProfilePic;
      }
      else{
        this.profileDisplayPic =
        API_ENDPOINTS.UMSAPI +
        "user/Download?filename=" +
        userDetails.userProfilePic;
      }
      sessionStorage.profileDisplayPic= this.profileDisplayPic;
      sessionStorage.profileDisplayExist= this.profileDisplayExist;
    }
  },
  (error) => { 
    this.showLoading = false;
    console.log(error);
  }
);
}
    TopMenuClicks(menuItemclick,id){ 
      var IsDCC = sessionStorage.getItem('IsDCC')!=''&& sessionStorage.getItem('IsDCC')!=null&&sessionStorage.getItem('IsDCC')!=undefined?sessionStorage.getItem("IsDCC").toLowerCase() == "true"?true:false:false;
      var clientId = sessionStorage.getItem('clientId')!=''&& sessionStorage.getItem('clientId')!=null&&sessionStorage.getItem('clientId')!=undefined?parseInt(sessionStorage.getItem('clientId')):0;
      this.showLoading = true;
            this._facadeService.CreateSecurityToken().subscribe(
              (data) => {
                this.showLoading = false;
                if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
                  var access_token = data.returnObject;
                  window.open(this.baseUrl["API_ENDPOINTS"][menuItemclick]+'entrypoint?userguid='+this.userGuid+'&userId='+this.userId+'&IsDCC='+IsDCC+'&access_token='+access_token+'&source=dashboard'+'&bt='+sessionStorage.getItem('bt')+"&appliId="+id+"&clientId="+clientId, '_self');
                }
                else{
                  location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
                }
              },
              (error) => {
                this.showLoading = false;
                console.log(error);
              } 
            );
    }

bindTopMenuList(){
    this.TopMenuItemsList=[];
    this.applications.forEach((value, key) => {
      if(value.projectIdentifier!="UMSUI"){
        this.TopMenuItemsList.push({ id:value.applicationId, identifier: value.projectIdentifier, iconPath: value.iconPath, displayText: value.displayText});
      }
    });
  }


    ngOnDestroy() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }
}
