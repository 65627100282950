<div class="p-grid p-fluid">
    <div class="p-col-12">
<div class="card" style="min-height:600px;">
    <div class="p-grid p-fluid p-formgrid" >
        <div class="p-field p-col-12 p-lg-10"></div>
        <div class="p-field p-col-12 p-lg-2" >
        <a>
            <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add"
                class="p-button-raised p-button-primary" (click)="AddNewButton()"
                style="width:auto;float: right;"></button>
        </a>
</div>
</div>

    <span class="p-input-icon-left" style="float: right">
        <p-dialog header="{{featureHeader}}" [(visible)]="addDisplay" modal="modal" showEffect="fade"
            [style]="{ width: '50%' }">
            <hr /><br>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-2"></div>
                <div class="p-field p-col-12 p-md-8">
                    <label>Application<span style="color: red">*</span></label>
                    <p-dropdown [options]="applicationsData" [disabled]="FromEdit"  [(ngModel)]="selectedApplication" placeholder="Select a application"
                optionLabel="applicationName"></p-dropdown>
                    <small class="p-invalid">{{ applicationError }}</small>
                    <br />
                    <label>Feature Name<span style="color: red">*</span></label>
                    <input type="text" pInputText placeholder="Enter Feature Name" [(ngModel)]="featureName" />
                    <small class="p-invalid">{{ featureNameError }}</small>
                    <br /><br />
                    <label>Code<span style="color: red">*</span></label>
                    <input type="text"  pInputText placeholder="Enter Code" [(ngModel)]="code" />
                    <small class="p-invalid">{{ codeError }}</small>
                    <br /><br/>
                    <label>Roles<span style="color: red">*</span></label>
                    <p-multiSelect [options]="rolesData"
                            [(ngModel)]="selectedRole" defaultLabel="Select Role" optionLabel="roleName">
                          </p-multiSelect>
                    <small class="p-invalid">{{ roleError }}</small>
                    <br /><br />
                    <label>Description</label>
                    <textarea rows="5" pInputTextarea id="Description" [(ngModel)]="Description" placeholder="Enter Description"
                  maxlength="1000" ng-trim="false" style="width: 100%;" ></textarea>
                    <br />
                    <div class="p-grid p-fluid">
                        <div class="p-col-12 p-md-5" style="text-align: left; margin-top:25px;">
                            <label style="margin-right: 20px;margin-bottom: -5px;">Role Access</label>
                            <p-checkbox binary="roleAccess" [(ngModel)]="roleAccess"></p-checkbox>
                        </div>
                        <div class="p-col-12 p-md-4" style="text-align: left; margin-top:25px;">
                            <label style="margin-right: 20px;margin-bottom: -5px;">Active</label>
                            <p-checkbox binary="active" [disabled]="!FromEdit" [(ngModel)]="active"
                              ></p-checkbox>
                        </div>
                      </div>
                    <br />
                </div>
                <div class="p-field p-col-12 p-md-2"></div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-9"><small class="p-invalid" style="padding-left: 20px;">{{ responseMessage}}</small></div>
                <div class="p-field p-col-12 p-md-2">
                    <br />
                    <button pButton pRipple type="button" icon="pi pi-check" label="{{Savelabel}}"
                    class="p-button-raised p-button-success p-mr-2 p-mb-2" style="float: right;width:auto" (click)="CreateFeature()"></button>
                </div>
                <div class="p-field p-col-12 p-md-1"></div>
            </div>
        </p-dialog>
    </span>

    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
        <h5 style="color:#495057; padding:15px 0 0 15px;width: 70%;">Features - {{Count}}</h5>
        <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2" (click)="print()"
                style="float:right;height:25px; width:25px;"></button>
            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" (click)="export()"
                style="float:right; height:25px; width:25px;"></button>
        </span>
    </div>
    <div class="invoice invoice-header" id="invoice-content">
        <p-table #dt [value]="featuresData" id="excel-roles" [columns]="cols" sortMode="multiple" class="table-align" [scrollable]="true" scrollHeight="580px"
            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id"
            [globalFilterFields]="['applicationName','featureName','code','roleNames','roleAccess', 'active']">
            <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" 
                    [ngClass]="{'applicationName':i==0,'featureName': i == 1,'code': i == 2,
                    'roleNames': i == 3,'roleAccess': i == 4,'active': i == 5}">
                        {{col.header}} 
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                    <th style="width:50px;">Actions</th>
                </tr>
                <tr id="trSearch">
                    <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                        <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                        (input)="test($event.target.value,col.field,dt)"
                            style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                        <td *ngIf="i == 0" style="text-align: left;padding-left:15px; width:180px;">
                            {{ rowData[col.field] }}
                        </td>
                        <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width:180px;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width:120px;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width:180px;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 4" style="text-align: center;padding-left:15px;width:70px;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 5" style="text-align: center;padding-left:15px;width:60px;">
                            {{rowData[col.field]}}
                        </td>
                    </ng-container>
                    <td style="text-align: center;width:50px;">
                        <a> <button pButton type="button" icon="pi pi-pencil" pTooltip="edit" tooltipPosition="left"
                                class="p-button-raised p-button-primary"
                                style="width:25px; height:25px;text-align:center;"
                                (click)="ViewFeature(rowData.featureId);"></button>
                        </a>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7" style="text-align: left;">No records found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
    </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>