<div class="login-body">
  <div class="login-wrapper">
    <div class="login-image">
      <div class="login-image-content" style="text-align: center">
        <img
          src="assets/gallery/Client-logo.png"
          class="logo"
          alt="FactoryPRO-Logo"
          style="width: 200px; height: 147px"
        />
        <!-- <h4>Lorem ipsum dolor sit amet, consectetur
                <br/> adipiscing elit. Donec posuere velit nec enim
                <br/> sodales, nec placerat erat tincidunt. </h4> -->
      </div>
      <img src="assets/gallery/c11.png" class="logo" alt="FactoryPRO-Logo" />

      <div class="p-grid" style="text-align: center">
        <div class="col-md-1"></div>
        <div class="col-md-2" style="min-width: 100px;padding-right: 10px;">
          <img src="assets/gallery/pms.png" class="img-fluid" alt="image" style="width:60px; height:60px;" />
          <p>
            Project<br />
            Management
          </p>
        </div>
        <div class="col-md-2" style="min-width: 100px;padding-right: 10px;">
          <img src="assets/gallery/dms.png" class="img-fluid" alt="image" style="width:60px; height:60px;" />
          <p>
            Document <br />
            Management
          </p>
        </div>
        <div class="col-md-2" style="min-width: 100px;padding-right: 10px;">
          <img src="assets/gallery/pdm.png" class="img-fluid" alt="image" style="width:60px; height:60px;" />
          <p>
            Product <br />
            Management
          </p>
        </div>
        <div class="col-md-2" style="min-width: 100px;">
          <img src="assets/gallery/cms.png" class="img-fluid" alt="image" style="width:60px; height:60px;" />
          <p>
            Change<br />
            Management
          </p>
        </div>
        <div class="col-md-2" style="min-width: 100px;">
          <img
            src="assets/gallery/its.png"
            class="img-fluid"
            alt="image"
            style="width:60px; height:60px;" />
          <p>
            Issue<br />
            Tracking
          </p>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>

    <div class="login-panel">
      <div class="login-form" style="margin-top: 100px; width: 100%">
        <img
          src="assets/gallery/logo.png"
          class="logo"
          alt="FactoryPRO-Logo"
          style="width: 140px; height: 80px"
        />
        <!-- <h2>Create Your Free Account</h2>  -->
        <h2>Login Here</h2>
        <!-- <p>Already have an account? <a href="#">Login</a></p> -->
        <input
          pInputText
          id="name"
          placeholder="Username"
          [(ngModel)]="userName"
          (keyup.enter)="onEnter($event)"
        />
        
        <div class="col-12 md:col-4" style="width: 85%;
        max-width: 310px;
        margin-bottom: 20px;">
          <div class="p-inputgroup">
            <input
            [feedback]="false"
            type="password"
            pPassword
            placeholder="Password"
            [(ngModel)]="password"
            (keyup.enter)="onEnter($event)"
            [type]="show_button ? 'text' : 'password'"
          />
          <span (click)="showPassword()" class="p-inputgroup-addon"><i style="font-size: 1rem;" [class]="show_eye ? 'pi pi-eye-slash' : 'pi pi-eye'"></i></span>        
          </div>
      </div>
        <button pButton label="Login" type="button" (click)="login()"></button>
        <br />
        <small id="errorMessage" class="p-invalid">{{
          loginMessage
        }}</small
        >
       <a (click)="forgotPasswordClick()"><span style="cursor: pointer;">Forgot Password ?</span></a>
       <div class="p-field p-col-12 p-md-12" *ngIf="No"><br><br>
        <span style="color:green;">Please click on the link in your email to reset the password</span>
      </div>
      </div>
    </div>
  </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 1000;" *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
</div>