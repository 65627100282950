import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';

@Component({
  selector: 'app-createupdatemenudetails',
  templateUrl: './createupdatemenudetails.component.html',
  styleUrls: ['./createupdatemenudetails.component.scss']
})
export class CreateupdatemenudetailsComponent implements OnInit {
  applicationsData=[];
  allmenuData=[];
  FromEdit:boolean;
  selectedApplication:any;
  applicationError="";
  menuName="";
  menuNamError="";
  menuCode="";
  menuCodeError="";
  menuURL="";
  iconPath="";
  iconError="";
  sequence:number;
  sequenceError="";
  mainError="";
  active=true;
  labeltext:any="Save";
  HeaderText:any="New Menu";
  showLoading:boolean;
  Description="";
  headermenudata=[];
  selectedheadermenu:any;
  headermenuError="";
  parentmenudata=[];
  selectedparentmenu:any;
  parentmenuError="";
  menuId=0;
  clients:any[];
  selectedClient:any;
  clientError:string = "";
  constructor(@Inject(BASE_API_URL_TOKEN) public baseUrl: Object,
  private _facadeService: FacadeService,
  private router: Router,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.showLoading = true;
    var menuId = this.route.snapshot.queryParamMap.get('menuId');
    this.clients = [
      { label: 'Kinetic', value: 1},
      { label: 'SolSem', value: 2}
    ];
    if(menuId!=null&&menuId!=""&&menuId!=undefined){
      this.HeaderText = "Update Menu";
      this.FromEdit = true;
      this.menuId=parseInt(menuId);
      this.GetMenuById();
    }
    else{
      this.GetApplications('');
    }
  }
  BackClick(){
    location.href=this.baseUrl["API_ENDPOINTS"]["UMSUI"]+'menu';
  }
  CreateUpdateMenu(){
    this.clientError = "";
    this.applicationError="";
    this.menuNamError="";
    this.menuCodeError="";
    this.iconError="";
    this.sequenceError="";
    this.mainError="";
    if(this.selectedClient==null||this.selectedClient==""||this.selectedClient==undefined){
      this.clientError="Please select client.";
    }
    else if(this.selectedApplication==null||this.selectedApplication==""||this.selectedApplication==undefined){
      this.applicationError="Please select application.";
    }
    else if(this.menuName==null||this.menuName==""||this.menuName==undefined){
      this.menuNamError="Please enter menu name.";
    }
    else if(this.menuCode==null||this.menuCode==""||this.menuCode==undefined){
      this.menuCodeError="Please enter menu code.";
    }
    else if(this.iconPath==null||this.iconPath==""||this.iconPath==undefined){
      this.iconError="Please enter icon path.";
    }
    else if(this.sequence==null||this.sequence==undefined){
      this.sequenceError="Please enter sequence.";
    }
    else{
      this.showLoading=true;
      var menu ={
          "menuId": this.menuId,
          "applicationId": this.selectedApplication.applicationId,
          "menuCode": this.menuCode,
          "menuName": this.menuName,
          "description": this.Description,
          "url": this.menuURL,
          "parentMenuId":this.selectedheadermenu!=null&&this.selectedheadermenu!=undefined&&this.selectedheadermenu!=""? this.selectedheadermenu.menuId:0,
          "headerMenuId": this.selectedparentmenu!=null&&this.selectedparentmenu!=""&&this.selectedparentmenu!=undefined?this.selectedparentmenu.menuId:0,
          "clientId": this.selectedClient.value,
          "iconPath": this.iconPath,
          "seq": this.sequence,
          "active": this.active?"Y":"N"
      }
      if(this.menuId>0){
        this._facadeService.UpdateMenu(menu).subscribe(
          (data) => {
            this.showLoading=false;
            if(data.returnCode==0){
              location.href=this.baseUrl["API_ENDPOINTS"]["UMSUI"]+'menu';
            }
            else{
             this.mainError=data.returnMessage;
            }
        },
        (error) => { 
          this.showLoading=false;
          console.log(error);
        }
      );
      }
      else{
        this._facadeService.CreateMenu(menu).subscribe(
          (data) => {
            this.showLoading=false;
            if(data.returnCode==0){
              location.href=this.baseUrl["API_ENDPOINTS"]["UMSUI"]+'menu';
            }
            else{
             this.mainError=data.returnMessage;
            }
        },
        (error) => { 
          this.showLoading=false;
          console.log(error);
        }
      );
      }
    }
  }
  GetApplications(appId){
    this._facadeService.GetApplications().subscribe(
      (data) => {
      this.applicationsData = data.returnObject;
      if(appId!=null&&appId!=""&&appId!=undefined)
      {
        this.selectedApplication=this.applicationsData.find(m=>m.applicationId==appId);
      }
      else
        this.showLoading = false;
    },
    (error) => { 
      this.showLoading=false;
      console.log(error);
    }
  );
  }
  GetMenuById(){
    this.showLoading=true;
    this._facadeService.GetMenuById(this.menuId).subscribe(
      (data) => {
      this.selectedClient=this.clients.find(m=>m.value==data.returnObject.clientId);
       this.GetApplications(data.returnObject.applicationId);
       this.menuName=data.returnObject.menuName;
       this.menuCode=data.returnObject.menuCode;
       this.menuURL=data.returnObject.url;
       this.iconPath=data.returnObject.iconPath;
       this.sequence=data.returnObject.seq;
       this.active=data.returnObject.active=="Y"?true:false;
       this.Description=data.returnObject.description;
       this.labeltext="Update";
       this.AllMenus(data.returnObject.parentMenuId,data.returnObject.headerMenuId,data.returnObject.applicationId,data.returnObject.clientId);
    },
    (error) => { 
      this.showLoading=false;
      console.log(error);
    }
  );
  }
  GetMenusByApplication(){
    if(this.selectedApplication!=null&&this.selectedApplication!=""&&this.selectedApplication!=undefined
    && this.selectedClient!=null&&this.selectedClient!=""&&this.selectedClient!=undefined){
      this.AllMenus('','',this.selectedApplication.applicationId,this.selectedClient.value);
    }
  }
  HeaderMenuClick(){
    this.showLoading = true;
    this.selectedparentmenu="";
    this.parentmenudata=[];
    this.parentmenudata=this.allmenuData.filter(m=>(m.url==null||m.url=="")&&
      m.applicationId==this.selectedApplication.applicationId && m.clientId == this.selectedClient.value && m.headerMenuId==this.selectedheadermenu.menuId);
    this.showLoading = false;
  }
  AllMenus(parentMenuId,headerMenuId,applicationId,clientID){
    this.showLoading = true;
    this.selectedparentmenu="";
    this.parentmenudata=[];
    this.selectedheadermenu="";
    this.headermenudata=[];
    this.allmenuData=[];
    this._facadeService.AllMenus().subscribe(
      (data) => {
      this.allmenuData = data.returnObject;
      if(this.allmenuData!=null&&this.allmenuData.length>0){
       
        this.headermenudata=data.returnObject.filter(m=>(m.url==null||m.url=="")&&m.parentMenuId==0
          &&m.headerMenuId==0&&m.applicationId==applicationId && m.clientId == clientID);

        if(headerMenuId!=null&&headerMenuId!=""&&headerMenuId!=undefined){
          this.selectedheadermenu = this.headermenudata.find(m=>m.menuId==headerMenuId);
          this.parentmenudata=data.returnObject.filter(m=>(m.url==null||m.url=="")&&
            m.applicationId==applicationId&&m.headerMenuId==headerMenuId && m.clientId == clientID);
        }
        if(parentMenuId!=null&&parentMenuId!=""&&parentMenuId!=undefined){
          this.selectedparentmenu = this.parentmenudata.find(m=>m.menuId==parentMenuId);
        }
      }
      this.showLoading = false;
    },
    (error) => { 
      this.showLoading=false;
      console.log(error);
    }
  );
  }
}
