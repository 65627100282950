import { Injectable } from "@angular/core";
import { LoginService } from "../services/login.service";
import { DashboardService } from "../services/dashboard.service";
import { UserService } from "../services/user.service";
@Injectable({
  providedIn: "root",
})
export class FacadeService {
  constructor(
    private _loginService: LoginService,
    private _dashboardService: DashboardService,
    private _userService: UserService
  ) {}

  login(model) {
    return this._loginService.login(model);
  }
  getDashboardCounts() {
    return this._dashboardService.getDashboardCounts();
  }

  profileList(UID) {
    return this._loginService.profileList(UID);
  }

  forGotPassword(loginName) {
    return this._loginService.forGotPassword(loginName);
  }

  newpasswordData(model) {
    return this._loginService.newpasswordData(model);
  }
  GetUserDetails(userGuid){
    return this._loginService.GetUserDetails(userGuid); 
  }
  GetUserDetailsnoCache(userGuid){
    return this._loginService.GetUserDetailsnoCache(userGuid); 
  }
  DelegatedDocuments(userId){
    return this._loginService.DelegatedDocuments(userId); 
  }
  UploadProfilePicture(userGuid,model){
  return this._loginService.UploadProfilePicture(userGuid,model);
  }
ChangePassword(model){
  return this._loginService.ChangePassword(model);
}
ValidateLink(keyToken){
  return this._loginService.ValidateLink(keyToken);
}
ResetPassword(keyToken){
  return this._loginService.ResetPassword(keyToken);
}
GetDepartments(){
  return this._userService.GetDepartments();
}
CheckDepartmentInactiveById(guid){
  return this._userService.CheckDepartmentInactiveById(guid);
}
GetAllDepartments(){
  return this._userService.GetAllDepartments();
}
CreateDepartment(model,ActionBy){
  return this._userService.CreateDepartment(model,ActionBy);
}
UpdateDepartment(model,ActionBy){
  return this._userService.UpdateDepartment(model,ActionBy);
}
ViewDepartment(departmentGuid){
  return this._userService.ViewDepartment(departmentGuid);
}

GetRoles(){
  return this._userService.GetRoles();
}
GetAllRoles(){
  return this._userService.GetAllRoles();
}
CheckRoleInactiveById(guid){
  return this._userService.CheckRoleInactiveById(guid);
}
CreateRole(model,ActionBy){
  return this._userService.CreateRole(model,ActionBy);
}
UpdateRole(model,ActionBy){
  return this._userService.UpdateRole(model,ActionBy);
}
ViewRole(roleGuid){
  return this._userService.ViewRole(roleGuid);
}
GetFeatureById(featureid){
  return this._userService.GetFeatureById(featureid);
}
GetBusinessUnit(){
  return this._userService.GetBusinessUnit();
}
GetAllBusinessUnit(){
  return this._userService.GetAllBusinessUnit();
}
CheckBusinessUnitInactiveById(guid){
  return this._userService.CheckBusinessUnitInactiveById(guid);
}
CreateBusinessUnit(model,ActionBy){
  return this._userService.CreateBusinessUnit(model,ActionBy);
}
UpdateBusinessUnit(model,ActionBy){
  return this._userService.UpdateBusinessUnit(model,ActionBy);
}
ViewBusinessUnit(Guid){
  return this._userService.ViewBusinessUnit(Guid);
}
createSite(model,actionby){
  return this._userService.createSite(model,actionby);
}


updateSite(model,actionby){
  return this._userService.updateSite(model,actionby);
}

viewSite(guid){
  return this._userService.viewSite(guid);
}

GetApplications(){
  return this._userService.GetApplications();
}
GetApplicationGroups(){
  return this._userService.GetApplicationGroups();
}
GetAllApplicationGroups(){
  return this._userService.GetAllApplicationGroups();
}
UpdateApplicationGroup(group){
  return this._userService.UpdateApplicationGroup(group);
}
CreateApplicationGroup(group){
  return this._userService.CreateApplicationGroup(group);
}
GetGroupApplicationDetailsById(guid){
  return this._userService.GetGroupApplicationDetailsById(guid);
}
CheckApplicationGroupInactiveById(guid){
  return this._userService.CheckApplicationGroupInactiveById(guid);
}
GetGroupApplicationData(guids)
{
  return this._userService.GetGroupApplicationData(guids);
}
GetUsersByDepID(depid)
{
  return this._userService.GetUsersByDepID(depid);
}

UpdateUserDefaultApplication(userid,applicationid){
  return this._userService.UpdateUserDefaultApplication(userid,applicationid);
}
UpdateUserDefaultDepartment(userid,depid){
  return this._userService.UpdateUserDefaultDepartment(userid,depid);
}
getRolesByDepartment(departmentId){
  return this._userService.getRolesByDepartment(departmentId);
}
getRolesByDepartmentGuids(departmets){
  return this._userService.getRolesByDepartmentGuids(departmets);
}

GetAllUsers(){
  return this._userService.GetAllUsers();
}

GetUsers(){
  return this._userService.GetUsers();
}
GetTimezone(){
  return this._userService.GetTimeZones();
}
GetSites(){
  return this._userService.GetSites();
}
GetDependencySupervisorList(userId){
  return this._userService.GetDependencySupervisorList(userId);
}
SaveUser(user){
  return this._userService.SaveUser(user);
}
UpdateUser(user){
  return this._userService.UpdateUser(user);
}
DMSUserApprovals(userguid){
  return this._userService.DMSUserApprovals(userguid);
}
DMSMyDocuments(data){
  return this._userService.DMSMyDocuments(data);
}
GetInprocessDocUsersFromCMSToInactive(userguid){
  return this._userService.GetInprocessDocUsersFromCMSToInactive(userguid);
}
UpdateUserReplacementInfo(userguid,ActionBy,model){
  return this._userService.UpdateUserReplacementInfo(userguid,ActionBy,model);
}
GetMyIssuesByUserIdUserDeactivation(userguid){
  return this._userService.GetMyIssuesByUserIdUserDeactivation(userguid);
}
SaveProductRole(data){
  return this._userService.SaveProductRole(data);
}
CreateSecurityToken(){
  return this._userService.CreateSecurityToken();
}
UpdateSecurityToken(token){
  return this._userService.UpdateSecurityToken(token);
}
GetUserReplacementInfo(userguid){
  return this._userService.GetUserReplacementInfo(userguid);
}
approvalNeededData(model){
  return this._userService.approvalNeededData(model);
}
getMenus(model) {
  return this._userService.getMenus(model);
}
getHelp(model)
{
  return this._userService.getHelp(model);
}
AddHelp(model)
{
  return this._userService.AddHelp(model);
}
UpdateHelp(model)
{
  return this._userService.UpdateHelp(model);
}
GetHelpByID(HelpID)
{
  return this._userService.GetHelpByID(HelpID);
}
CheckAavailability(ID)
{
  return this._userService.CheckAavailability(ID);
}
GetFeatures(model){
  return this._userService.GetFeatures(model);
}
CreateFeature(model){
  return this._userService.CreateFeature(model);
}
UpdateFeature(model){
  return this._userService.UpdateFeature(model);
}
AllRolesFeatures(){
  return this._userService.AllRolesFeatures();
}
InsertRoleFeatures(model){
  return this._userService.InsertRoleFeatures(model);
}
UpdateRoleFeatures(model){
  return this._userService.UpdateRoleFeatures(model);
}
GetRolesByFeatureId(fetureid){
  return this._userService.GetRolesByFeatureId(fetureid);
}
AllMenus(){
  return this._userService.AllMenus();
}
CreateMenu(model){
  return this._userService.CreateMenu(model);
}
UpdateMenu(model){
  return this._userService.UpdateMenu(model);
}
GetMenuById(menuid){
  return this._userService.GetMenuById(menuid);
}
GetMenusByRoles(roles){
  return this._userService.GetMenusByRoles(roles);
}
InsertRoleMenus(model){
  return this._userService.InsertRoleMenus(model);
}
GetMenusByUser(userId,applicationid){
  return this._userService.GetMenusByUser(userId,applicationid);
}
UpdateRoleMenus(model){
  return this._userService.UpdateRoleMenus(model);
}
AddUserTracking(model){
  return this._userService.AddUserTracking(model);
}
GetUserTracking(model){
  return this._userService.GetUserTracking(model);
}
}
