<div class="p-fluid p-formgrid p-grid">
    <div class="p-col-12">
      <div class="card" style="min-height:600px;">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-col-12 p-md-2">
            <a>
              <button pButton pRipple type="button" icon="pi pi-chevron-left" iconPos="left" label="Back"
                class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="width: 50%"
                (click)="BackClick()"></button></a>
          </div>
  
          <div class="p-col-12 p-md-8" style="text-align: center"> 
            <h4>{{HeaderText}}</h4>
          </div>
        </div>
        <hr />
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-12">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-4">
                <label>Client<span style="color: red">*</span></label>
                <p-dropdown [options]="clients" [disabled]="FromEdit"  [(ngModel)]="selectedClient" placeholder="Select a Client"
            optionLabel="label" (click)="GetMenusByApplication()"></p-dropdown>
                <small class="p-invalid">{{ clientError }}</small>
                <br />
                <label>Parent Menu</label>
                <p-dropdown [options]="parentmenudata"  [(ngModel)]="selectedparentmenu" placeholder="Select Parent menu"
                optionLabel="menuName"></p-dropdown>
                <br/> 
                <label>URL</label>
                <input [(ngModel)]="menuURL" type="text" pInputText placeholder="Enter URL" />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label>Application<span style="color: red">*</span></label>
                <p-dropdown [options]="applicationsData" [disabled]="FromEdit"  [(ngModel)]="selectedApplication" placeholder="Select a application"
            optionLabel="applicationName" (click)="GetMenusByApplication()"></p-dropdown>
                <small class="p-invalid">{{ applicationError }}</small>
                <br />
                <label>Menu Name<span style="color: red">*</span></label>
                <input [(ngModel)]="menuName" type="text" pInputText placeholder="Enter Menu Name" />
                <small class="p-invalid">{{ menuNamError }}</small>
                <br /><br/> 
                <label>Icon Path<span style="color: red">*</span></label>
                <input [(ngModel)]="iconPath" type="text" pInputText placeholder="Enter Icon Path" />
                <small class="p-invalid">{{ iconError }}</small>
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label>Header Menu</label>
                <p-dropdown [options]="headermenudata"  [(ngModel)]="selectedheadermenu" placeholder="Select header menu"
                optionLabel="menuName" (click)="HeaderMenuClick()"></p-dropdown>
                <br/> 
                <label>Menu Code<span style="color: red">*</span></label>
                <input [(ngModel)]="menuCode" type="text" maxlength="12" pInputText placeholder="Enter Menu Code" />
                <small class="p-invalid">{{ menuCodeError }}</small>
                <br /><br/> 
                <label>Sequence<span style="color: red">*</span></label>
                <input [(ngModel)]="sequence" type="number" pInputText placeholder="Enter sequence" />
                <small class="p-invalid">{{ sequenceError }}</small>
              </div>
              <div class="p-field p-col-12 p-md-10">
                <label>Description</label>
                <textarea rows="5" pInputTextarea id="Description" [(ngModel)]="Description" placeholder="Enter Description"
                maxlength="1000" ng-trim="false" style="width: 100%;" ></textarea>
              </div>
              <div class="p-field p-col-12 p-md-2" >
                <br/> <br/> 
                <label style="margin-right: 20px;margin-bottom: -5px;">Active</label>
                <p-checkbox binary="active" [disabled]="!FromEdit" [(ngModel)]="active"
                  ></p-checkbox>
              </div>
              <div class="p-field p-col-12 p-md-10" ><small class="p-invalid">{{ mainError }}</small></div>
              <div class="p-field p-col-12 p-md-2" >
                <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="{{labeltext}}"
                class="p-button-raised p-button-success p-mr-2 p-mb-2"
                style="width:120px; float:right; margin-left:10px;margin-top: 10px;" (click)="CreateUpdateMenu()"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>