import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from 'src/app/facade/facade.service';
import { UserService } from 'src/app/services/user.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from "../../services/printcommon.service";
import { Table } from 'primeng/table';

@Component({
  selector: 'app-createhelpeditor',
  templateUrl: './createhelpeditor.component.html',
  styleUrls: ['./createhelpeditor.component.scss']
})
export class CreatehelpeditorComponent implements OnInit {
  showLoading: boolean;
  applications: any;
  selectedapplication: any;
  applicationError: any;
  menusData: any;
  selectedmenu: any;
  menuError: any;
  helpID:string="";
  HelpIDError:string = "";
  Title: string = "";
  TitleError : string = "";
  KeyWords : string = "";
  KeyWordsError: string = "";
  isActive:boolean = true;
  isFeedback:boolean = false;
  Type:string = "Create";
  btnLabel:string="Save";
  selectedRelated:any[];
  descriptionError:string = "";
  responseError:string = "";
  editmode:boolean = false;

  @ViewChild("dt") table: Table;
  cols: any[];
  HelpData: any;
  HelpDataR:any;
  applicationName:string = "";
  Count : number = 0;
  HelpID:string="";
  
  userId = sessionStorage.getItem('userGuid');
  constructor(private _facadeService: FacadeService, private _userService: UserService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object ,private printService: PrintcommonService) { }

  resetCtrls()
  {
    this.showLoading = true;
    this.descriptionError = "";
    this.responseError = "";
    this.applicationError = "";
    this.selectedmenu = [];
    this.menuError = "";
    this.helpID = "";
    this.HelpIDError = "";
    this.Title = "";
    this.TitleError = "";
    this.KeyWords = "";
    this.KeyWordsError = "";
    this.isActive = true;
    this.editmode = false;
    this.isFeedback = false;
    this.selectedRelated = [];
    this.HelpID = "";
    this.Type = "Create";
    this.btnLabel = "Save";
    if(this._userService.editor.blocks != undefined)
      this._userService.editor.blocks.clear();
    this.HelpDataR = this.HelpData;
    this.showLoading = false;
  }
  ngOnInit(): void {
    this.showLoading = true;
    this._userService.editorJsInit();
    sessionStorage.applicationName = "";
    sessionStorage.menuName = "";
    sessionStorage.helpTitle = "";
    sessionStorage.helpId = "";
    sessionStorage.active = "";
    this.cols = [
      { field: 'applicationName', header: 'Application', dynamicPlaceHolder: 'Search' },
      { field: 'menuName', header: 'Menu', dynamicPlaceHolder: 'Search' },
      { field: 'helpTitle', header: 'Title', dynamicPlaceHolder: 'Search' },
      { field: 'helpId', header: 'Code', dynamicPlaceHolder: ' Search' },
      { field: 'active', header: 'Active', dynamicPlaceHolder: ' Search' },
    ];
    this.getApplications();
  }
  getApplications() {
    this._facadeService.GetApplications().subscribe(
      (data) => {
        this.applications = data.returnObject;
        this.showLoading = false;
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }

  getMenuList(MenuID,BindHelp) {
    this.showLoading = true;
    this.applicationName = this.selectedapplication.applicationName;
    var Model = {
      menuId: 0,
      menuGuid: "",
      applicationGuid: this.selectedapplication.applicationGuid,
      active: "Y",
    };
    this._facadeService.getMenus(Model).subscribe((data) => {
        this.menusData = data.returnObject;
        if(this.menusData != null && this.menusData != undefined)
        {
          this.menusData .forEach(e => {
            if(e.parentMenuName != null)
            {
              if(e.headerMenuName != null)
              {
                if(e.headerMenuName == e.parentMenuName )
                  e.menuName = e.parentMenuName + "  >>  " + e.menuName;
                else
                  e.menuName = e.headerMenuName + "  >>  " + e.parentMenuName + "  >>  " + e.menuName;
              }
              else
                e.menuName = e.parentMenuName + "  >>  " + e.menuName;
            }
          });
          if(MenuID != "")
            this.selectedmenu = this.menusData.find(x=>x.menuGuid.toLowerCase() == MenuID.toLowerCase());
          else
            this.selectedmenu = this.menusData[0];
          if(BindHelp)
            this.getHelp();
          else
            this.showLoading = false;
        }
        else
          this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
      );
  }
  checkAvailability()
  {
    this.showLoading = true;
    this.HelpIDError = "";
    if(this.helpID != null && this.helpID != undefined && this.helpID != "")
    {
      this._facadeService.CheckAavailability(Number(this.helpID)).subscribe((data) => {
        this.HelpIDError = "Not Available";
        if(data != null && data != undefined)
        {
          if(data)
            this.HelpIDError = "Available";
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
      );
    }
    else
    {
      this.HelpIDError = "Please enter help id"; this.showLoading = false;
    }
  }
  Edit(HelpID)
  {
    this.showLoading = true;
    this.HelpID = HelpID;
    this._facadeService.GetHelpByID(HelpID).subscribe((data) => {
      var ID = data.returnObject;
      if(ID != null)
      {
        this.editmode = true;
        this.Type = "Edit";
        this.btnLabel = "Update";
        this.descriptionError = "";
        this.responseError = "";
        this.applicationError = "";
        this.menuError = "";
        this.HelpIDError = "";
        this.helpID = ID.helpId;
        this.Title = ID.helpTitle;
        this.TitleError = "";
        this.KeyWords = ID.keyWords;
        this.KeyWordsError = "";
        this.isActive = ID.active;
        this.isFeedback = ID.showFeedback;
        this.HelpDataR = this.HelpData.filter(x=>x.helpGuid.toLowerCase() != HelpID.toLowerCase());
        this.selectedRelated = [];
        var ref = [];
        if(this.HelpDataR != null && this.HelpDataR != undefined)
        {
          if(this.HelpDataR.length > 0)
          {
            if(ID.references != null && ID.references != undefined)
            {
              if(ID.references.length > 0)
              {
                ID.references.forEach(element => {
                  var a = this.HelpDataR.find(x=>x.helpGuid.toLowerCase() == element.helpGuid.toLowerCase());
                  if(a != null)
                    ref.push(a);
                });
                this.selectedRelated = ref;
              }
            }
          }
        }
        this._userService.editor.blocks.render({
          blocks: JSON.parse(ID.description)
        });
         
        this.getMenuList(ID.menuGuid,false);
      }
      else
        this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
    );
  }
  getHelp()
  {
    var Model = {
      "helpId": 0,
      "applicationGuid": this.selectedapplication.applicationGuid,
      "active": true
    };
    this._facadeService.getHelp(Model).subscribe((data) => {
      this.HelpData = data.returnObject;
      if(this.HelpData != null && this.HelpData != undefined)
      {
        this.HelpData.forEach(e => {
          e.name = e.helpId + " : " + e.helpTitle;
        });
      }
      this.HelpDataR = this.HelpData;
      this.HelpCount();
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
    );
  }
  save()
  {
    this.showLoading = true;
    this.responseError = "";
    this.applicationError = "";
    this.menuError = "";
    this.HelpIDError = "";
    this.TitleError = "";
    this.KeyWordsError = "";
    this.descriptionError = "";
    if(this.selectedapplication == null || this.selectedapplication == undefined || this.selectedapplication.length == 0)
    {
      this.applicationError = "Please select application"; this.showLoading = false;
    }
    else if(this.selectedmenu == null || this.selectedmenu == undefined || this.selectedmenu.length == 0)
    {
      this.menuError = "Please select menu"; this.showLoading = false;
    }
    else if(this.helpID == null || this.helpID == undefined || this.helpID == "" || this.helpID == "0")
    {
      this.HelpIDError = "Please enter Help ID"; this.showLoading = false;
    }
    else if(this.Title == null || this.Title == undefined || this.Title == "")
    {
      this.TitleError = "Please enter title"; this.showLoading = false;
    }
    else if(this.KeyWords == null || this.KeyWords == undefined || this.KeyWords == "")
    {
      this.KeyWordsError = "Please enter keywords"; this.showLoading = false;
    }
    else
    {
      var ref = [];
      if(this.selectedRelated != null && this.selectedRelated != undefined)
      {
        if(this.selectedRelated.length > 0)
        {
          this.selectedRelated.forEach(element => {
            var a =
              {
                "helpGuid": element.helpGuid,
                "helpTitle": element.helpTitle
              }
            ref.push(a);   
          });
        }
      }
      this._userService.editor.save().then((outputData) => {
        if(outputData.blocks.length == 0)
        {
          this.descriptionError = "Please enter description"; this.showLoading = false;
        }
        else
        {
          var Content = "";
          var textblocks = outputData.blocks.filter(x=>x.type.toLowerCase() == "paragraph");
          if(textblocks != null && textblocks != undefined)
          {
            textblocks.forEach(element => {
              var str = JSON.stringify(element.data);
              str = str.replace('{"text":','').replace('"','').replace('"}','').replace('<strong>','').replace('</strong>','').replace('&nbsp;',' ');
              str = str.replace('<i>','').replace('</i>','').replace('<u>','').replace('</u>','');
              if(str != "")
                Content = Content + str + " </br> ";
            });
          }
          var DisplayContent = "";
          if(outputData.blocks != null && outputData.blocks != undefined)
          {
            outputData.blocks.forEach(element => {
              if(element.type == "paragraph")
              {
                var str = JSON.stringify(element.data);
                str = str.replace('{"text":','').replace('"','').replace('"}','').replace('&nbsp;',' ');
                if(element.tunes != null && element.tunes != undefined)
                {
                  var alignment = JSON.stringify(element.tunes.anyTuneName);
                  alignment = alignment.replace('{"alignment":','').replace('"','').replace('"}','').replace('&nbsp;',' ');
                  if(alignment == "right")
                    str = "<div class='alignleft'>" + str + "</div>";
                  else if(alignment == "center")
                    str = "<div class='aligncenter'>" + str + "</div>";
                  else
                    str = "<div class='alignleft'>" + str + "</div>";
                }
                DisplayContent = DisplayContent + str + " </br> ";
              }
              if(element.type == "header")
              {
                var str = JSON.stringify(element.data);
                str = str.replace('{"text":','').replace('"','').replace('"}','').replace('&nbsp;',' ');
                var alignment = "alignleft";
                if(element.tunes != null && element.tunes != undefined)
                {
                  var alignment = JSON.stringify(element.tunes.anyTuneName);
                  alignment = alignment.replace('{"alignment":','').replace('"','').replace('"}','').replace('&nbsp;',' ');
                  if(alignment == "right")
                    alignment = "alignleft";
                  else if(alignment == "center")
                    alignment = "aligncenter";
                  else
                    alignment = "alignleft";
                }

                if(str.indexOf('level":1') > -1)
                {
                  str = str.replace('","level":1}','');
                  str = "<h1 class='"+ alignment+"'>" + str + "</h1>";
                }
                if(str.indexOf('level":2') > -1)
                {
                  str = str.replace('","level":2}','');
                  str = "<h2 class='"+ alignment+"'>" + str + "</h2>";
                }
                if(str.indexOf('level":3') > -1)
                {
                  str = str.replace('","level":3}','');
                  str = "<h3 class='"+ alignment+"'>" + str + "</h3>";
                }
                if(str.indexOf('level":4') > -1)
                {
                  str = str.replace('","level":4}','');
                  str = "<h4 class='"+ alignment+"'>" + str + "</h4>";
                }
                if(str.indexOf('level":5') > -1)
                {
                  str = str.replace('","level":5}','');
                  str = "<h5 class='"+ alignment+"'>" + str + "</h5>";
                }
                if(str.indexOf('level":6') > -1)
                {
                  str = str.replace('","level":6}','');
                  str = "<h6 class='"+ alignment+"'>" + str + "</h6>";
                }
                DisplayContent = DisplayContent + str + " </br> ";
              }
              if(element.type == "image")
              {
                var str = JSON.stringify(element.data.file);
                str = str.replace('{"url":','').replace('"','').replace('"}','').replace('&nbsp;',' ');
                str = "<img src='" + str + "' class='imgalign'>";
                DisplayContent = DisplayContent + str + " </br> ";
              }
            });
          }
          if(this.HelpID == "")
            this.HelpID = "00000000-0000-0000-0000-000000000000";
          var Model = {
            "helpId": Number(this.helpID),
            "helpGuid": this.HelpID,
            "applicationGuid": this.selectedapplication.applicationGuid,
            "applicationName": this.selectedapplication.applicationName,
            "menuGuid": this.selectedmenu.menuGuid,
            "menuName": this.selectedmenu.menuName,
            "helpTitle": this.Title,
            "description": JSON.stringify(outputData.blocks),
            "helpContent": Content,
            "displayContent": DisplayContent,
            "keyWords": this.KeyWords,
            "showFeedback": this.isFeedback,
            "active": this.isActive,
            "createdBy": this.userId,
            "references": ref
          };
          if(this.HelpID == "00000000-0000-0000-0000-000000000000")
          {
            this._facadeService.AddHelp(Model).subscribe((data) => {
              if(data.returnCode == 0)
              {        
                this.resetCtrls();
                this.getHelp();
              }
              else
              {
                this.responseError = data.returnMessage;
                this.showLoading = false;
              }
            },
            (error) => {
              this.showLoading = false;
              console.log(error);
            }
            );
          }
          else{
            this._facadeService.UpdateHelp(Model).subscribe((data) => {
              if(data.returnCode == 0)
              {        
                this.resetCtrls();
                this.getHelp();
              }
              else
              {
                this.responseError = data.returnMessage;
                this.showLoading = false;
              }
            },
            (error) => {
              this.showLoading = false;
              console.log(error);
            }
            );
          }
        }
      })
      .catch((error) => {
        this.showLoading = false;
        console.log('Saving failed: ', error);
      });
    }
  }
  test(val,field,dt)
  {
    if(field == "applicationName")
      sessionStorage.applicationName = val;
    else if(field == "menuName")
      sessionStorage.menuName = val;
    else if(field == "helpTitle")
      sessionStorage.helpTitle = val;
    else if(field == "helpId")
      sessionStorage.helpId = val;
    else if(field == "active")
      sessionStorage.active = val;
    dt = dt.filter(val,field, 'contains');
    this.HelpCount();
  }
  HelpCount(){
    if(this.HelpData != undefined && this.HelpData != null)
    {
      var temp = this.HelpData;
      if(sessionStorage.applicationName != "")
        temp = temp.filter(x=> x.applicationName.toLowerCase().contains(sessionStorage.applicationName.toLowerCase()));
      if(sessionStorage.menuName != "")
        temp = temp.filter(x=> x.menuName.toLowerCase().contains(sessionStorage.menuName.toLowerCase()));
      if(sessionStorage.helpTitle != "")
        temp = temp.filter(x=> x.helpTitle.toLowerCase().contains(sessionStorage.helpTitle.toLowerCase()));
      if(sessionStorage.helpId != "")
        temp = temp.filter(x=> x.helpId.toLowerCase().contains(sessionStorage.helpId.toLowerCase()));
      if(sessionStorage.active != "")
        temp = temp.filter(x=> x.active.toLowerCase().contains(sessionStorage.active.toLowerCase()));
      this.Count = temp.length;
    }
    else
      this.Count = 0;
  }
  exportExcel() {
    if(this.selectedapplication != undefined && this.selectedapplication != null)
    {
     /* table id is passed over here */   
      let element = document.getElementById('excel-table'); 
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [{ wpx : 150 },{ wpx : 200 },{ wpx : 600 },{ wpx : 60 },{ wpx : 60 }];
      this.delete_row(wb.Sheets.Sheet1, 1);
      /* save to file */
      XLSX.writeFile(wb, this.selectedapplication.applicationName + " Help Items.xlsx");
    }
  }
  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;&nbsp;   &nbsp;" || ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['F1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }

  print() {
    if(this.selectedapplication != undefined && this.selectedapplication != null)
     {
      var Content = document.getElementById('invoice-content').innerHTML;
      var Title = this.selectedapplication.applicationName + " - Help Items";
      this.printService.Print(Content,Title,"trSearch",true);
     }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}


