import { Injectable } from "@angular/core";
import { HttputilityService } from "../services/httputility.service";
@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private _httputilityService: HttputilityService) {}

  login(model) {
    return this._httputilityService.AddAuthh("user/Login", model);
  }

  profileList(UID) {
    return this._httputilityService.GetU('user/GetUserByID?UID=' + UID,
      ''
    );
  }

  forGotPassword(loginName) {
    return this._httputilityService.GetAuth('user/ForgotPassword?loginName=' + loginName,
      ''
    );
  }

  newpasswordData(model) {
    return this._httputilityService.AddAuthh("user/ResetPassword", model);
  }

  GetUserDetails(userGuid){
    return this._httputilityService.GetU('user/GetUserDetails?UserGUID='+userGuid);
  }
  GetUserDetailsnoCache(userGuid){
    return this._httputilityService.GetU('user/GetUserDetails?UserGUID='+userGuid+'&cacheset=false');
  }
  DelegatedDocuments(userId){
    return this._httputilityService.GetDMS('DelegatedDocuments?UID='+userId);
  }
  UploadProfilePicture(userGuid,model){
    return this._httputilityService.FileUpload("user/UploadProfilePicture?userGuid="+userGuid,model);
  }

  ChangePassword(data){
    return this._httputilityService.Add("user/ChangePassword",data);
  }

  ResetPassword(keyToken){
    return this._httputilityService.GetAuth('user/ResetPassword?t='+keyToken);
  }
  ValidateLink(keyToken){
    return this._httputilityService.GetAuth('user/ValidateLink?t='+keyToken);
  }
}

