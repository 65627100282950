import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/facade/facade.service';

@Component({
  selector: 'app-rolemenu',
  templateUrl: './rolemenu.component.html',
  styleUrls: ['./rolemenu.component.scss']
})
export class RolemenuComponent implements OnInit {
  showLoading: boolean;
  roleMenuData = [];
  cols = [];
  responseMessage = "";
  selectedroleMenus = [];
  rolesData = [];
  selectedRole: any;
  menuData = [];
  userId = parseInt(sessionStorage.getItem("userId"));
  mainSelectedData = [];
  menuByRoles = [];
  clients:any[];
  constructor(private _facadeService: FacadeService,) { }

  ngOnInit(): void {
    this.showLoading = true;
    this.cols = [
      { field: 'clientName', header: 'Client', dynamicPlaceHolder: 'Search' },
      { field: 'applicationName', header: 'Application', dynamicPlaceHolder: 'Search' },
      { field: 'menuName', header: 'Menu', dynamicPlaceHolder: 'Search' },
      { field: 'headerMenuName', header: 'Header Menu', dynamicPlaceHolder: 'Search' },
      { field: 'parentMenuName', header: 'Parent Menu', dynamicPlaceHolder: 'Search' },
      { field: 'path', header: 'Path', dynamicPlaceHolder: 'Search' },
    ];
    this.clients = [
      { label: 'Kinetic', value: 1},
      { label: 'SolSem', value: 2}
    ];
    this.GetRoles();
  }
  GetRoles() {
    this.showLoading = true;
    this._facadeService.GetAllRoles().subscribe(
      (data) => {
        this.rolesData = data.returnObject;
        this.AllMenus();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  AllMenus() {
    this._facadeService.AllMenus().subscribe(
      (data) => {
        this.menuData = data.returnObject;
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  GetRoleByMenus() {
    if (this.selectedRole != null && this.selectedRole != "" && this.selectedRole != undefined) {
      this.showLoading = true;
      var roles = [];
      this.selectedroleMenus = [];
      this.menuByRoles=[];
      roles.push(this.selectedRole.roleId);
      this._facadeService.GetMenusByRoles(roles).subscribe(
        (data) => {
          var seleMenu = [];
          this.roleMenuData = this.menuData;
          if(this.roleMenuData != null && this.roleMenuData != undefined)
          {
            this.roleMenuData.forEach(e => {
              e.path = "--";
              if(e.parentMenuId != 0)
                e.path = e.parentMenuName + " >> " +e.menuName;
              if(e.headerMenuId != 0)
              {
                if(e.parentMenuId != 0)
                {
                  if(e.headerMenuName != e.parentMenuName)
                    e.path = e.headerMenuName + " >> " +e.path;
                }
                else 
                  e.path = e.headerMenuName + " >> " +e.path;
              }
              e.clientName = "";
              var a = this.clients.find(x=>x.value == e.clientId);
              if(a!= null)
                e.clientName = a.label;
            });
          }
          if (data.returnObject != null && data.returnObject != "" && data.returnObject != undefined && data.returnObject.length > 0) {
            data.returnObject.forEach(element => {

              var sel = this.roleMenuData.find(m => m.menuId == element.menuId && element.roleMenuActive == "Y");
              if (sel != null) {
                seleMenu.push(sel);
                if (element.subMenus != null && element.subMenus.length > 0) {
                  element.subMenus.forEach(element1 => {
                    var sel1 = this.roleMenuData.find(m => m.menuId == element1.menuId && element1.roleMenuActive == "Y");
                    if (sel1 != null) {
                      seleMenu.push(sel1);
                      if (element1.subMenus != null && element1.subMenus.length > 0) {
                        element1.subMenus.forEach(element2 => {
                          var sel2 = this.roleMenuData.find(m => m.menuId == element2.menuId && element2.roleMenuActive == "Y");
                          if (sel2 != null) {
                            seleMenu.push(sel2);
                          }
                        });
                      }
                    }
                  });
                }
              }
              else {
                if (element.subMenus != null && element.subMenus.length > 0) {
                  element.subMenus.forEach(element1 => {
                    var sel1 = this.roleMenuData.find(m => m.menuId == element1.menuId && element1.roleMenuActive == "Y");
                    if (sel1 != null) {
                      seleMenu.push(sel1);
                      if (element1.subMenus != null && element1.subMenus.length > 0) {
                        element1.subMenus.forEach(element2 => {
                          var sel2 = this.roleMenuData.find(m => m.menuId == element2.menuId && element2.roleMenuActive == "Y");
                          if (sel2 != null) {
                            seleMenu.push(sel2);
                          }
                        });
                      }
                    }
                    else {
                      if (element1.subMenus != null && element1.subMenus.length > 0) {
                        element1.subMenus.forEach(element2 => {
                          var sel2 = this.roleMenuData.find(m => m.menuId == element2.menuId && element2.roleMenuActive == "Y");
                          if (sel2 != null) {
                            seleMenu.push(sel2);
                          }
                        });
                      }
                    }
                  });
                }
              }
            });
          }
          this.selectedroleMenus = seleMenu;
          this.mainSelectedData = seleMenu;

          if (data.returnObject != null && data.returnObject != "" && data.returnObject != undefined && data.returnObject.length > 0){
            data.returnObject.forEach(element => {
              this.menuByRoles.push(element);
              if(element.subMenus!=null&&element.subMenus.length>0){
                element.subMenus.forEach(element1 => {
                  this.menuByRoles.push(element1);
                  if(element1.subMenus!=null&&element1.subMenus.length>0){
                    element1.subMenus.forEach(element2 => {
                      this.menuByRoles.push(element2);
                      if(element2.subMenus!=null&&element2.subMenus.length>0){
                        element2.subMenus.forEach(element3 => {
                          this.menuByRoles.push(element3);
                        });
                      }
                    });
                  }
                });
              }
            });
          }
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else
      this.showLoading = false;
  }
  
 
  CreateUpdateRoleFeature() {
    this.showLoading = true;
    this.responseMessage = "";
    var modelData = [];
    if(this.selectedroleMenus!=null&&this.selectedroleMenus.length>0){
      this.selectedroleMenus.forEach(element => {
        var eles = this.menuByRoles.find(m=>m.menuId==element.menuId);
        if(eles!=null){
          if(eles.roleMenuActive=="N"){
            var model =
            {
              "Id": eles.roleMenuId,
              "roleId": this.selectedRole.roleId,
              "menuId": element.menuId,
              "active": "Y",
              "createdBy": this.userId,
              "action": "update"
            }
            modelData.push(model);
          }
        }
        else{
          var model1 =
          {
            "Id": 0,
            "roleId": this.selectedRole.roleId,
            "menuId": element.menuId,
            "active": "Y",
            "createdBy": this.userId,
            "action": "new"
          }
          modelData.push(model1);
        }
      });
    }
    if (this.menuByRoles != null && this.menuByRoles.length > 0) {
      this.menuByRoles.forEach(element => {
        if(element.roleMenuActive=="Y"){
          var sel = this.selectedroleMenus.find(m=>m.menuId==element.menuId);
          if(sel==null||sel==undefined){
            var model =
            {
              "Id": element.roleMenuId,
              "roleId": this.selectedRole.roleId,
              "menuId": element.menuId,
              "active": "N",
              "createdBy": this.userId,
              "action": "update"
            }
            modelData.push(model);
          }
        }
      });
    }
    this._facadeService.UpdateRoleMenus(modelData).subscribe(
      (data) => {
        if (data.returnCode == 0)
          this.GetRoleByMenus();
        else {
          this.responseMessage = data.returnMessage;
          this.showLoading = false;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  test(val, field, dt) {
    dt = dt.filter(val, field, 'contains');
  }
}
